import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {
  length: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCartLength":
      return {
        length: action.length,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const CartLengthContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <CartLengthContext.Provider value={context}>
      {children}
    </CartLengthContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import Dialog from "@material-ui/core/Dialog";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { useTerms } from "../API/Hooks/Auth";
import { TermsOfService } from "../components/TermsOfService";
import { AuthContext } from "../contexts/Auth";
import Cart from "../screens/private/Cart";
import Orders from "../screens/private/Orders";
import Product from "../screens/private/Product";
import Status from "../screens/private/Status";
import UserData from "../screens/private/UserData";
import ForgotPassword from "../screens/public/ForgotPassword";
import Home from "../screens/public/Home";
import Login from "../screens/public/Login";
import Register from "../screens/public/Register";

const AllRoutes = () => {
  const [auth] = useContext(AuthContext);
  const { token, termDescription, termStatus } = auth || {};
  const { acceptActiveTerm } = useTerms();

  return (
    <>
      {token && termStatus !== "valid" ? (
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <TermsOfService
            openTermsOfService
            termDescription={termDescription}
            onConfirm={() => acceptActiveTerm()}
          />
        </Dialog>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/product/:id" element={<Product />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/status" element={<Status />} />
          <Route exact path="/user" element={<UserData />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/reset-password" element={<ForgotPassword />} />
        </Routes>
      )}
    </>
  );
};

export default AllRoutes;

import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Data } from "../../../components/Data";
import { Address } from "../../../components/Address";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/User";
import { modeContext } from "../../../App";
import { useSnackbar } from "notistack";
import { useUpdateUserPassword } from "../../../API/Hooks";
import { CompanyContext } from "../../../contexts/Company";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 60px)",
  },
  rootDesktop: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    minHeight: "calc(100vh - 50px)",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  rootDesktopDark: {
    display: "flex",
    backgroundColor: "#474A56",
    width: "100%",
    minHeight: "calc(100vh - 50px)",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
});

const UserData = () => {
  const [token] = useContext(AuthContext);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [openPasswordScreen, setOpenPasswordScreen] = useState(false);

  const {
    fetchUpdateUserPassword,
    requestErrorUpdatePassword,
    requestLoadingUpdatePassword,
  } = useUpdateUserPassword();

  const classes = useStyles();

  const colorMode = useContext(modeContext);

  const [name, setName] = useState(user.userName);
  const [lastName, setLastName] = useState(user.userLastName);
  const [phone, setPhone] = useState("");
  const [email] = useState(user.userEmail);
  const [loadingPhone, setLoadingPhone] = useState(true);
  const [erasing, setErasing] = useState(false);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleUpdatePassword = (data) => {
    delete data.newPassword;
    data.password = password;
    fetchUpdateUserPassword(data);
    if (!requestErrorUpdatePassword && !requestLoadingUpdatePassword) {
      enqueueSnackbar(`Senha alterada com sucesso!`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const isMobile = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    const redirectUnloggedUser = () => {
      if (!token.token) {
        return navigate("/login");
      } else if (
        user &&
        company &&
        !company?.company_id &&
        user?.systemRole === 4
      ) {
        return navigate("/");
      }
    };
    redirectUnloggedUser();

    const handlePhoneMask = () => {
      if (user.userPhone.length === 11 && phone === "" && loadingPhone) {
        setPhone(
          `(${user.userPhone.substring(0, 2)}) ${user.userPhone.substring(
            2,
            7
          )}-${user.userPhone.substring(7, 12)}`
        );
        setLoadingPhone(false);
      }
      if (!phone.includes(")") && phone.includes("(")) {
        setPhone("");
      }
    };
    handlePhoneMask();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    name,
    phone,
    email,
    lastName,
    erasing,
    loadingPhone,
    user,
    user.userPhone,
    password,
    newPassword,
    company,
    user,
  ]);

  return (
    <div
      className={
        isMobile
          ? classes.root
          : colorMode !== "dark"
          ? classes.rootDesktop
          : classes.rootDesktopDark
      }
    >
      <Data
        erasing={erasing}
        setErasing={setErasing}
        loadingPhone={loadingPhone}
        user={user}
        dispatchUserContext={dispatchUserContext}
        name={name}
        setName={setName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        colorMode={colorMode}
        password={password}
        setPassword={setPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        handleUpdatePassword={handleUpdatePassword}
        openPasswordScreen={openPasswordScreen}
        setOpenPasswordScreen={setOpenPasswordScreen}
      />
      <Address />
    </div>
  );
};

export default UserData;

import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFreight":
      return {
        Codigo: action.Codigo,
        Valor: action.Valor,
        PrazoEntrega: action.PrazoEntrega,
        ValorSemAdicionais: action.ValorSemAdicionais,
        ValorMaoPropria: action.ValorMaoPropria,
        ValorAvisoRecebimento: action.ValorAvisoRecebimento,
        ValorValorDeclarado: action.ValorValorDeclarado,
        EntregaDomiciliar: action.EntregaDomiciliar,
        EntregaSabado: action.EntregaSabado,
        obsFim: action.obsFim,
        Erro: action.Erro,
        MsgErro: action.MsgErro,
        Type: action.Type
    };
    default:
      throw new Error("Unexpected action");
  }
};

export const FreightContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <FreightContext.Provider value={context}>
      {children}
    </FreightContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  const { newSelectedTariffs } = action;
  switch (action.type) {
    case "setSelectedTariffs":
      return newSelectedTariffs;
    case "clearSelectedTariffs":
      return {};
    default:
      throw new Error("Unexpected action");
  }
};

export const SelectedTariffsContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <SelectedTariffsContext.Provider value={context}>
      {children}
    </SelectedTariffsContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded"; //nome e sobrenome
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded"; //email
import CallRoundedIcon from "@mui/icons-material/CallRounded"; //telefone
import { UserContext } from "../../contexts/User";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock"; //cadeado
import { useUpdateUser } from "../../API/Hooks";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  root: {
    height: "440px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "20px",
  },
  rootDesktop: {
    height: "440px",
    display: "flex",
    marginTop: "70px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rootDesktopChangePassword: {
    height: "390px",
    display: "flex",
    marginTop: "70px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rootMobileChangePassword: {
    height: "230px",
    display: "flex",
    margin: "70px 0 30px 0",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  inputsContainer: {
    height: "270px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionTitleDark: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "white",
  },
  buttonAndTextContainer: {
    display: "flex",
    justifyContent: "center",
  },
  buttonsChangeContainer: {
    display: "flex",
    padding: '0 30px 0 30px',
    justifyContent: "space-around"
  },
  buttonsChangeContainerMobile: {
    display: "flex",
    justifyContent: "space-around"
  },
  changePasswordContainer: {
    display: "flex",
    justifyContent: "right",
    width: "100%",
  },
  changePasswordContainerMobile: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  inputsContainerChangePassword: {
    height: "150px",
    display: "flex",
    marginBottom: '10px',
    flexDirection: "column",
    justifyContent: "space-around",
  },
  
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "30px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldEmail = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "30px",
  "& .MuiOutlinedInput-root": {
    paddingRight: "40px",
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldDesktopEmail = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    paddingRight: "40px",
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopEmailDark = styled(TextField, {
  name: "StyledTextField",
})({
  "& label.MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    paddingRight: "40px",
    color: "white",
    "& fieldset": {
      border: "1px solid white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  pointerEvents: "none",
  width: "450px",
  backgroundColor: "#474A56",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopDark = styled(TextField, {
  name: "StyledTextField",
})({
  "& label.MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      border: "1px solid white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  width: "450px",
  backgroundColor: "#474A56",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "20px",
  border: "1px solid #273682",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#EEF5ED",
    border: "1px solid #273682",
    color: "#273682"
  },
});

const StyledButtonChangePassword = styled(Button, {
  name: "StyledButton",
})({
  height: "30px",
  width: "130px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: 600,
  color: "white",
  marginTop: "20px",
  border: "1px solid #273682",
  fontSize: "1em",
  borderRadius: "5px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#EEF5ED",
    border: "1px solid #273682",
    color: "#273682"
  },
});

const StyledButtonDarkChangePassword = styled(Button, {
  name: "StyledButton",
})({
  height: "30px",
  width: "130px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  color: "white",
  fontWeight: "bold",
  marginTop: "20px",
  fontSize: "1em",
  borderRadius: "5px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
    border: "1px solid white",
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "20px",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
    border: "1px solid white",
  },
});

const StyledLockIcon = styled(LockIcon, {
  name: "StyledButton",
})({
  position: "absolute",
  marginRight: "10px",
  right: 0,
  zIndex: 1,
  height: "25px",
  width: "25px",
  "&:hover": {
    cursor: "default",
  },
});

const StyledLockIconDark = styled(LockIcon, {
  name: "StyledButton",
})({
  position: "absolute",
  marginRight: "10px",
  right: 0,
  zIndex: 1,
  color: "white",
  height: "25px",
  width: "25px",
  "&:hover": {
    cursor: "default",
  },
});

const StyledButtonCancel = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#273682",
  color: "white",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "20px",
  border: "1px solid #273682",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682",
    border: "1px solid #273682",
  },
});

const StyledButtonCancelDark = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "white",
  color: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "20px",
  fontSize: "1.3em",
  border: "1px solid #0B0B0D",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    color: "white",
    border: "1px solid white",
  },
});

const StyledTypography = styled(Typography, {
  name: "StyledCheckbox",
})({
  marginLeft: "60px",
});

const StyledAccountBoxRoundedIcon = styled(AccountBoxRoundedIcon, {
  name: "StyledButton",
})({
  color: "white",
  "&:hover": {
    cursor: "default",
  },
});

const StyledLockIconBigger = styled(LockIcon, {
  name: "StyledButton",
})({
  color: "white",
  "&:hover": {
    cursor: "default",
  },
});

const StyledLocalPostOfficeRoundedIcon = styled(LocalPostOfficeRoundedIcon, {
  name: "StyledButton",
})({
  color: "white",
  "&:hover": {
    cursor: "default",
  },
});

const StyledCallRoundedIcon = styled(CallRoundedIcon, {
  name: "StyledButton",
})({
  color: "white",
  "&:hover": {
    cursor: "default",
  },
});

const formSchema = yup.object().shape({
  password: yup.string().required("Senha obrigatória").min(6, "A senha precisa ter no pelo menos 6 dígitos"),
  newPassword: yup
  .string()
  .required("Confirmação de senha obrigatória").oneOf([yup.ref("password")], "As senhas não coincidem!"),
});

export const Data = ({
  user,
  dispatchUserContext,
  erasing,
  setErasing,
  loadingPhone,
  name,
  setName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  colorMode,
  password,
  newPassword,
  setPassword,
  setNewPassword,
  handleUpdatePassword,
  openPasswordScreen,
  setOpenPasswordScreen
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(openPasswordScreen && {
    resolver: yupResolver(formSchema),
  }
  );

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery("(max-width:900px)");

  const { fetchUpdateUser } = useUpdateUser();

  const handlePhone = (event) => {
    event.target.maxLength = 15;
    if (event.target.value.length === 2 && !erasing) {
      event.target.value = `(${event.target.value})`;
    } else if (event.target.value.length === 5 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        4
      )} ${event.target.value.substring(4)}`;
    } else if (event.target.value.length === 11 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        10
      )}-${event.target.value.substring(10)}`;
    }
    setPhone(event.target.value);
  };

  const handleName = (event) => {
    event.target.maxLength = 15;
    setName(event.target.value);
  };

  const handleLastName = (event) => {
    event.target.maxLength = 15;
    setLastName(event.target.value);
  };

  const handleErase = (event) => {
    if (event.keyCode === 8) {
      setErasing(true);
    } else {
      setErasing(false);
      if (isNaN(event.key)) {
        event.preventDefault();
        return false;
      }
    }
  };

  const handleLetters = (event) => {
    if (!isNaN(event.key) && event.keyCode !== 32) {
      event.preventDefault();
      return false;
    }
  };

  const handleUpdateUser = (data) => {
    const phone = data.phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    data.phone = data.phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    if (phone.length === 11 && name !== "" && lastName !== "") {
      fetchUpdateUser(data);
      dispatchUserContext({
        ...user,
        type: "updateSettingsUser",
        name,
        lastName,
        phone,
      });
      enqueueSnackbar("Dados atualizados!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else if (lastName === "") {
      enqueueSnackbar("Sobrenome inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else if (name === "") {
      enqueueSnackbar("Nome inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      enqueueSnackbar("Telefone inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  useEffect(() => {}, [
    erasing,
    phone,
    loadingPhone,
    user.userPhone,
    user,
    email,
    password,
    newPassword
  ]);

  return (
    <div className={isMobile && !openPasswordScreen ? classes.root : isMobile && openPasswordScreen ? classes.rootMobileChangePassword : !openPasswordScreen ? classes.rootDesktop : classes.rootDesktopChangePassword}>
      {!openPasswordScreen && <h2
        className={
          colorMode !== "dark" || isMobile
            ? classes.sessionTitle
            : classes.sessionTitleDark
        }
      >
        Dados Cadastrais
      </h2>}
      <div
        className={
          isMobile
            ? classes.changePasswordContainerMobile
            : classes.changePasswordContainer
        }
      >
        {(colorMode !== "dark" || isMobile) && !openPasswordScreen ? (
          <StyledButtonChangePassword onClick={() => setOpenPasswordScreen(true)}> 
            Alterar senha{" "}
          </StyledButtonChangePassword>
        ) : !openPasswordScreen && (
          <StyledButtonDarkChangePassword onClick={() => setOpenPasswordScreen(true)}> 
            Alterar senha
          </StyledButtonDarkChangePassword>
        )}
      </div>
      {isMobile && !openPasswordScreen ? (
        <>
          <form onSubmit={handleSubmit(handleUpdateUser)}>
            <div className={classes.inputsContainer}>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextField
                  id="name-input"
                  label="Nome"
                  type="text"
                  autoComplete="off"
                  value={name}
                  onKeyDown={handleLetters}
                  {...register("firstName")}
                  onChange={(e) => handleName(e)}
                />
              </div>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextField
                  id="last-name-input"
                  label="Sobrenome"
                  type="text"
                  autoComplete="off"
                  value={lastName}
                  onKeyDown={handleLetters}
                  {...register("lastName")}
                  onChange={(e) => handleLastName(e)}
                />
              </div>
              <div className={classes.IconAndInputContainer}>
                <StyledLockIcon />
                <LocalPostOfficeRoundedIcon />
                <StyledTextFieldEmail
                  disabled
                  id="email-input"
                  label="Email"
                  type="email"
                  value={email}
                  autoComplete="off"
                />
              </div>
              <div className={classes.IconAndInputContainer}>
                <CallRoundedIcon />
                <StyledTextField
                  id="phone-input"
                  label="Telefone"
                  type="text"
                  autoComplete="off"
                  inputProps={{autoFocus: true}}
                  onKeyDown={handleErase}
                  {...register("phone")}
                  value={phone}
                  onChange={(e) => handlePhone(e)}
                />
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Atualizar
              </StyledButton>
            </div>
          </form>
        </>
      ) : !openPasswordScreen ? (
        <>
          <form onSubmit={handleSubmit(handleUpdateUser)}>
            <div className={classes.inputsContainer}>
              <div className={classes.IconAndInputContainer}>
                {colorMode !== "dark" ? (
                  <AccountBoxRoundedIcon />
                ) : (
                  <StyledAccountBoxRoundedIcon />
                )}
                {colorMode !== "dark" ? (
                  <StyledTextFieldDesktop
                    id="name-input"
                    label="Nome"
                    type="text"
                    autoComplete="off"
                    value={name}
                    {...register("firstName")}
                    onKeyDown={handleLetters}
                    onChange={(e) => handleName(e)}
                  />
                ) : (
                  <StyledTextFieldDesktopDark
                    id="name-input"
                    label="Nome"
                    type="text"
                    autoComplete="off"
                    value={name}
                    {...register("firstName")}
                    onKeyDown={handleLetters}
                    onChange={(e) => handleName(e)}
                  />
                )}
              </div>
              <div className={classes.IconAndInputContainer}>
                {colorMode !== "dark" ? (
                  <AccountBoxRoundedIcon />
                ) : (
                  <StyledAccountBoxRoundedIcon />
                )}
                {colorMode !== "dark" ? (
                  <StyledTextFieldDesktop
                    id="last-name-input"
                    label="Sobrenome"
                    type="text"
                    autoComplete="off"
                    value={lastName}
                    {...register("lastName")}
                    onKeyDown={handleLetters}
                    onChange={(e) => handleLastName(e)}
                  />
                ) : (
                  <StyledTextFieldDesktopDark
                    id="last-name-input"
                    label="Sobrenome"
                    type="text"
                    autoComplete="off"
                    value={lastName}
                    {...register("lastName")}
                    onKeyDown={handleLetters}
                    onChange={(e) => handleLastName(e)}
                  />
                )}
              </div>
              <div className={classes.IconAndInputContainer}>
                {colorMode !== "dark" ? (
                  <StyledLockIcon />
                ) : (
                  <StyledLockIconDark />
                )}
                {colorMode !== "dark" ? (
                  <LocalPostOfficeRoundedIcon />
                ) : (
                  <StyledLocalPostOfficeRoundedIcon />
                )}
                {colorMode !== "dark" ? (
                  <StyledTextFieldDesktopEmail
                    disabled
                    id="email-input"
                    label="Email"
                    type="email"
                    value={email}
                    autoComplete="off"
                  />
                ) : (
                  <StyledTextFieldDesktopEmailDark
                    id="email-input"
                    label="Email"
                    type="email"
                    value={email}
                    autoComplete="off"
                  />
                )}
              </div>
              <div className={classes.IconAndInputContainer}>
                {colorMode !== "dark" ? (
                  <CallRoundedIcon />
                ) : (
                  <StyledCallRoundedIcon />
                )}
                {colorMode !== "dark" ? (
                  <StyledTextFieldDesktop
                    id="phone-input"
                    label="Telefone"
                    type="text"
                    autoComplete="off"
                    inputProps={{autoFocus: true}}
                    onKeyDown={handleErase}
                    {...register("phone")}
                    value={phone}
                    onChange={(e) => handlePhone(e)}
                  />
                ) : (
                  <StyledTextFieldDesktopDark
                    id="phone-input"
                    label="Telefone"
                    type="text"
                    inputProps={{autoFocus: true}}
                    autoComplete="off"
                    onKeyDown={handleErase}
                    {...register("phone")}
                    value={phone}
                    onChange={(e) => handlePhone(e)}
                  />
                )}
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              {colorMode !== "dark" ? (
                <StyledButton type="submit" variant="contained">
                  Atualizar
                </StyledButton>
              ) : (
                <StyledButtonDark type="submit" variant="contained">
                  Atualizar
                </StyledButtonDark>
              )}
            </div>
          </form>
        </>
      ) : !isMobile && openPasswordScreen ? (
        <form onSubmit={handleSubmit(handleUpdatePassword)}>
          <input type='hidden' value='something'/>
          <div className={classes.inputsContainerChangePassword}>
            <div className={classes.IconAndInputContainer}>
              {colorMode !== "dark" ? (
                <LockIcon />
              ) : (
                <StyledAccountBoxRoundedIcon />
              )}
              {colorMode !== "dark" ? (
                <StyledTextFieldDesktop
                  id="outlined-password-input-test-3"
                  label="Nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.password ? true : false}
                  value={password}
                  {...register("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <StyledTextFieldDesktopDark
                  id="outlined-password-input-test-4"
                  label="Nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.password ? true : false}
                  value={password}
                  {...register("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
            </div>
            <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.password?.message}
            </StyledTypography>
            <div className={classes.IconAndInputContainer}>
              {colorMode !== "dark" ? (
                <LockIcon />
              ) : (
                <StyledAccountBoxRoundedIcon />
              )}
              {colorMode !== "dark" ? (
                <StyledTextFieldDesktop
                  id="outlined-password-input-test-2"
                  label="Confirmar nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.newPassword ? true : false}
                  value={newPassword}
                  {...register("newPassword")}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              ) : (
                <StyledTextFieldDesktopDark
                  id="outlined-password-input-test"
                  label="Confirmar nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.newPassword ? true : false}
                  value={newPassword}
                  {...register("newPassword")}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              )}
            </div>
            <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.newPassword?.message}
            </StyledTypography>
          </div>
          <div className={classes.buttonsChangeContainer}>
            {colorMode !== "dark" ? (
              <StyledButton type="submit" variant="contained">
                Alterar
              </StyledButton>
            ) : (
              <StyledButtonDark type="submit" variant="contained">
                Alterar
              </StyledButtonDark>
            )}
                        {colorMode !== "dark" ? (
              <StyledButtonCancel onClick={() => setOpenPasswordScreen(false)} variant="contained">
                Cancelar
              </StyledButtonCancel>
            ) : (
              <StyledButtonCancelDark onClick={() => setOpenPasswordScreen(false)}  variant="contained">
                Cancelar
              </StyledButtonCancelDark>
            )}
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handleUpdatePassword)}>
          <input type='hidden' value='something'/>
          <div className={classes.inputsContainerChangePassword}>
            <div className={classes.IconAndInputContainer}>
                <LockIcon />
              {colorMode !== "dark" ? (
                <StyledTextField
                  id="outlined-password-input-test-3"
                  label="Nova senha"
                  type="password"
                  autoComplete="off"
                  value={password}
                  error={errors.password ? true : false}
                  {...register("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <StyledTextField
                  id="outlined-password-input-test-4"
                  label="Nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.password ? true : false}
                  value={password}
                  {...register("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
            </div>
            <StyledTypography variant="inherit" color="#d32f2f">
              {errors.password?.message}
            </StyledTypography>
            <div className={classes.IconAndInputContainer}>
                <LockIcon />
              {colorMode !== "dark" ? (
                <StyledTextField
                  id="outlined-password-input-test-2"
                  label="Confirmar nova senha"
                  type="password"
                  autoComplete="off"
                  value={newPassword}
                  error={errors.newPassword ? true : false}
                  {...register("newPassword")}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              ) : (
                <StyledTextField
                  id="outlined-password-input-test"
                  label="Confirmar nova senha"
                  type="password"
                  autoComplete="off"
                  error={errors.newPassword ? true : false}
                  value={newPassword}
                  {...register("newPassword")}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              )}
            </div>
            <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.newPassword?.message}
            </StyledTypography>
          </div>
          <div className={classes.buttonsChangeContainerMobile}>
              <StyledButton type="submit" variant="contained">
                Alterar
              </StyledButton>
              <StyledButtonCancel onClick={() => setOpenPasswordScreen(false)} variant="contained">
                Cancelar
              </StyledButtonCancel>
          </div>
        </form>
      )}
    </div>
  );
};

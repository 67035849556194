import axios from "axios";
import React from "react";
import { AuthContext } from "../../contexts/Auth";
import { ThemeContext } from "../../contexts/Theme";
import { UserContext } from "../../contexts/User";
import validateError from "../../utils/auth";
import { normalizeUserData } from "./Normalizers";
import { loginAuthBodyDefault } from "./APIConstants";
import { useValidateErrorResponse } from "./useValidateErrorResponse";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useSettingsUser = () => {
  const [, dispatchUserContext] = React.useContext(UserContext);
  const [, dispatchThemeContext] = React.useContext(ThemeContext);

  const [stateAuthContext] = React.useContext(AuthContext);
  const { token } = stateAuthContext;

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const {
    accessRoles,
    defaultScreen,
    userEmail,
    userId,
    userLanguage,
    userName,
    userTheme,
    userGroup,
    companyId,
    companyName,
    systemRole,
    userPhone,
    userLastName,
    companies
  } = requestData;

  React.useEffect(() => {
    if (
      accessRoles &&
      defaultScreen &&
      userEmail &&
      userId &&
      userLanguage &&
      userName &&
      userGroup &&
      userPhone &&
      userLastName
    ) {
      dispatchUserContext({
        type: "setSettingsUser",
        accessRoles,
        defaultScreen,
        userEmail,
        userId,
        userLanguage,
        userName,
        userPhone,
        userGroup,
        companyId,
        companyName,
        systemRole,
        companies,
        userLastName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestData,
    accessRoles,
    defaultScreen,
    userEmail,
    userId,
    userPhone,
    userLanguage,
    userName,
    userGroup,
    dispatchUserContext,
    userLastName,
  ]);

  React.useEffect(() => {
    if (userTheme) {
      dispatchThemeContext({
        type: "setUserTheme",
        userTheme,
      });
    }
  }, [userTheme, dispatchThemeContext]);

  const fetchSettingsUser = () => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .get(`${process.env.REACT_APP_API_URL}/user/settings`, {
            headers,
            cancelToken,
          })
          .then(({ data }) => normalizeUserData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  const setSettingsUserData = (data) => {
    dispatchUserContext({
      ...data,
      type: "setSettingsUser",
    });

    dispatchThemeContext({
      type: "setUserTheme",
      userTheme: data?.theme?.type || "light",
    });
  };

  return {
    requestError,
    requestLoading,
    fetchSettingsUser,
    setSettingsUserData,
  };
};

export const useUpdateUser = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchUpdateUser = ({ firstName, phone, lastName }) => {
    if (!requestLoading && firstName && phone && lastName) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          firstName,
          lastName,
          phone,
        };

        axios
          .put(`${process.env.REACT_APP_API_URL}/private/user`, body, {
            headers,
            cancelToken,
          })
          .then(() => setRequestData({ success: "success" }))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchUpdateUser, requestData };
};

export const useForgotPassword = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingReset, setRequestLoadingReset] = React.useState(false);
  const [requestErrorReset, setRequestErrorReset] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const fetchForgotPassword = ({ user }) => {
    if (!requestLoadingReset && user) {
      setRequestErrorReset(false);
      setRequestLoadingReset(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          user
        };

        axios
          .put(`${process.env.REACT_APP_API_URL}/public/auth/forgotPassword`, body, {
            cancelToken
          })
          .then(() => setRequestData({ success: "success" }))
          .catch((err) => {
            setRequestErrorReset(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingReset(false));
      });
    }
  };

  return { requestErrorReset, requestLoadingReset, fetchForgotPassword, requestData };
};

// export const useUpdateUserPassword = () => {
//   const { validateError } = useValidateErrorResponse();
//   const [requestLoadingUpdatePassword, setRequestLoadingUpdatePassword] = React.useState(false);
//   const [requestErrorUpdatePassword, setRequestErrorUpdatePassword] = React.useState(false);
//   const [requestDataUpdatePassword, setRequestDataUpdatePassword] = React.useState({});

//   const headers = createHeaders();

//   const fetchUpdateUserPassword = ({ password }) => {
//     if (!requestLoadingUpdatePassword && password) {
//       setRequestErrorUpdatePassword(false);
//       setRequestLoadingUpdatePassword(true);

//       const axiosToken = CancelToken.source();
//       const cancelToken = axiosToken?.token;

//       return new Promise((resolve, reject) => {
//         const body = {
//           ...loginAuthBodyDefault,
//           password
//         };

//         axios
//           .post(`${process.env.REACT_APP_API_URL}/private/user/newPassword `, body, {
//             headers,
//             cancelToken,
//           })
//           .then(() => setRequestDataUpdatePassword({ success: "success" }))
//           .catch((err) => {
//             setRequestErrorUpdatePassword(true);
//             const errMessage = validateError(err?.response?.data);
//             reject(errMessage);
//           })
//           .finally(() => setRequestLoadingUpdatePassword(false));
//       });
//     }
//   };

//   return { requestErrorUpdatePassword, requestLoadingUpdatePassword, fetchUpdateUserPassword, requestDataUpdatePassword };
// };
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "20px",
  },
  rootDesktop: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    minHeight: "calc(100vh - 150px)",
    alignItems: "flex-start",
  },
  rootDesktopDark: {
    display: "flex",
    backgroundColor: "#474A56",
    width: "100%",
    minHeight: "calc(100vh - 150px)",
    alignItems: "flex-start",
  },
  productDetailsContainer: {
    height: "650px",
    width: "80%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px solid #273682",
    borderRadius: "15px",
    justifyContent: "space-around",
    padding: "15px 0 15px 0",
  },
  productDetailsContainerDesktop: {
    height: "450px",
    width: "65%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    border: "3px solid #273682",
    borderRadius: "15px",
    justifyContent: "space-around",
    padding: "15px 0 15px 0",
  },
  orderDetailsContainer: {
    height: "450px",
    width: "90%",
    border: "3px solid #273682",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
    padding: "10px 40px 10px 40px",
  },
  orderDetailsContainerDesktop: {
    height: "500px",
    width: "27%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopDark: {
    height: "500px",
    width: "27%",
    border: "1px solid #0B0B0D",
    borderRadius: "15px",
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopBigger: {
    height: "460px",
    width: "22%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopBiggerComercial: {
    height: "310px",
    width: "22%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopBiggerDark: {
    height: "460px",
    width: "22%",
    border: "1px solid #0B0B0D",
    borderRadius: "15px",
    padding: "10px 40px 10px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  sessionName: {
    alignItems: "center",
    fontWeight: "bold",
    marginLeft: "10px",
    fontSize: "1.3em",
  },
  orderDetailsTitle: {
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  orderDetailsTitleMobile: {
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.2em",
    width: "80%",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionTitleDark: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "white",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sessionTitleContainerDark: {
    backgroundColor: "#474A56",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionProductMobile: {
    fontSize: "1.1em",
    textAlign: "justify",
    margin: "5px 15px 5px 15px",
    color: "black",
    maxHeight: "230px",
    overflow: "hidden",
  },
  descriptionTitle: {
    fontSize: "1.2em",
    textAlign: "left",
    marginLeft: "15px",
    fontWeight: "bold",
    color: "#273682",
  },
  stock: {
    textAlign: "center",
    color: "#0FA958",
    fontWeight: "bold",
    fontSize: "1.2em",
    marginLeft: "10px",
  },
  soldOff: {
    textAlign: "center",
    color: "#E11521",
    fontWeight: "bold",
    fontSize: "1.2em",
    marginLeft: "10px",
  },
  select: {
    width: "200px",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "7px",
    },
  },
  signatureSelected: {
    width: "100%!important",
    fontSize: "2px!important",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "15px 0 13px 10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "6px",
    },
  },
  selectAndTextFieldContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "120px",
  },
  selectAndTextFieldContainerDesktop: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "80px",
  },
  detailsParagraphLeft: {
    width: "100%",
    fontSize: "1em",
    textAlign: "justify",
  },
  detailsParagraphLeftSent: {
    width: "100%",
    textAlign: "justify",
    height: "20px",
    fontWeight: "600",
    fontSize: "1.1em",
  },
  detailsParagraphLeftTotal: {
    width: "90%",
    height: "20px",
    fontSize: "1.2em",
    fontWeight: "bold",
    textAlign: "left",
  },
  detailsParagraphRight: {
    width: "50%",
    fontSize: "1.1em",
    textAlign: "right",
  },
  detailsParagraphRightBold: {
    width: "100%",
    fontSize: "1.1em",
    textAlign: "left",
    fontWeight: "700",
    color: "#273682",
  },
  detailsParagraphRightBoldDark: {
    width: "100%",
    fontSize: "1.1em",
    textAlign: "left",
    fontWeight: "700",
    color: "#474A56",
  },
  paragraphsContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "280px",
    justifyContent: "center",
  },
  valueParagraphContainer: {
    display: "flex",
    flexDirection: "column",
    height: "60px",
    fontWeight: "600",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "110px",
  },
  productDetailsAndOrderDetailsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
  },
  imageNameAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "300px",
    marginBottom: "20px",
  },
  descriptionContainer: {
    height: "400px",
    display: "flex",
    width: "70%",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  cardsContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardsContainerMobile: {
    width: "90%",
  },
  noQuantity: {
    color: "#DA2727",
    fontSize: "0.8em",
  },
  companyNameTitle: {
    fontWeight: "600",
    fontSize: "1.1em",
    height: "20px",
  },
  linkAddress: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#273682",
    fontWeight: "600",
    height: "25px",
    marginTop: "5px",
  },
  linkAddressFreeShipping: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#273682",
    fontWeight: "600",
    height: "25px",
    marginTop: "-25px",
  },
  linkAddressDark: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#474A56",
    fontWeight: "600",
    height: "25px",
    marginTop: "5px",
  },
  linkAddressDarkFreeShipping: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#474A56",
    fontWeight: "600",
    height: "25px",
    marginTop: "-25px",
  },
  linkAddressBigger: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#273682",
    fontWeight: "600",
    height: "25px",
    marginTop: "20px",
  },
  linkAddressBiggerFreeShipping: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#273682",
    fontWeight: "600",
    height: "25px",
    marginTop: "-5px",
  },
  linkAddressBiggerDark: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#474A56",
    fontWeight: "600",
    height: "25px",
    marginTop: "20px",
  },
  linkAddressBiggerDarkFreeShipping: {
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    color: "#474A56",
    fontWeight: "600",
    height: "25px",
    marginTop: "-5px",
  },
  noOrdersContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 150px)",
    backgroundColor: "#EEF5ED",
  },
  noOrdersContainerDark: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 150px)",
    backgroundColor: "#474A56",
  },
  noOrdersContainerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 160px)",
    backgroundColor: "#EEF5ED",
  },
  noOrdersText: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#666666",
  },
  noOrdersTextDark: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "white",
  },
  noOrdersTextMobile: {
    fontSize: "1em",
    fontWeight: "600",
    color: "#666666",
  },
  freightContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100px",
    width: "80%",
    marginBottom: "20px",
  },
  freightContainerMobile: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "110px",
    width: "100%",
    marginTop: "15px",
    marginBottom: "20px",
  },
  calculateTitle: {
    fontWeight: "700",
    marginRight: "10px",
    fontSize: "1.2em",
    lineHeight: "1em",
  },
  calculateTitleDark: {
    fontWeight: "700",
    marginRight: "10px",
    fontSize: "1.2em",
    lineHeight: "1em",
    color: "white",
    textShadow: "3px 3px 10px #0B0B0D",
  },
  calculateTitleMobile: {
    fontWeight: "700",
    marginRight: "5px",
    fontSize: "1.2em",
    lineHeight: "1em",
    width: "70px",
  },
  inputFreightContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 10px 0",
  },
  inputFreightContainerAndText: {
    height: "90px",
  },
  resultContainer: {
    display: "flex",
    width: "500px",
  },
  resultContainerMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "140px",
  },
  radioContainerFreeShipping: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "100px",
  },
  radioContainerBigger: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  containerRadios: {
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  containerRadiosMobile: {
    height: "53px",
    display: "flex",
    width: "170px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  sentParagraph: {
    width: "100%",
  },
  freightCalculatedParagraphMobile: {
    fontWeight: "600",
    color: "#273682",
    textAlign: "center",
  },
  freightCalculatedParagraph: {
    marginRight: "35px",
    fontWeight: "600",
    color: "#273682",
  },
  freightCalculatedParagraphDark: {
    marginRight: "35px",
    fontWeight: "600",
    color: "white",
    textShadow: "3px 3px 5px #0B0B0D",
  },
  spanFormControl: {
    width: "130px",
  },
  paragraphFreight: {
    fontSize: "0.9em",
    textAlign: "justify",
    fontWeight: "500",
    color: "#273682",
  },
  freeShipping: {
    fontSize: "1.2em",
    color: "#0FA958",
    fontWeight: "600",
  },
  freeShippingParagraphDark: {
    textAlign: "left",
    fontWeight: "600",
    color: "#0FA958",
    textShadow: "3px 3px 5px #0B0B0D",
  },
  freeShippingParagraph: {
    textAlign: "left",
    fontWeight: "600",
    color: "#0FA958",
  },
});

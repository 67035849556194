import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { fontWeight, styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { StatusProductMobile } from "../statusProductMobile";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { modeContext } from "../../App";
import CheckIcon from '@mui/icons-material/Check';
import { UserContext } from "../../contexts/User";

const useStyles = makeStyles({
  root: {
    width: "80%",
    display: "flex",
    justifyContent: "space-around",
    height: "450px",
  },
  rootBigger: {
    width: "80%",
    display: "flex",
    justifyContent: "space-around",
    height: "540px",
  },
  rootDesktopNotLoaded: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "3px solid #273682",
    opacity: 0,
    paddingTop: "25px",
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-around",
    height: "490px",
  },
  rootDesktop: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "3px solid #273682",
    opacity: "100%",
    transition: "opacity 0.5s",
    paddingTop: "25px",
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-around",
    height: "490px",
  },
  rootDesktopBiggerNotLoaded: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
    opacity: 0,
    border: "3px solid #273682",
    paddingTop: "25px",
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-around",
    height: "540px",
  },
  rootDesktopBigger: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
    opacity: "100%",
    transition: "opacity 0.5s",
    border: "3px solid #273682",
    paddingTop: "25px",
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-around",
    height: "540px",
  },
  allStatusContainer: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  allStatusContainerMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconsAndLines: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  line: {
    height: "60px",
    width: "2px",
    backgroundColor: "#8dd477",
    opacity: "60%",
  },
  lineDark: {
    height: "60px",
    width: "2px",
    backgroundColor: "#8dd477",
  },
  lineGray: {
    height: "60px",
    width: "2px",
    backgroundColor: "#CCCCCC",
  },
  lineGrayDark: {
    height: "60px",
    width: "2px",
    backgroundColor: "white",
  },
  lineBigger: {
    height: "110px",
    width: "2px",
    backgroundColor: "#8dd477",
    opacity: "60%",
  },
  lineBiggerDark: {
    height: "110px",
    width: "2px",
    backgroundColor: "#8dd477",
  },
  lineBiggerGray: {
    height: "110px",
    width: "2px",
    backgroundColor: "#CCCCCC",
  },
  lineBiggerGrayDark: {
    height: "110px",
    width: "2px",
    backgroundColor: "white",
  },
  circle: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    backgroundColor: "#8dd477",
    position: "relative",
  },
  grayCircle: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    border: "4px solid #CCCCCC",
    position: "relative",
  },
  grayCircleDark: {
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    border: "4px solid white",
    position: "relative",
  },
  leftLine: {
    height: "12px",
    width: "3px",
    backgroundColor: "white",
    position: "absolute",
    left: 10.4,
    top: 16.5,
    transform: "rotate(-45deg)",
    borderRadius: "2px 2px 0 0",
  },
  rightLine: {
    height: "22px",
    width: "3px",
    backgroundColor: "white",
    position: "absolute",
    right: 15.6,
    top: 10.4,
    transform: "rotate(45deg)",
    borderRadius: "2px 2px 0 0",
  },
  statusTitle: {
    color: "#8BA16A",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "20px",
  },
  statusTitleDark: {
    color: "#8dd477",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "20px",
  },
  statusTitleLast: {
    color: "#939393",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "40px",
  },
  statusTitleLastDark: {
    color: "white",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "40px",
  },
  statusTitleLastWithoutCode: {
    color: "#939393",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "40px",
    marginBottom: "60px",
  },
  statusTitleLastWithoutCodeDark: {
    color: "white",
    fontWeight: "600",
    fontSize: "1.2em",
    lineHeight: "40px",
    marginBottom: "60px",
  },
  statusContainer: {
    height: "40px",
  },
  statusDate: {
    lineHeight: "20px",
    fontSize: "1em",
    marginBottom: "60px",
  },
  emptyDiv: {
    height: "20px",
    marginBottom: "60px",
  },
  statusDateDark: {
    lineHeight: "20px",
    fontSize: "1em",
    marginBottom: "60px",
    color: "white",
  },
  statusLastDate: {
    lineHeight: "20px",
    fontSize: "1em",
  },
  statusLastButOneDate: {
    lineHeight: "20px",
    fontSize: "1em",
    marginBottom: "40px",
  },
  statusLastButOneDateDark: {
    lineHeight: "20px",
    fontSize: "1em",
    marginBottom: "40px",
    color: "white",
  },
  statusTracking: {
    fontWeight: "600",
    fontSize: "1.1em",
    lineHeight: "20px",
    color: "#737373",
  },
  statusCode: {
    fontWeight: "600",
    fontSize: "1em",
    lineHeight: "20px",
    color: "#273682",
    marginBottom: "25px",
  },
  statusCodeDark: {
    fontWeight: "600",
    fontSize: "1em",
    lineHeight: "20px",
    color: "white",
    marginBottom: "25px",
    textShadow: "3px 3px 5px #0b0b0d",
  },
  noOrdersContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 250px)",
  },
  noOrdersText: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#666666",
  },
  noOrdersTextDark: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "white",
  },
  seeAll: {
    lineHeight: "100px",
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#273682",
  },
  numberOfPackageParagraphNotLoaded: {
    textAlign: "center",
    // marginBottom: "35px",
    fontSize: "1.2em",
    width: "150px",
    opacity: 0,
    fontWeight: "700",
    color: "#273682",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
    borderTop: "2px solid #273682",
    borderLeft: "2px solid #273682",
    borderRight: "2px solid #273682",
  },
  numberOfPackageParagraph: {
    textAlign: "center",
    // marginBottom: "35px",
    fontSize: "1.2em",
    width: "150px",
    opacity: "100%",
    transition: "opacity 0.5s",
    fontWeight: "700",
    color: "#273682",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
    borderTop: "2px solid #273682",
    borderLeft: "2px solid #273682",
    borderRight: "2px solid #273682",
  },
  numberOfPackageParagraphDark: {
    textAlign: "center",
    marginBottom: "35px",
    fontSize: "1.2em",
    width: "150px",
    fontWeight: "700",
    color: "#0B0B0D",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "2px solid #0B0B0D",
  },
  codeTitle: {
    height: "25px",
    fontWeight: "600",
  },
  codeTitleDark: {
    height: "25px",
    fontWeight: "600",
    color: "white",
  },
});

const StyledSentimentVeryDissatisfiedIcon = styled(
  SentimentVeryDissatisfiedIcon,
  {
    name: "StyledSentimentVeryDissatisfiedIcon",
  }
)({
  height: "65px",
  width: "65px",
  color: "#666666",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledSentimentVeryDissatisfiedIconDark = styled(
  SentimentVeryDissatisfiedIcon,
  {
    name: "StyledSentimentVeryDissatisfiedIcon",
  }
)({
  height: "65px",
  width: "65px",
  color: "white",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledCheckIcon = styled(CheckIcon, {
  name: "StyledStyledCheckIcon",
})({
  height: "36px",
  width: "37px",
  marginTop: "2px",
  color: "white",
  // marginLeft: "24px",
  "&:hover": {
    cursor: "pointer",
  },
});

export const LastOrderStatus = ({ lastOrder, orders, loaded }) => {
  const isMobile = useMediaQuery("(max-width:900px)");

  const colorMode = useContext(modeContext);

  const [user, dispatchUserContext] = useContext(UserContext)

  const classes = useStyles();

  return isMobile ? (
    <>
      {orders && orders?.data?.length !== 0 && (
        <StatusProductMobile lastOrder={lastOrder} />
      )}
      {(lastOrder === undefined) ? (
        <div className={classes.noOrdersContainer}>
          <h3 className={classes.noOrdersText}>
            Você ainda não realizou nenhum pedido!
          </h3>
          <StyledSentimentVeryDissatisfiedIcon />
        </div>
      ) : (
        <div className={classes.allStatusContainerMobile}>
          {lastOrder.orders.map((order, index) =>
            order.comments[order.comments.length - 1].statusNow ===
            "delivered" ? (
              <div className={classes.rootBigger}>
                <div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.lineBigger}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                  </div>
                </div>

                <div className={classes.statusContainer}>
                  <h3 className={classes.statusTitle}>Pedido Realizado.</h3>
                  <p className={classes.statusDate}>
                    {typeof order.comments.filter(
                      (status) =>
                        status.statusNow.includes("awaiting_payment") ||
                        status.statusNow.includes(
                          "awaiting_payment_for_chip_order"
                        )
                    )[0].createdAt === "string" &&
                    order.comments
                      .filter(
                        (status) =>
                          status.statusNow.includes("awaiting_payment") ||
                          status.statusNow.includes(
                            "awaiting_payment_for_chip_order"
                          )
                      )[0]
                      .createdAt.includes("T")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[1]
                            .split(".")[0]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("awaiting_payment") ||
                              status.statusNow.includes(
                                "awaiting_payment_for_chip_order"
                              )
                          )[0]
                          .createdAt.includes(" ")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[1]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : "Data antiga"}
                  </p>
                  <h3 className={classes.statusTitle}>{user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("chips_order")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("chips_order")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("chips_order")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitle}>Preparando Pedido.</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("packing")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("packing")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("packing")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("packing")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitle}>Pedido Enviado.</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("packing")
                  ) !== undefined ? (
                    <p className={classes.statusLastButOneDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("posted")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("posted")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("posted")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.codeTitle}>Código de Rastreamento:</h3>
                  <p className={classes.statusCode}>{order.post_code}</p>
                  <h3 className={classes.statusTitle}>Pedido Entregue.</h3>
                  <p className={classes.statusDate}>
                    {typeof order.comments.filter((status) =>
                      status.statusNow.includes("delivered")
                    )[0].createdAt === "string" &&
                    order.comments
                      .filter((status) =>
                        status.statusNow.includes("delivered")
                      )[0]
                      .createdAt.includes("T")
                      ? moment(
                          `${order.comments
                            .filter((status) =>
                              status.statusNow.includes("delivered")
                            )[0]
                            .createdAt.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter((status) =>
                              status.statusNow.includes("delivered")
                            )[0]
                            .createdAt.split("T")[1]
                            .split(".")[0]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : order.comments
                          .filter((status) =>
                            status.statusNow.includes("delivered")
                          )[0]
                          .createdAt.includes(" ")
                      ? moment(
                          `${order.comments
                            .filter((status) =>
                              status.statusNow.includes("delivered")
                            )[0]
                            .createdAt.split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter((status) =>
                              status.statusNow.includes("delivered")
                            )[0]
                            .createdAt.split(" ")[1]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : "Data antiga"}
                  </p>
                </div>
              </div>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "posted" ? (
              <div className={classes.rootBigger}>
                <div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.lineBiggerGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                  </div>
                </div>

                <div className={classes.statusContainer}>
                  <h3 className={classes.statusTitle}>Pedido Realizado.</h3>
                  <p className={classes.statusDate}>
                    {typeof order.comments.filter(
                      (status) =>
                        status.statusNow.includes("awaiting_payment") ||
                        status.statusNow.includes(
                          "awaiting_payment_for_chip_order"
                        )
                    )[0].createdAt === "string" &&
                    order.comments
                      .filter(
                        (status) =>
                          status.statusNow.includes("awaiting_payment") ||
                          status.statusNow.includes(
                            "awaiting_payment_for_chip_order"
                          )
                      )[0]
                      .createdAt.includes("T")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[1]
                            .split(".")[0]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("awaiting_payment") ||
                              status.statusNow.includes(
                                "awaiting_payment_for_chip_order"
                              )
                          )[0]
                          .createdAt.includes(" ")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[1]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : "Data antiga"}
                  </p>
                  <h3 className={classes.statusTitle}>{user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("chips_order")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("chips_order")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("chips_order")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitle}>Preparando Pedido.</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("packing")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("packing")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("packing")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("packing")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitle}>Pedido Enviado.</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("posted")
                  ) !== undefined ? (
                    <p className={classes.statusLastButOneDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("posted")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("posted")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("posted")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("posted")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <p className={classes.codeTitle}>Código de Rastreamento:</p>
                  <p className={classes.statusCode}>{order.post_code}</p>
                  <h3 className={classes.statusTitleLast}>Pedido Entregue.</h3>
                </div>
              </div>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "packing" ? (
              <div className={classes.root}>
                <div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                      <div className={classes.leftLine}></div>
                      <div className={classes.rightLine}></div>
                    </div>
                    <div className={classes.lineGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                    <div className={classes.lineGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                  </div>
                </div>

                <div className={classes.statusContainer}>
                  <h3 className={classes.statusTitle}>Pedido Realizado.</h3>
                  <p className={classes.statusDate}>
                    {typeof order.comments.filter(
                      (status) =>
                        status.statusNow.includes("awaiting_payment") ||
                        status.statusNow.includes(
                          "awaiting_payment_for_chip_order"
                        )
                    )[0].createdAt === "string" &&
                    order.comments
                      .filter(
                        (status) =>
                          status.statusNow.includes("awaiting_payment") ||
                          status.statusNow.includes(
                            "awaiting_payment_for_chip_order"
                          )
                      )[0]
                      .createdAt.includes("T")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[1]
                            .split(".")[0]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("awaiting_payment") ||
                              status.statusNow.includes(
                                "awaiting_payment_for_chip_order"
                              )
                          )[0]
                          .createdAt.includes(" ")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[1]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : "Data antiga"}
                  </p>
                  <h3 className={classes.statusTitle}>{user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("chips_order")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("chips_order")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("chips_order")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitle}>Preparando Pedido.</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("packing")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("packing")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("packing")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("packing")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("packing")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitleLastWithoutCode}>
                    Pedido Enviado.
                  </h3>
                  <h3 className={classes.statusTitleLast}>Pedido Entregue.</h3>
                </div>
              </div>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "chips_order" ? (
              <div className={classes.root}>
                <div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.line}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.circle}>
                    <StyledCheckIcon/>
                    </div>
                    <div className={classes.lineGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                    <div className={classes.lineGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                    <div className={classes.lineGray}></div>
                  </div>
                  <div className={classes.iconsAndLines}>
                    <div className={classes.grayCircle}></div>
                  </div>
                </div>

                <div className={classes.statusContainer}>
                  <h3 className={classes.statusTitle}>Pedido Realizado.</h3>
                  <p className={classes.statusDate}>
                    {typeof order.comments.filter(
                      (status) =>
                        status.statusNow.includes("awaiting_payment") ||
                        status.statusNow.includes(
                          "awaiting_payment_for_chip_order"
                        )
                    )[0].createdAt === "string" &&
                    order.comments
                      .filter(
                        (status) =>
                          status.statusNow.includes("awaiting_payment") ||
                          status.statusNow.includes(
                            "awaiting_payment_for_chip_order"
                          )
                      )[0]
                      .createdAt.includes("T")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split("T")[1]
                            .split(".")[0]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("awaiting_payment") ||
                              status.statusNow.includes(
                                "awaiting_payment_for_chip_order"
                              )
                          )[0]
                          .createdAt.includes(" ")
                      ? moment(
                          `${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("")}${order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes(
                                  "awaiting_payment_for_chip_order"
                                )
                            )[0]
                            .createdAt.split(" ")[1]
                            .replace(":", "")
                            .replace(":", "")}`,
                          "DDMMYYYYHHmmss"
                        )
                          .add(-3, "hours")
                          .format("DD/MM/YYYY - HH:mm")
                      : "Data antiga"}
                  </p>
                  <h3 className={classes.statusTitle}>{user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}</h3>
                  {order.comments.find((status) =>
                    status.statusNow.includes("chips_order")
                  ) !== undefined ? (
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter((status) =>
                        status.statusNow.includes("chips_order")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter((status) =>
                              status.statusNow.includes("chips_order")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                  ) : (
                    <div className={classes.emptyDiv}></div>
                  )}
                  <h3 className={classes.statusTitleLastWithoutCode}>
                    Preparando Pedido.
                  </h3>
                  <h3 className={classes.statusTitleLastWithoutCode}>
                    Pedido Enviado.
                  </h3>
                  <h3 className={classes.statusTitleLast}>Pedido Entregue.</h3>
                </div>
              </div>
            ) : order.comments[order.comments.length - 1].statusNow ===
                "awaiting_payment" ||
              order.comments[order.comments.length - 1].statusNow ===
                "awaiting_payment_for_chip_order" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2 className={loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded}>
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={classes.root}>
                  <div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div className={classes.lineGray}></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.grayCircle}></div>
                      <div className={classes.lineGray}></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.grayCircle}></div>
                      <div className={classes.lineGray}></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.grayCircle}></div>
                      <div className={classes.lineGray}></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.grayCircle}></div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3 className={classes.statusTitle}>Pedido Realizado.</h3>
                    <p className={classes.statusDate}>
                      {typeof order.comments.filter(
                        (status) =>
                          status.statusNow.includes("awaiting_payment") ||
                          status.statusNow.includes("awaiting_payment")
                      )[0].createdAt === "string" &&
                      order.comments
                        .filter(
                          (status) =>
                            status.statusNow.includes("awaiting_payment") ||
                            status.statusNow.includes("awaiting_payment")
                        )[0]
                        .createdAt.includes("T")
                        ? moment(
                            `${order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes(
                                    "awaiting_payment"
                                  ) ||
                                  status.statusNow.includes("awaiting_payment")
                              )[0]
                              .createdAt.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes(
                                    "awaiting_payment"
                                  ) ||
                                  status.statusNow.includes("awaiting_payment")
                              )[0]
                              .createdAt.split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.comments
                            .filter(
                              (status) =>
                                status.statusNow.includes("awaiting_payment") ||
                                status.statusNow.includes("awaiting_payment")
                            )[0]
                            .createdAt.includes(" ")
                        ? moment(
                            `${order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes(
                                    "awaiting_payment"
                                  ) ||
                                  status.statusNow.includes("awaiting_payment")
                              )[0]
                              .createdAt.split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes(
                                    "awaiting_payment"
                                  ) ||
                                  status.statusNow.includes("awaiting_payment")
                              )[0]
                              .createdAt.split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>

                    <h3 className={classes.statusTitleLastWithoutCode}>
                    {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    <h3 className={classes.statusTitleLastWithoutCode}>
                      Preparando Pedido.
                    </h3>
                    <h3 className={classes.statusTitleLastWithoutCode}>
                      Pedido Enviado.
                    </h3>
                    <h3 className={classes.statusTitleLast}>
                      Pedido Entregue.
                    </h3>
                  </div>
                </div>
              </>
            ) : null
          )}
        </div>
      )}
      {orders && orders?.data?.length !== 0 && (
        <Link to="/orders" className={classes.seeAll}>
          Ver Todos os Pedidos
        </Link>
      )}
    </>
  ) : (
    //desktop abaixo -------------------------- desktop abaixo ----------------
    <>
      {(lastOrder === undefined) ? (
        <div className={classes.noOrdersContainer}>
          <h3
            className={
              colorMode !== "dark"
                ? classes.noOrdersText
                : classes.noOrdersTextDark
            }
          >
            Você ainda não realizou nenhum pedido!
          </h3>
          {colorMode !== "dark" ? (
            <StyledSentimentVeryDissatisfiedIcon />
          ) : (
            <StyledSentimentVeryDissatisfiedIconDark />
          )}
        </div>
      ) : (
        <div className={classes.allStatusContainer}>
          {lastOrder.orders.map((order, index) =>
            order.comments[order.comments.length - 1].statusNow ===
              "delivered" ||
            order.comments[order.comments.length - 1].statusNow ===
              "finished" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2
                    className={
                      loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded
                    }
                  >
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={loaded ? classes.rootDesktopBigger : classes.rootDesktopBiggerNotLoaded}>
                  <div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineBigger
                            : classes.lineBiggerDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Realizado.
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusDate
                          : classes.statusDateDark
                      }
                    >
                      {typeof order.date_created_at === "string" &&
                      order.date_created_at.includes("T")
                        ? moment(
                            `${order.date_created_at
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.date_created_at.includes(" ")
                        ? moment(
                            `${order.date_created_at
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("chips_order")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter((status) =>
                            status.statusNow.includes("chips_order")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Preparando Pedido.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("packing")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("processing") ||
                            status.statusNow.includes("enable") ||
                            status.statusNow.includes("packing") ||
                            status.statusNow.includes("ready_to_post")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("processing") ||
                              status.statusNow.includes("enable") ||
                              status.statusNow.includes("packing") ||
                              status.statusNow.includes("ready_to_post")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("processing") ||
                                  status.statusNow.includes("enable") ||
                                  status.statusNow.includes("packing") ||
                                  status.statusNow.includes("ready_to_post")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Enviado.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("posted")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusLastButOneDate
                            : classes.statusLastButOneDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("posted") ||
                            status.statusNow.includes("in_transit")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("posted") ||
                              status.statusNow.includes("in_transit")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("posted") ||
                                  status.statusNow.includes("in_transit")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.codeTitle
                          : classes.codeTitleDark
                      }
                    >
                      Código de Rastreamento:
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusCode
                          : classes.statusCodeDark
                      }
                    >
                      {order.post_code}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Entregue.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("delivered")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("delivered") ||
                            status.statusNow.includes("finished")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("delivered") ||
                              status.statusNow.includes("finished")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("delivered") ||
                                    status.statusNow.includes("finished")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("delivered") ||
                                    status.statusNow.includes("finished")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("delivered") ||
                                  status.statusNow.includes("finished")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("delivered") ||
                                    status.statusNow.includes("finished")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("delivered") ||
                                    status.statusNow.includes("finished")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}
                  </div>
                </div>
              </>
            ) : order.comments[order.comments.length - 1].statusNow ===
                "posted" ||
              order.comments[order.comments.length - 1].statusNow ===
                "in_transit" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2
                    className={
                      loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded
                    }
                  >
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={loaded ? classes.rootDesktopBigger : classes.rootDesktopBiggerNotLoaded}>
                  <div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineBiggerGray
                            : classes.lineBiggerGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Realizado.
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusDate
                          : classes.statusDateDark
                      }
                    >
                      {typeof order.date_created_at === "string" &&
                      order.date_created_at.includes("T")
                        ? moment(
                            `${order.date_created_at
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.date_created_at.includes(" ")
                        ? moment(
                            `${order.date_created_at
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("chips_order")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter((status) =>
                            status.statusNow.includes("chips_order")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}

                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Preparando Pedido.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("packing")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("processing") ||
                            status.statusNow.includes("enable") ||
                            status.statusNow.includes("packing") ||
                            status.statusNow.includes("ready_to_post")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("processing") ||
                              status.statusNow.includes("enable") ||
                              status.statusNow.includes("packing") ||
                              status.statusNow.includes("ready_to_post")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("processing") ||
                                  status.statusNow.includes("enable") ||
                                  status.statusNow.includes("packing") ||
                                  status.statusNow.includes("ready_to_post")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}

                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Enviado.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("posted")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusLastButOneDate
                            : classes.statusLastButOneDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("posted") ||
                            status.statusNow.includes("in_transit")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("posted") ||
                              status.statusNow.includes("in_transit")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("posted") ||
                                  status.statusNow.includes("in_transit")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("posted") ||
                                    status.statusNow.includes("in_transit")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}

                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.codeTitle
                          : classes.codeTitleDark
                      }
                    >
                      Código de Rastreamento:
                    </p>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusCode
                          : classes.statusCodeDark
                      }
                    >
                      {order.post_code}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLast
                          : classes.statusTitleLastDark
                      }
                    >
                      Pedido Entregue.
                    </h3>
                  </div>
                </div>
              </>
            ) : order.comments[order.comments.length - 1].statusNow ===
                "packing" ||
              order.comments[order.comments.length - 1].statusNow ===
                "ready_to_post" ||
              order.comments[order.comments.length - 1].statusNow ===
                "processing" ||
              order.comments[order.comments.length - 1].statusNow ===
                "enable" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2
                    className={
                      loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded
                    }
                  >
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={loaded ? classes.rootDesktop : classes.rootDesktopNotLoaded}>
                  <div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Realizado.
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusDate
                          : classes.statusDateDark
                      }
                    >
                      {typeof order.date_created_at === "string" &&
                      order.date_created_at.includes("T")
                        ? moment(
                            `${order.date_created_at
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.date_created_at.includes(" ")
                        ? moment(
                            `${order.date_created_at
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("chips_order")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter((status) =>
                            status.statusNow.includes("chips_order")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}

                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Preparando Pedido.
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("packing")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter(
                          (status) =>
                            status.statusNow.includes("processing") ||
                            status.statusNow.includes("enable") ||
                            status.statusNow.includes("packing") ||
                            status.statusNow.includes("ready_to_post")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter(
                            (status) =>
                              status.statusNow.includes("processing") ||
                              status.statusNow.includes("enable") ||
                              status.statusNow.includes("packing") ||
                              status.statusNow.includes("ready_to_post")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter(
                                (status) =>
                                  status.statusNow.includes("processing") ||
                                  status.statusNow.includes("enable") ||
                                  status.statusNow.includes("packing") ||
                                  status.statusNow.includes("ready_to_post")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter(
                                  (status) =>
                                    status.statusNow.includes("processing") ||
                                    status.statusNow.includes("enable") ||
                                    status.statusNow.includes("packing") ||
                                    status.statusNow.includes("ready_to_post")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      Pedido Enviado.
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLast
                          : classes.statusTitleLastDark
                      }
                    >
                      Pedido Entregue.
                    </h3>
                  </div>
                </div>
              </>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "chips_order" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2
                    className={
                      loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded
                    }
                  >
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={loaded ? classes.rootDesktop : classes.rootDesktopNotLoaded}>
                  <div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark" ? classes.line : classes.lineDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Realizado.
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusDate
                          : classes.statusDateDark
                      }
                    >
                      {typeof order.date_created_at === "string" &&
                      order.date_created_at.includes("T")
                        ? moment(
                            `${order.date_created_at
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.date_created_at.includes(" ")
                        ? moment(
                            `${order.date_created_at
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    {order.comments.find((status) =>
                      status.statusNow.includes("chips_order")
                    ) !== undefined ? (
                      <p
                        className={
                          colorMode !== "dark"
                            ? classes.statusDate
                            : classes.statusDateDark
                        }
                      >
                        {typeof order.comments.filter((status) =>
                          status.statusNow.includes("chips_order")
                        )[0].createdAt === "string" &&
                        order.comments
                          .filter((status) =>
                            status.statusNow.includes("chips_order")
                          )[0]
                          .createdAt.includes("T")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split("T")[1]
                                .split(".")[0]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : order.comments
                              .filter((status) =>
                                status.statusNow.includes("chips_order")
                              )[0]
                              .createdAt.includes(" ")
                          ? moment(
                              `${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("")}${order.comments
                                .filter((status) =>
                                  status.statusNow.includes("chips_order")
                                )[0]
                                .createdAt.split(" ")[1]
                                .replace(":", "")
                                .replace(":", "")}`,
                              "DDMMYYYYHHmmss"
                            )
                              .add(-3, "hours")
                              .format("DD/MM/YYYY - HH:mm")
                          : "Data antiga"}
                      </p>
                    ) : (
                      <div className={classes.emptyDiv}></div>
                    )}

                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      Preparando Pedido.
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      Pedido Enviado.
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLast
                          : classes.statusTitleLastDark
                      }
                    >
                      Pedido Entregue.
                    </h3>
                  </div>
                </div>
              </>
            ) : order.comments[order.comments.length - 1].statusNow ===
                "awaiting_payment_for_chip_order" ||
              order.comments[order.comments.length - 1].statusNow ===
                "awaiting_payment" ||
              order.comments[order.comments.length - 1].statusNow ===
                "being_analyzed" ? (
              <>
                {lastOrder.orders.length > 1 && (
                  <h2
                    className={
                      loaded ? classes.numberOfPackageParagraph : classes.numberOfPackageParagraphNotLoaded
                    }
                  >
                    Pacote {index + 1}
                  </h2>
                )}
                <div className={loaded ? classes.rootDesktop : classes.rootDesktopNotLoaded}>
                  <div className={classes.draw}>
                    <div className={classes.iconsAndLines}>
                      <div className={classes.circle}>
                      <StyledCheckIcon/>
                      </div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.lineGray
                            : classes.lineGrayDark
                        }
                      ></div>
                    </div>
                    <div className={classes.iconsAndLines}>
                      <div
                        className={
                          colorMode !== "dark"
                            ? classes.grayCircle
                            : classes.grayCircleDark
                        }
                      ></div>
                    </div>
                  </div>

                  <div className={classes.statusContainer}>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitle
                          : classes.statusTitleDark
                      }
                    >
                      Pedido Realizado.
                    </h3>
                    <p
                      className={
                        colorMode !== "dark"
                          ? classes.statusDate
                          : classes.statusDateDark
                      }
                    >
                      {typeof order.date_created_at === "string" &&
                      order.date_created_at.includes("T")
                        ? moment(
                            `${order.date_created_at
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split("T")[1]
                              .split(".")[0]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : order.date_created_at.includes(" ")
                        ? moment(
                            `${order.date_created_at
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("")}${order.date_created_at
                              .split(" ")[1]
                              .replace(":", "")
                              .replace(":", "")}`,
                            "DDMMYYYYHHmmss"
                          )
                            .add(-3, "hours")
                            .format("DD/MM/YYYY - HH:mm")
                        : "Data antiga"}
                    </p>

                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      {user?.systemRole !== 4 ? "Pagamento Aprovado." : "Aprovado pelo Comercial"}
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      Preparando Pedido.
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLastWithoutCode
                          : classes.statusTitleLastWithoutCodeDark
                      }
                    >
                      Pedido Enviado.
                    </h3>
                    <h3
                      className={
                        colorMode !== "dark"
                          ? classes.statusTitleLast
                          : classes.statusTitleLastDark
                      }
                    >
                      Pedido Entregue.
                    </h3>
                  </div>
                </div>
              </>
            ) : null
          )}
        </div>
      )}
    </>
  );
};

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/system";

export const StyledButtonCart = styled(Button, {
    name: "StyledButtonCart",
  })({
    height: "45px",
    width: "100%",
    backgroundColor: "#273682",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1em",
    border: "1px solid transparent",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "#273682",
      border: "1px solid #273682",
    },
  });
  
  export const StyledButtonCartDark = styled(Button, {
    name: "StyledButtonCart",
  })({
    height: "45px",
    width: "100%",
    backgroundColor: "white",
    color: "#0B0B0D",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1em",
    border: "1px solid #0B0B0D",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2B2B30",
      color: "white",
      border: "1px solid white",
    },
  });
  
  export const StyledButtonCep = styled(Button, {
    name: "StyledButtonCart",
  })({
    height: "50px",
    width: "100px",
    backgroundColor: "#273682",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1.1em",
    border: "1px solid #273682",
    borderRadius: "0 10px 10px 0",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "#273682",
      filter: "brightness(120%)",
    },
  });
  
  export const StyledButtonCepDark = styled(Button, {
    name: "StyledButtonCart",
  })({
    height: "50px",
    width: "100px",
    backgroundColor: "#0B0B0D",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1.1em",
    border: "1px solid",
    borderRadius: "0 10px 10px 0",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(120%)",
      backgroundColor: "#2B2B30",
    },
  });
  
  export const StyledTextField = styled(TextField, {
    name: "StyledTextField",
  })({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderStyle: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#273682",
    },
    width: "120px",
    backgroundColor: "white",
    textTransform: "unset",
    fontWeight: "bold",
    borderRadius: "10px 0 0 10px",
  });
  
  export const StyledTextFieldDark = styled(TextField, {
    name: "StyledTextField",
  })({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderStyle: "none",
      },
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    width: "120px",
    backgroundColor: "white",
    textTransform: "unset",
    fontWeight: "bold",
    borderRadius: "10px 0 0 10px",
  });
  
  export const StyledTextFieldDarkSomethingTyped = styled(TextField, {
    name: "StyledTextField",
  })({
    "& label.MuiInputLabel-root": {
      color: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderStyle: "none",
      },
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    width: "120px",
    backgroundColor: "white",
    textTransform: "unset",
    fontWeight: "bold",
    borderRadius: "10px 0 0 10px",
  });
  
  export const StyledButtonClose = styled(Button, {
    name: "StyledButtonClose",
  })({
    height: "45px",
    width: "100%",
    backgroundColor: "#273682",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1em",
    border: "1px solid #273682",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "#273682",
      filter: "brightness(120%)",
    },
  });
  
  export const StyledButtonCloseDark = styled(Button, {
    name: "StyledButtonClose",
  })({
    height: "45px",
    width: "100%",
    backgroundColor: "#0B0B0D",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.1em",
    lineHeight: "1em",
    border: "1px solid",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2B2B30",
      filter: "brightness(120%)",
    },
  });
  
  export const StyledSentimentVeryDissatisfiedIcon = styled(
    SentimentVeryDissatisfiedIcon,
    {
      name: "StyledSentimentVeryDissatisfiedIcon",
    }
  )({
    height: "65px",
    width: "65px",
    margin: "0 0 60px 0",
    color: "#666666",
    "&:hover": {
      cursor: "pointer",
    },
  });
  
  export const StyledSentimentVeryDissatisfiedIconDark = styled(
    SentimentVeryDissatisfiedIcon,
    {
      name: "StyledSentimentVeryDissatisfiedIcon",
    }
  )({
    height: "65px",
    width: "65px",
    margin: "0 0 60px 0",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
  });
  
  export const StyledLocalShippingIcon = styled(LocalShippingIcon, {
    name: "StyledLocalShippingIcon",
  })({
    height: "35px",
    width: "35px",
    marginRight: "10px",
    color: "#273682",
    "&:hover": {
      cursor: "pointer",
    },
  });
  
  export const StyledLocalShippingIconDark = styled(LocalShippingIcon, {
    name: "StyledLocalShippingIcon",
  })({
    height: "35px",
    width: "35px",
    marginRight: "10px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
  });
  
  export const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: "2px",
    height: "2px",
  }));
  
  export const StyledFormControl = styled(FormControl, {
    name: "StyledLocalShippingIcon",
  })({
    width: "100%",
    "& .MuiFormControlLabel-root": {
      width: "100%",
    },
  });
  
  export const BpCheckedIcon = styled(BpIcon)({
    border: "6px solid #273682",
  });
  
  export const BpCheckedIconDark = styled(BpIcon)({
    border: "6px solid #474A56",
  });

  export function BpRadio(props) {
    return (
      <Radio
        sx={{
          border: "3px solid #273682",
          marginRight: "5px",
          width: "0px",
          height: "0px",
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  
  export function BpRadioDark(props) {
    return (
      <Radio
        sx={{
          border: "3px solid #474A56",
          marginRight: "5px",
          width: "0px",
          height: "0px",
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIconDark />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  
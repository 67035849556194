import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCart":
      return {
        id: action.id,
        product_id: action.product_id,
        company_id: action.company_id,
        quantity: action.quantity,
        product: action.product,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const CartContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <CartContext.Provider value={context}>{children}</CartContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

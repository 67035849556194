import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {
  canShow: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setLoginMessage":
      return {
        canShow: action.canShow,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const LoginMessageContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <LoginMessageContext.Provider value={context}>
      {children}
    </LoginMessageContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import moment from "moment";

const formatMomentDateTime = (value) => {
  if (!value) return "";
  return moment(value).format("DD/MM/YYYY HH:mm:ss");
};

export const normalizeAuthData = (data) => ({
  token: data?.token,
});

export const normalizeUserData = ({ data }) => ({
  accessRoles: data?.accessRoles || {},
  defaultScreen: data?.defaultScreen || "",
  userEmail: data?.login || "",
  userName: data?.name || "",
  userPhone: data?.phone || "",
  userLanguage: data?.language || "pt-BR",
  userId: data?.id || 0,
  userGroup: data?.group || 0,
  companyId: data?.companyId || 0,
  userTheme: data?.theme?.type || "light",
  companies: data?.companies || [],
});

export const normalizeAuthAndUserData = (data) => ({
  token: data?.token,
  accessRoles: data?.accessRoles || {},
  defaultScreen: data?.defaultScreen || "",
  userEmail: data?.user?.email || "",
  userPhone: data?.user?.phone || "",
  userName: `${data?.user?.first_name}` || "",
  userLastName: `${data?.user?.last_name}` || "",
  userLanguage: data?.user?.language || "pt-BR",
  userId: data?.id || 0,
  userGroup: data?.group || 0,
  companyId: data?.company?.id || 0,
  companyName: data?.company?.name || "",
  userTheme: data?.themeType || "light",
  systemRole: data?.systemRole?.id || null,
  companies: data?.companies || [],
  term: data?.term || {},
});

export const normalizeInventoryData = ({ data }) => {
  return data || [];
};

export const normalizeExportsData = ({ data }) => {
  return data || [];
};

export const normalizeImportsData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      dateOrder: formatMomentDateTime(row.dateOrder),
      dateReceptionOperator: formatMomentDateTime(row.dateReceptionOperator),
      siDateDebutFacturationOperateur: formatMomentDateTime(
        row.siDateDebutFacturationOperateur
      ),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeBatchesData = ({ data }) => {
  return data || [];
};

export const normalizeStockData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
      siDateCreation: formatMomentDateTime(row.siDateCreation),
      siDateDebutFacturation: formatMomentDateTime(row.siDateDebutFacturation),
      siDateDebutFacturationOperateur: formatMomentDateTime(
        row.siDateDebutFacturationOperateur
      ),
      siDateDemandeActivation: formatMomentDateTime(
        row.siDateDemandeActivation
      ),
    })) || []
  );
};

export const normalizePlanData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeAPNsData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeNetworksData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeGGSNsData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeOperatorsData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeCompaniesData = ({ data }) => {
  return (
    data?.map((row) => ({
      ...row,
      createdAt: formatMomentDateTime(row.createdAt),
      updatedAt: formatMomentDateTime(row.updatedAt),
    })) || []
  );
};

export const normalizeFilterSettingsData = ({ data }) => ({
  filterPossibilities: data?.filterPossibilities || [],
  groupingPossibilities: data?.groupingPossibilities || [],
  groupsPossibilities: data?.groupsPossibilities || [],
});

export const normalizeSizeData = ({ data }) => {
  return data || 0;
};

export const normalizeTableSettingsData = (values) => {
  return {
    columns: values?.data?.columns || [],
    fixedLeftColumns: values?.data?.fixedLeftColumns || [],
    fixedRightColumns: values?.data?.fixedRightColumns || [],
    hiddenColumns: values?.data?.hiddenColumns || [],
    order: values?.data?.order || [],
    pageSizes: values?.data?.pageSizes || [],
    providers: values?.data?.providers || {},
    colouredCurrencyGroupColumns:
      values?.data?.colouredCurrencyGroupColumns || [],
    totalRowHasTitle: values?.data?.totalRowHasTitle || false,
  };
};

export const normalizecompaniesList = (data) => {
  let newListData = []
  for(let i = 0; i < data.length; i++){
    let companyFormated = {
      label: data[i].companyName,
      id: data[i].company_id
    }
    newListData.push(companyFormated)
  }
  return newListData
};

export const normalizeImportDetailsData = (data) => {
  return {
    ...data,
    createdAt: formatMomentDateTime(data.createdAt),
    dateOrder: formatMomentDateTime(data.dateOrder),
    dateReceptionOperator: formatMomentDateTime(data.dateReceptionOperator),
    siDateDebutFacturationOperateur: formatMomentDateTime(
      data.siDateDebutFacturationOperateur
    ),
    updatedAt: formatMomentDateTime(data.updatedAt),
  };
};

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useCart } from "../../API/Hooks/Cart";
import { CartLengthContext } from "../../contexts/CartLength";
import { useSnackbar } from "notistack";
import { useProductsQuantity } from "../../API/Hooks/Products";
import { AuthContext } from "../../contexts/Auth";
import { modeContext } from "../../App";
import {
  StyledButtonCep,
  StyledLocalShippingIcon,
} from "../../screens/private/Cart/components";
import { useGetFreightCalculate, useGetFreight } from "../../API/Hooks/Orders";
import { UserContext } from "../../contexts/User";
import { CompanyContext } from "../../contexts/Company";

const useStyles = makeStyles({
  productDetailsContainer: {
    height: "650px",
    width: "80%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "3px solid #273682",
    borderRadius: "15px",
    justifyContent: "space-around",
    padding: "15px 0 15px 0",
  },
  productDetailsContainerDesktop: {
    height: "450px",
    width: "65%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    border: "3px solid #273682",
    borderRadius: "15px",
    justifyContent: "space-around",
    padding: "15px 0 15px 0",
  },
  orderDetailsContainer: {
    height: "300px",
    width: "80%",
    margin: "20px 0 20px 0",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktop: {
    height: "450px",
    width: "30%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopBigger: {
    height: "450px",
    width: "20%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  sessionName: {
    alignItems: "center",
    fontWeight: "bold",
    marginLeft: "10px",
    fontSize: "1.3em",
  },
  orderDetailsTitle: {
    alignItems: "center",
    fontWeight: "bold",
    color: "#273682",
    fontSize: "1.2em",
  },
  calculateTitle: {
    fontWeight: "700",
    marginRight: "10px",
    fontSize: "1.2em",
    lineHeight: "1em",
    minWidth: "131px",
  },
  calculateTitleDark: {
    fontWeight: "700",
    marginRight: "10px",
    fontSize: "1.2em",
    lineHeight: "1em",
    color: "white",
    textShadow: "3px 3px 10px #0B0B0D",
  },
  freeShippingParagraph: {
    textAlign: "left",
    fontWeight: "600",
    margin: "20px 0 10px 0",
    color: "#0FA958",
  },
  calculateTitleMobile: {
    fontWeight: "700",
    marginRight: "5px",
    fontSize: "1.2em",
    lineHeight: "1.7em",
  },
  inputFreightContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 10px 0",
  },
  inputFreightContainerMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "10px 0 10px 0",
  },
  orderDetailsTitleDark: {
    alignItems: "center",
    fontWeight: "bold",
    color: "#474A56",
    fontSize: "1.2em",
  },
  images: {
    width: "160px",
    height: "130px",
    objectFit: "contain",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionTitleDark: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "white",
  },
  resultContainer: {
    display: "flex",
    justifyContent: "center",
    width: "500px",
  },
  resultContainerMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
    height: "100px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  sessionTitleContainerDark: {
    backgroundColor: "#474A56",
    height: "100px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionProduct: {
    fontSize: "1.1em",
    textAlign: "justify",
    margin: "5px 15px 5px 15px",
    color: "black",
    maxHeight: "265px",
    overflow: "hidden",
  },
  descriptionProductMobile: {
    fontSize: "1.1em",
    textAlign: "justify",
    margin: "5px 15px 5px 15px",
    color: "black",
    maxHeight: "237px",
    overflow: "hidden",
  },
  descriptionTitle: {
    fontSize: "1.2em",
    textAlign: "left",
    marginLeft: "15px",
    fontWeight: "bold",
    color: "#273682",
  },
  freightCalculatedParagraphMobile: {
    fontWeight: "600",
    color: "#273682",
    textAlign: "center",
  },
  freightCalculatedParagraph: {
    fontWeight: "600",
    color: "#273682",
    marginRight: "35px",
  },
  freightCalculatedParagraphNoMargin: {
    fontWeight: "600",
    color: "#273682",
  },
  freightCalculatedParagraphDark: {
    fontWeight: "600",
    color: "white",
    textShadow: "3px 3px 5px #0B0B0D",
  },
  descriptionTitleDark: {
    fontSize: "1.2em",
    textAlign: "left",
    marginLeft: "15px",
    fontWeight: "bold",
    color: "#474A56",
  },
  priceProduct: {
    color: "#001064",
    fontWeight: "600",
    fontSize: "1.2em",
    marginLeft: "10px",
  },
  priceProductDark: {
    color: "#474A56",
    fontWeight: "600",
    fontSize: "1.2em",
    marginLeft: "10px",
  },
  priceProductMobile: {
    color: "#001064",
    fontWeight: "600",
    fontSize: "1.2em",
  },
  stock: {
    textAlign: "center",
    color: "#0FA958",
    fontWeight: "bold",
    fontSize: "1em",
  },
  soldOff: {
    textAlign: "center",
    color: "#E11521",
    fontWeight: "bold",
    fontSize: "1em",
  },
  select: {
    width: "200px",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "7px",
    },
  },
  selectAndTextFieldContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "120px",
  },
  selectAndTextFieldContainerDesktop: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "80px",
  },
  detailsParagraphLeft: {
    width: "50%",
    fontSize: "1.1em",
    textAlign: "left",
  },
  detailsParagraphLeftTotal: {
    width: "40%",
    fontSize: "1.em",
    textAlign: "left",
  },
  detailsParagraphRight: {
    width: "50%",
    fontSize: "1.1em",
    textAlign: "right",
  },
  detailsParagraphRightBold: {
    width: "60%",
    fontSize: "1.1em",
    textAlign: "right",
    fontWeight: "700",
    color: "#273682",
  },
  detailsParagraphRightBoldDark: {
    width: "60%",
    fontSize: "1.1em",
    textAlign: "right",
    fontWeight: "700",
    color: "#474A56",
  },
  paragraphsContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "100px",
  },
  valueParagraphContainer: {
    display: "flex",
    flexWrap: "wrap",
    height: "50px",
    fontWeight: "600",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  productDetailsAndOrderDetailsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
  },
  productDetailsAndOrderDetailsContainerDark: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
    backgroundColor: "#474A56",
  },
  imageNameAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "300px",
    marginBottom: "20px",
  },
  descriptionContainer: {
    height: "400px",
    display: "flex",
    width: "70%",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  availableStockParagraph: {
    width: "80%",
    marginLeft: "10px",
  },
  availableStockParagraphMobile: {
    width: "100%",
    textAlign: "center",
  },
  mobileCalc: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
});

const StyledInputBase = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "45%",
  "& .MuiInputBase-input": {
    border: "1px solid #273682",
    height: "40px",
    fontSize: "1.1em",
    lineHeight: "2.2em",
    padding: "5px 5px 5px 10px",
    "&:focus": {
      border: "2px solid #273682",
    },
  },
});

const StyledInputBaseDark = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "45%",
  "& .MuiInputBase-input": {
    border: "1px solid #474A56",
    height: "40px",
    fontSize: "1.1em",
    lineHeight: "2.2em",
    padding: "5px 5px 5px 10px",
    "&:focus": {
      border: "2px solid #474A56",
    },
  },
});

const StyledInputBaseMobile = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "250px",
  "& .MuiInputBase-input": {
    border: "1px solid #273682",
    height: "40px",
    fontSize: "1.1em",
    lineHeight: "2.2em",
    padding: "5px 5px 5px 10px",
    "&:focus": {
      border: "2px solid #273682",
    },
  },
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "45%",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#273682",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#273682",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
    fontWeight: "600",
  },
  "& .MuiInputLabel-root": {
    color: "#273682",
    fontWeight: "600",
  },
});

const StyledTextFieldDark = styled(TextField, {
  name: "StyledTextField",
})({
  width: "45%",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#474A56",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#474A56",
    },
  },
  "& label.Mui-focused": {
    color: "#474A56",
    fontWeight: "600",
  },
  "& .MuiInputLabel-root": {
    color: "#474A56",
    fontWeight: "600",
  },
});

const StyledTextFieldMobile = styled(TextField, {
  name: "StyledTextField",
})({
  width: "250px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#273682",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#273682",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
    fontWeight: "600",
  },
  "& .MuiInputLabel-root": {
    color: "#273682",
    fontWeight: "600",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "50px",
  width: "250px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.2em",
  lineHeight: "1em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#273682",
    filter: "brightness(120%)",
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "50px",
  width: "250px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.2em",
  lineHeight: "1em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
  },
});

export const ProductDetails = ({
  carrier,
  id,
  name,
  type,
  description,
  price,
  available,
}) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const colorMode = useContext(modeContext);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const [chipType, setChipType] = useState(type[0]);

  const [cart, dispatchCartLengthContext] = useContext(CartLengthContext);

  const [quantity, setQuantity] = useState(1);

  const isMobile = useMediaQuery("(max-width:900px)");

  const [token] = useContext(AuthContext);

  const [zipcode, setZipCode] = useState("");

  const [oldZipCode, setOldZipCode] = useState("");

  const [cleared, setCleared] = useState(false);

  const [calculatingFreight, setCalculatingFreight] = useState(true);

  const [calculatedFreight, setCalculatedFreight] = useState({});

  const {
    fetchGetFreight,
    requestDataFreight,
    requestLoadingFreight,
    requestErrorFreight,
  } = useGetFreight();

  const {
    fetchGetFreightCalculate,
    requestDataFreightCalculate,
    requestLoadingFreightCalculate,
    requestErrorFreightCalculate,
  } = useGetFreightCalculate();

  const handleZipCode = (event) => {
    event.target.maxLength = 9;
    event.target.value = event.target.value.replace(/\D/g, "");
    setZipCode(event.target.value.replace(/^(\d{5})(\d)/, "$1-$2"));
  };

  const handleCalculate = () => {
    setZipCode("");
    setOldZipCode("");
    setCleared(true);
    setCalculatedFreight({});
  };

  const handleCalculateFreight = () => {
    return new Promise((resolve, reject) => {
      fetchGetFreightCalculate(zipcode, quantity);
      resolve();
    });
  };

  const clearZipCode = () => {
    setZipCode("");
    setOldZipCode("");
    setCleared(true);
    setCalculatedFreight({});
  };

  useEffect(() => {
    const calculatedFreightHandler = async () => {
      if (zipcode.length > 8 && calculatingFreight) {
        setCleared(false);
        await handleCalculateFreight();
        setCalculatingFreight(false);
        setOldZipCode(zipcode);
      }
      if (zipcode.length < 9 || zipcode !== oldZipCode) {
        setCalculatingFreight(true);
      }
      if (!cleared) {
        if (
          Object.keys(requestDataFreightCalculate).length !== 0 &&
          Object.keys(requestDataFreightCalculate.data).length !== 0 &&
          !requestLoadingFreightCalculate &&
          !requestErrorFreightCalculate
        ) {
          setCalculatedFreight(requestDataFreightCalculate);
        }
      }
      if (
        requestErrorFreightCalculate &&
        zipcode.length !== 0 &&
        Object.keys(calculatedFreight).length !== 0
      ) {
        clearZipCode();
      }
    };
    calculatedFreightHandler();
  }, [
    token,
    cart,
    requestDataFreight,
    calculatedFreight,
    zipcode,
    requestLoadingFreight,
    requestErrorFreight,
    oldZipCode,
    calculatingFreight,
    requestDataFreightCalculate,
    requestLoadingFreightCalculate,
    requestErrorFreightCalculate,
    cleared,
  ]);

  const operators = ["claro", "vivo", "tim", "oi", "arqia", "nlt", "algar"];

  const productName = operators.find((item) =>
    name.toLowerCase().includes(item)
  );

  const { fetchProductsQuantity, requestData } = useProductsQuantity();

  const [stockQuantity, setStockQuantity] = useState({});

  const handleStockQuantity = () => {
    return new Promise((resolve, reject) => {
      fetchProductsQuantity(productName);
      setStockQuantity(requestData);
      resolve();
    });
  };

  const isBig = useMediaQuery("(min-width:1116px)");

  const classes = useStyles();

  const priceFormatted = `${price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(2)
    .split("")
    .reverse()
    .join("")},${price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(0, 2)
    .split("")
    .reverse()
    .join("")}`;

  const total = () => {
    let result = parseFloat(priceFormatted.replace(",", ".")) * quantity;
    result = result.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
    return `R$ ${result}`;
  };

  const handleQuantity = (event) => {
    if (event.target.value.length < 7 && event.target.value >= 0) {
      setQuantity(parseInt(event.target.value));
      clearZipCode();
    }
  };

  const handleComma = (event) => {
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
      return false;
    }
  };

  const { fetchCart } = useCart();

  const handleAddToCart = () => {
    if (token.token) {
      let body = {
        productId: id,
        productType: chipType,
        quantity: quantity,
      };
      if (user.systemRole === 4 && company.company_id !== undefined) {
        body.companyId = company.company_id;
      }
      fetchCart(body).then(() => {
        dispatchCartLengthContext({
          type: "setCartLength",
          length: cart.length + 1,
        });
        navigate(`/cart`);
        enqueueSnackbar(`Adicionado ao carrinho!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      });
    } else {
      return navigate("/login");
    }
  };

  useEffect(() => {
    const loadStockQuantity = async () => {
      if (Object.keys(stockQuantity).length === 0) {
        await handleStockQuantity();
      }
    };
    loadStockQuantity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, stockQuantity, requestData, token]);

  return (
    <>
      {isMobile ? (
        <>
          <div className={classes.sessionTitleContainer}>
            <h1 className={classes.sessionTitle}>Detalhes do Produto</h1>
          </div>
          <div className={classes.productDetailsContainer}>
            <h2 className={classes.sessionName}>{name}</h2>
            <img
              className={classes.images}
              src={`${
                process.env.REACT_APP_API_URL
              }/public/images/product/${carrier?.toLowerCase()}`}
              alt={name}
            />
            <div>
              <h3 className={classes.descriptionTitle}>Descrição:</h3>
              <p className={classes.descriptionProductMobile}>{description}</p>
            </div>
            <p className={classes.priceProductMobile}>R$ {priceFormatted}</p>
            <p className={classes.availableStockParagraphMobile}>
              {Object.keys(stockQuantity).length !== 0 &&
                (stockQuantity.data >= 1 ? (
                  <span className={classes.stock}>Em estoque</span>
                ) : (
                  <span className={classes.soldOff}>Esgotado</span>
                ))}
            </p>
            <div className={classes.selectAndTextFieldContainer}>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chipType}
                label="Tipo"
                input={<StyledInputBaseMobile />}
                onChange={(e) => setChipType(e.target.value)}
                className={classes.select}
              >
                {type.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
              <StyledTextFieldMobile
                id="outlined-number"
                label="Quantidade"
                type="number"
                value={quantity}
                onChange={handleQuantity}
                onKeyDown={handleComma}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
          <div className={classes.orderDetailsContainer}>
            <h2 className={classes.orderDetailsTitle}>Detalhes do Pedido:</h2>
            <div className={classes.paragraphsContainer}>
              <p className={classes.detailsParagraphLeft}>Modelo:</p>
              <p className={classes.detailsParagraphRight}>{chipType}</p>
              <p className={classes.detailsParagraphLeft}>Preço unitário:</p>
              <p className={classes.detailsParagraphRight}>
                R$ {priceFormatted}
              </p>
              <p className={classes.detailsParagraphLeft}>Quantidade:</p>
              <p className={classes.detailsParagraphRight}>
                {!isNaN(quantity) ? quantity : 0}
              </p>
            </div>
            <div className={classes.valueParagraphContainer}>
              <p className={classes.detailsParagraphLeftTotal}>Valor Total:</p>
              <p className={classes.detailsParagraphRightBold}>
                {!isNaN(quantity) ? total() : "R$ 0,00"}
              </p>
            </div>
            <div className={classes.buttonContainer}>
              {quantity ? (
                <StyledButton
                  onClick={() => handleAddToCart()}
                  variant="contained"
                >
                  Adicionar ao Carrinho
                </StyledButton>
              ) : (
                <StyledButton
                  disabled
                  onClick={() => navigate(`/cart`)}
                  variant="contained"
                >
                  Adicionar ao Carrinho
                </StyledButton>
              )}
            </div>
          </div>{" "}
          <p
            className={
              colorMode !== "dark" || isMobile
                ? classes.freeShippingParagraph
                : classes.freeShippingParagraphDark
            }
          >
            * Frete grátis a partir de 50 unidades
          </p>
          <div className={classes.inputFreightContainerMobile}>
            <div className={classes.mobileCalc}>
              <StyledLocalShippingIcon />
              <h2
                className={
                  isMobile
                    ? classes.calculateTitleMobile
                    : colorMode !== "dark"
                    ? classes.calculateTitle
                    : classes.calculateTitleDark
                }
              >
                Calcule o frete:
              </h2>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledTextField
                id="street-input"
                label="Digite o CEP"
                type="text"
                autoComplete="off"
                value={zipcode}
                onChange={(e) => handleZipCode(e)}
              />
              <StyledButtonCep onClick={() => handleCalculate()}>
                Limpar
              </StyledButtonCep>
            </div>
          </div>
          <div
            className={
              isMobile ? classes.resultContainerMobile : classes.resultContainer
            }
          >
            {Object.keys(calculatedFreight).length !== 0 &&
              Object.keys(calculatedFreight.data).length !== 0 &&
              !requestLoadingFreight &&
              calculatedFreight.data.length === 2 && (
                <>
                  {" "}
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraph
                        : classes.freightCalculatedParagraphDark
                    }
                  >{`${calculatedFreight.data[0].Type} - R$ ${
                    calculatedFreight.data[0].Valor
                  } - ${calculatedFreight.data[0].PrazoEntrega} ${
                    calculatedFreight.data[0].PrazoEntrega === "1"
                      ? "dia útil"
                      : "dias úteis"
                  }`}</p>
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraph
                        : classes.freightCalculatedParagraphDark
                    }
                  >{`${calculatedFreight.data[1].Type} - R$ ${
                    calculatedFreight.data[1].Valor
                  } - ${calculatedFreight.data[1].PrazoEntrega} ${
                    calculatedFreight.data[1].PrazoEntrega === "1"
                      ? "dia útil"
                      : "dias úteis"
                  }`}</p>{" "}
                </>
              )}
            {Object.keys(calculatedFreight).length !== 0 &&
              Object.keys(calculatedFreight.data).length !== 0 &&
              !requestLoadingFreight &&
              calculatedFreight.data.length === 1 && (
                <>
                  {" "}
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraph
                        : classes.freightCalculatedParagraphDark
                    }
                  >
                    Grátis
                  </p>
                </>
              )}
          </div>
        </>
      ) : (
        //DESKTOP ABAIXO --------------------- DESKTOP ABAIXO -------------------------------

        <>
          <div
            className={
              colorMode !== "dark"
                ? classes.sessionTitleContainer
                : classes.sessionTitleContainerDark
            }
          >
            <h1
              className={
                colorMode !== "dark"
                  ? classes.sessionTitle
                  : classes.sessionTitleDark
              }
            >
              Detalhes do Produto
            </h1>
          </div>
          <div
            className={
              colorMode !== "dark"
                ? classes.productDetailsAndOrderDetailsContainer
                : classes.productDetailsAndOrderDetailsContainerDark
            }
          >
            <div className={classes.productDetailsContainerDesktop}>
              <div className={classes.imageNameAndPriceContainer}>
                <img
                  className={classes.images}
                  src={`${
                    process.env.REACT_APP_API_URL
                  }/public/images/product/${carrier?.toLowerCase()}`}
                  alt={name}
                />
                <h2 className={classes.sessionName}>{name}</h2>
                <p
                  className={
                    colorMode !== "dark"
                      ? classes.priceProduct
                      : classes.priceProductDark
                  }
                >
                  R$ {priceFormatted}
                </p>
                <p className={classes.availableStockParagraph}>
                  {Object.keys(stockQuantity).length !== 0 &&
                    (stockQuantity.data >= 1 ? (
                      <span className={classes.stock}>Em estoque</span>
                    ) : (
                      <span className={classes.soldOff}>Esgotado</span>
                    ))}
                </p>
              </div>

              <div className={classes.descriptionContainer}>
                <h3
                  className={
                    colorMode !== "dark"
                      ? classes.descriptionTitle
                      : classes.descriptionTitleDark
                  }
                >
                  Descrição:
                </h3>
                <p className={classes.descriptionProduct}>{description}</p>
                <div className={classes.selectAndTextFieldContainerDesktop}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={chipType}
                    label="Tipo"
                    input={
                      colorMode !== "dark" ? (
                        <StyledInputBase />
                      ) : (
                        <StyledInputBaseDark />
                      )
                    }
                    onChange={(e) => setChipType(e.target.value)}
                    className={classes.select}
                  >
                    {type.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  {colorMode !== "dark" ? (
                    <StyledTextField
                      id="outlined-number"
                      label="Quantidade"
                      max={7}
                      type="number"
                      value={quantity}
                      onChange={handleQuantity}
                      onKeyDown={handleComma}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (
                    <StyledTextFieldDark
                      id="outlined-number"
                      label="Quantidade"
                      max={7}
                      type="number"
                      value={quantity}
                      onChange={handleQuantity}
                      onKeyDown={handleComma}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                isBig
                  ? classes.orderDetailsContainerDesktopBigger
                  : classes.orderDetailsContainerDesktop
              }
            >
              <h2
                className={
                  colorMode !== "dark"
                    ? classes.orderDetailsTitle
                    : classes.orderDetailsTitleDark
                }
              >
                Detalhes do Pedido:
              </h2>
              <div className={classes.paragraphsContainer}>
                <p className={classes.detailsParagraphLeft}>Modelo:</p>
                <p className={classes.detailsParagraphRight}>{chipType}</p>
                <p className={classes.detailsParagraphLeft}>Preço unitário:</p>
                <p className={classes.detailsParagraphRight}>
                  R$ {priceFormatted}
                </p>
                <p className={classes.detailsParagraphLeft}>Quantidade:</p>
                <p className={classes.detailsParagraphRight}>
                  {!isNaN(quantity) ? quantity : 0}
                </p>
              </div>
              <div className={classes.valueParagraphContainer}>
                <p className={classes.detailsParagraphLeftTotal}>
                  Valor Total:
                </p>
                <p
                  className={
                    colorMode !== "dark"
                      ? classes.detailsParagraphRightBold
                      : classes.detailsParagraphRightBoldDark
                  }
                >
                  {!isNaN(quantity) ? total() : "R$ 0,00"}
                </p>
              </div>
              <div className={classes.buttonContainer}>
                {quantity && colorMode !== "dark" ? (
                  <StyledButton
                    onClick={() => handleAddToCart()}
                    variant="contained"
                  >
                    Adicionar ao Carrinho
                  </StyledButton>
                ) : colorMode === "dark" && quantity ? (
                  <StyledButtonDark
                    onClick={() => handleAddToCart()}
                    variant="contained"
                  >
                    Adicionar ao Carrinho
                  </StyledButtonDark>
                ) : (
                  <StyledButton disabled variant="contained">
                    Adicionar ao Carrinho
                  </StyledButton>
                )}
              </div>
            </div>
          </div>
          <p
            className={
              colorMode !== "dark" || isMobile
                ? classes.freeShippingParagraph
                : classes.freeShippingParagraphDark
            }
          >
            * Frete grátis a partir de 50 unidades
          </p>
          <div className={classes.inputFreightContainer}>
            <StyledLocalShippingIcon />
            <h2
              className={
                isMobile
                  ? classes.calculateTitleMobile
                  : colorMode !== "dark"
                  ? classes.calculateTitle
                  : classes.calculateTitleDark
              }
            >
              Calcule o frete:
            </h2>
            <StyledTextField
              id="street-input"
              label="Digite o CEP"
              type="text"
              autoComplete="off"
              value={zipcode}
              onChange={(e) => handleZipCode(e)}
            />
            <StyledButtonCep onClick={() => handleCalculate()}>
              Limpar
            </StyledButtonCep>
          </div>
          <div
            className={
              isMobile ? classes.resultContainerMobile : classes.resultContainer
            }
          >
            {Object.keys(calculatedFreight).length !== 0 &&
              Object.keys(calculatedFreight.data).length !== 0 &&
              !requestLoadingFreight &&
              calculatedFreight.data.length === 2 && (
                <>
                  {" "}
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraph
                        : classes.freightCalculatedParagraphDark
                    }
                  >{`${calculatedFreight.data[0].Type} - R$ ${
                    calculatedFreight.data[0].Valor
                  } - ${calculatedFreight.data[0].PrazoEntrega} ${
                    calculatedFreight.data[0].PrazoEntrega === "1"
                      ? "dia útil"
                      : "dias úteis"
                  }`}</p>
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraphNoMargin
                        : classes.freightCalculatedParagraphDark
                    }
                  >{`${calculatedFreight.data[1].Type} - R$ ${
                    calculatedFreight.data[1].Valor
                  } - ${calculatedFreight.data[1].PrazoEntrega} ${
                    calculatedFreight.data[1].PrazoEntrega === "1"
                      ? "dia útil"
                      : "dias úteis"
                  }`}</p>{" "}
                </>
              )}
            {Object.keys(calculatedFreight).length !== 0 &&
              Object.keys(calculatedFreight.data).length !== 0 &&
              !requestLoadingFreight &&
              calculatedFreight.data.length === 1 && (
                <>
                  {" "}
                  <p
                    className={
                      isMobile
                        ? classes.freightCalculatedParagraphMobile
                        : colorMode !== "dark"
                        ? classes.freightCalculatedParagraphNoMargin
                        : classes.freightCalculatedParagraphDark
                    }
                  >
                    Grátis
                  </p>
                </>
              )}
          </div>
        </>
      )}
    </>
  );
};

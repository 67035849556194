import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setOrders":
      return {
        company: action.company,
        shipping_address: action.shipping_address,
        user_order: action.user_order,
        status: action.status,
        products: action.products,
        date_created_at: action.date_created_at,
        date_awaiting_payment: action.date_awaiting_payment,
        date_awaiting_payment_for_chip_order:
          action.date_awaiting_payment_for_chip_order,
        date_chips_order: action.date_chips_order,
        date_packing: action.date_packing,
        date_ready_to_post: action.date_ready_to_post,
        date_posted: action.date_posted,
        date_in_transit: action.date_in_transit,
        date_delivered: action.date_delivered,
        message: action.message,
        _id: action._id,
        company_id: action.company_id,
        freight: action.freight,
        value_freight: action.value_freight,
        price: action.price,
        __v: action.__v,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const OrdersContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <OrdersContext.Provider value={context}>{children}</OrdersContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { loadItem, removeItem, saveItem } from "../utils/localStorage";

const userName = loadItem("userName");
const userEmail = loadItem("userEmail");
const userLanguage = loadItem("userLanguage");
const userId = loadItem("userId");
const companyId = loadItem("companyId");
const companyName = loadItem("companyName");
const userGroup = loadItem("userGroup");
const accessRoles = loadItem("accessRoles");
const defaultScreen = loadItem("defaultScreen");
const systemRole = loadItem("systemRole");
const userPhone = loadItem("userPhone");
const userLastName = loadItem("userLastName");
const companies = loadItem("companies");

const defaultState = {
  userEmail: "",
  userName: "",
  userPhone: "",
  userLastName: "",
  userLanguage: "",
  userId: null,
  userGroup: 0,
  companyId: null,
  companyName: "",
  defaultScreen: "",
  accessRoles: {},
  systemRole: null,
  companies: []
};

const initialState = {
  userEmail: userEmail || "",
  userName: userName || "",
  userPhone: userPhone || "",
  userLastName: userLastName || "",
  userLanguage: userLanguage || "",
  userId: userId || null,
  userGroup: userGroup || 0,
  companyId: companyId || null,
  companyName: companyName || "",
  accessRoles: accessRoles || {},
  defaultScreen: defaultScreen || "",
  systemRole: systemRole || null,
  companies: companies || [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setSettingsUser":
      saveItem(action.userEmail, "userEmail");
      saveItem(action.userName, "userName");
      saveItem(action.userPhone, "userPhone");
      saveItem(action.userLanguage, "userLanguage");
      saveItem(action.userId, "userId");
      saveItem(action.userGroup, "userGroup");
      saveItem(action.userLastName, "userLastName");
      saveItem(action.companyId, "companyId");
      saveItem(action.companyName, "companyName");
      saveItem(action.accessRoles, "accessRoles");
      saveItem(action.defaultScreen, "defaultScreen");
      saveItem(action.systemRole, "systemRole");
      saveItem(action.companies, "companies");

      return {
        userEmail: action.userEmail,
        userName: action.userName,
        userPhone: action.userPhone,
        userLastName: action.userLastName,
        userLanguage: action.userLanguage,
        userId: action.userId,
        userGroup: action.userGroup,
        companyId: action.companyId,
        companyName: action.companyName,
        accessRoles: action.accessRoles,
        defaultScreen: action.defaultScreen,
        systemRole: action.systemRole,
        companies: action.companies
      };
    case "clear":
      removeItem("userEmail");
      removeItem("userName");
      removeItem("userLanguage");
      removeItem("userId");
      removeItem("userGroup");
      removeItem("userPhone");
      removeItem("companyId");
      removeItem("companyName");
      removeItem("userLastName");
      removeItem("accessRoles");
      removeItem("defaultScreen");
      removeItem("systemRole");
      removeItem("companies");

      return defaultState;
    case "updateSettingsUser":
      saveItem(action.name, "userName");
      saveItem(action.lastName, "userLastName");
      saveItem(action.phone, "userPhone");
      return {
        userEmail: userEmail,
        userName: action.name,
        userPhone: action.phone,
        userLastName: action.lastName,
        userLanguage: userLanguage,
        userId: userId,
        userGroup: userGroup,
        companyId: companyId,
        companyName: companyName,
        accessRoles: accessRoles,
        defaultScreen: defaultScreen,
        systemRole: systemRole,
        companies: companies,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const UserContext = React.createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import {
  useCreateAddresses,
  useDeleteAddresses,
  useGetAddresses,
  useGetCep,
  useUpdateAddresses,
} from "../../API/Hooks/Addresses";
import ContactMailSharpIcon from "@mui/icons-material/ContactMailSharp";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BusinessIcon from "@mui/icons-material/Business";
import FormatListNumberedSharpIcon from "@mui/icons-material/FormatListNumberedSharp";
import FlagSharpIcon from "@mui/icons-material/FlagSharp";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HolidayVillageSharpIcon from "@mui/icons-material/HolidayVillageSharp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import FaceIcon from "@mui/icons-material/Face";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { modeContext } from "../../App";
import { UserContext } from "../../contexts/User";
import { CompanyContext } from "../../contexts/Company";

const useStyles = makeStyles({
  root: {
    minHeight: "440px",
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "50px 0 20px 0",
    backgroundColor: "white",
    padding: "0 30px 15px 30px",
    borderRadius: "15px",
  },
  rootLoading: {
    opacity: "0",
  },
  rootEdit: {
    minHeight: "600px",
    width: "85%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0 20px 0",
    border: "2px solid #273682",
    padding: "0 30px 0 30px",
    borderRadius: "15px",
  },
  rootDesktop: {
    minHeight: "440px",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "70px 0 20px 0",
    backgroundColor: "white",
    padding: "0 30px 0 30px",
    borderRadius: "15px",
  },
  rootDesktopEdit: {
    minHeight: "600px",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0 20px 0",
    border: "3px solid #273682",
    padding: "0 30px 0 30px",
    borderRadius: "15px",
  },
  rootDesktopEditDark: {
    minHeight: "600px",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0 20px 0",
    border: "3px solid #0B0B0D",
    padding: "0 30px 0 30px",
    borderRadius: "15px",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
    paddingBottom: "5px",
  },
  addressContainer: {
    width: "100%",
    minHeight: "130px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  addressTitle: {
    fontSize: "1.4em",
    lineHeight: "1.1em",
    fontWeight: "700",
  },
  addressInfo: {
    fontSize: "1.1em",
    fontWeight: "600",
    color: "#777373",
  },
  iconAndAddressContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    minHeight: "130px",
    marginBottom: "15px",
  },
  sessionTitleContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "70px",
    alignItems: "center",
  },
  inputsContainer: {
    height: "515px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  buttonsContainer: {
    width: "70%",
    minWidth: "300px",
    display: "flex",
    justifyContent: "space-around",
  },
  buttonsContainerMobile: {
    width: "50%",
    minWidth: "300px",
    display: "flex",
    justifyContent: "space-around",
  },
  select: {
    width: "200px",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
        borderRadius: "20px",
      },
    },
    "& .MuiSelect-select": {
      padding: "10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "7px",
    },
  },
  selectContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editAndDeleteIconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputsAndButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const StyledEditIcon = styled(EditIcon, {
  name: "StyledButton",
})({
  margin: "0",
  height: "30px",
});

const StyledEditIconDark = styled(EditIcon, {
  name: "StyledButton",
})({
  color: "#474A56",
  margin: "0",
  height: "30px",
});

const StyledAddCircleSharpIcon = styled(AddCircleSharpIcon, {
  name: "StyledButton",
})({
  margin: "0 0 0 10px",
});

const StyledAddCircleSharpIconDark = styled(AddCircleSharpIcon, {
  name: "StyledButton",
})({
  color: "#474A56",
  margin: "0 0 0 10px",
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "30vw",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopDark = styled(TextField, {
  name: "StyledTextField",
})({
  "& label.MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      border: "1px solid white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  width: "30vw",
  backgroundColor: "#474A56",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopDarkDisabled = styled(TextField, {
  name: "StyledTextField",
})({
  "& label.MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      border: "1px solid white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  pointerEvents: "none",
  width: "30vw",
  backgroundColor: "#474A56",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopErrorZipCode = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    color: "red",
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "30vw",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextFieldDesktopErrorZipCodeDark = styled(TextField, {
  name: "StyledTextField",
})({
  "& label.MuiInputLabel-root": {
    color: "red",
  },
  "& .MuiOutlinedInput-root": {
    color: "red",
    "& fieldset": {
      border: "1px solid white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
  },
  "& label.Mui-focused": {
    color: "white",
  },
  width: "30vw",
  backgroundColor: "#474A56",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "60vw",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldErrorZipCode = styled(TextField, {
  name: "StyledTextField",
})({
  width: "60vw",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    color: "red",
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  border: "1px solid transparent",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#EEF5ED",
    color: "#273682",
    border: "1px solid #273682",
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
    border: "1px solid white",
  },
});

const StyledButtonCancel = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#273682",
  color: "white",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  border: "1px solid #273682",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#EEF5ED",
    border: "1px solid #273682",
    color: "#273682",
  },
});

const StyledButtonCancelDark = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "white",
  color: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  border: "1px solid #0B0B0D",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    color: "white",
    border: "1px solid white",
  },
});

const StyledDeleteForeverIcon = styled(DeleteForeverIcon, {
  name: "DeleteIcon",
})({
  color: "#A4A5AA",
  height: "30px",
  margin: "0",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledInputBaseMobile = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "60vw",
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "1em",
    borderRadius: "20px",
    lineHeight: "2.5em",
    color: "#273682",
    fontWeight: "light",
    backgroundColor: "white",
    padding: "5px 5px 5px 15px",
  },
});

const StyledInputBaseDesktop = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "30vw",
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "1em",
    borderRadius: "20px",
    lineHeight: "2.5em",
    color: "#273682",
    fontWeight: "light",
    backgroundColor: "white",
    padding: "5px 5px 5px 15px",
  },
});

const StyledInputBaseDesktopDark = styled(InputBase, {
  name: "StyledInputBase",
})({
  width: "30vw",
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "1em",
    borderRadius: "5px",
    lineHeight: "2.5em",
    color: "white",
    fontWeight: "light",
    backgroundColor: "#474A56",
    padding: "5px 5px 5px 15px",
    border: "1px solid white",
  },
});

const StyledWorkIcon = styled(HomeWorkIcon, {
  name: "StyledWorkIcon",
})({
  color: "white",
});

const StyledBusinessIcon = styled(BusinessIcon, {
  name: "StyledBusinessIcon",
})({
  color: "white",
});

const StyledFormatListNumberedSharpIcon = styled(FormatListNumberedSharpIcon, {
  name: "StyledFormatListNumberedSharpIcon",
})({
  color: "white",
});

const StyledFlagSharpIcon = styled(FlagSharpIcon, {
  name: "StyledWorkIcon",
})({
  color: "white",
});

const StyledLocationCityIcon = styled(LocationCityIcon, {
  name: "StyledLocationCityIcon",
})({
  color: "white",
});

const StyledHolidayVillageSharpIcon = styled(HolidayVillageSharpIcon, {
  name: "StyledHolidayVillageSharpIcon",
})({
  color: "white",
});

const StyledAddBusinessIcon = styled(AddBusinessIcon, {
  name: "StyledAddBusinessIcon",
})({
  color: "white",
});

const StyledContactMailSharpIcon = styled(ContactMailSharpIcon, {
  name: "StyledContactMailSharpIcon",
})({
  color: "white",
});

export const Address = () => {
  const [addresses, setAddresses] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  const [selectedAddress, setSelectedAddress] = useState({});

  const colorMode = useContext(modeContext);

  const [addAddress, setAddAddress] = useState(false);

  const { fetchGetAddresses, requestDataAddress, requestLoading } =
    useGetAddresses();

  const { fetchCreateAddresses, requestErrorCreate } = useCreateAddresses();

  const {
    fetchGetCep,
    requestDataCep,
    requestLoadingCep,
    requestErrorCep,
    setRequestDataCep,
    setRequestErrorCep,
  } = useGetCep();

  const { enqueueSnackbar } = useSnackbar();

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const { fetchUpdateAddresses, requestLoadingUpdate } = useUpdateAddresses();

  const { fetchDeleteAddresses, requestLoadingDelete, requestErrorDelete } =
    useDeleteAddresses();

  const AddressType =
    user?.systemRole !== 4
      ? ["Endereço Principal", "Endereço de Entrega"]
      : ["Endereço de Entrega"];

  const [addressesLength, setAddressesLength] = useState(0);

  const [updatingAddress, setUpdatingAddress] = useState(false);

  const [zipcode, setZipCode] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [recipient, setRecipient] = useState("");
  const [addressTypeSelected, setAddressTypeSelected] = useState(null);
  const [oldZipCode, setOldZipCode] = useState("");

  const [cepLoaded, setCepLoaded] = useState({});

  const handleRequestCep = () => {
    return new Promise((resolve, reject) => {
      fetchGetCep(zipcode);
      resolve(requestDataCep.data);
    });
  };

  const handleNumber = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setNumber(event.target.value);
    }
  };

  const handleZipCode = (event) => {
    event.target.maxLength = 9;
    if (!requestLoading && !requestLoadingUpdate) {
      event.target.value = event.target.value.replace(/\D/g, "");
      setZipCode(event.target.value.replace(/^(\d{5})(\d)/, "$1-$2"));
    }
  };

  const handleStreet = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setStreet(event.target.value);
    }
  };

  const handleComplement = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setComplement(event.target.value);
    }
  };

  const handleState = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setState(event.target.value);
    }
  };

  const handleCity = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setCity(event.target.value);
    }
  };

  const handleDistrict = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setDistrict(event.target.value);
    }
  };

  const handleRecipient = (event) => {
    if (!requestLoading && !requestLoadingUpdate) {
      setRecipient(event.target.value);
    }
  };

  const [search, setSearch] = useState(false);

  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:900px)");

  const handleUpdate = async (data) => {
    data._id = selectedAddress._id;
    data.street = street;
    data.city = city;
    data.state = state;
    data.district = district;
    data.recipient = recipient;
    data.number = number;
    data.complement = complement;
    data.zipcode = zipcode;
    if (user?.systemRole === 4) {
      data.companyId = company?.company_id;
    }
    await fetchUpdateAddresses(data);
    if (user?.systemRole === 4) {
      const result = await fetchGetAddresses(company.company_id);
      setAddresses(result);
      setAddressesLength(result.data.length);
    } else {
      const result = await fetchGetAddresses();
      setAddresses(result);
      setAddressesLength(result.data.length);
    }
    setSelectedAddress({});
    setUpdatingAddress(false);
    setZipCode("");
    setStreet("");
    setComplement("");
    setNumber("");
    setState("");
    setCity("");
    setDistrict("");
    enqueueSnackbar("Endereço atualizado!", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    setRequestDataCep({});
  };

  const handleAdd = async (data) => {
    data.street = street;
    data.city = city;
    data.state = state;
    data.district = district;
    data.recipient = recipient;
    data.zipcode = zipcode;
    data.number = number;
    data.complement = complement;
    if (user?.systemRole === 4) {
      data.companyId = company?.company_id;
    }
    if (addressTypeSelected === "Endereço Principal") {
      data.type = "billing_address";
      await fetchCreateAddresses(data);
      if (user?.systemRole === 4) {
        const result = await fetchGetAddresses(company.company_id);
        setAddresses(result);
        setAddressesLength(result.data.length);
      } else {
        const result = await fetchGetAddresses();
        setAddresses(result);
        setAddressesLength(result.data.length);
      }
      setAddAddress(false);
      setZipCode("");
      setStreet("");
      setComplement("");
      setNumber("");
      setState("");
      setCity("");
      setDistrict("");
      enqueueSnackbar("Endereço adicionado!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setRequestDataCep({});
    } else {
      data.type = "shipping_address";
      if (user?.systemRole === 4) {
        data.companyId = company?.company_id;
      }
      if (!addresses.data.find((add) => add.type === "shipping_address")) {
        await fetchCreateAddresses(data);
        if (user?.systemRole === 4) {
          const result = await fetchGetAddresses(company.company_id);
          setAddresses(result);
          setAddressesLength(result.data.length);
        } else {
          const result = await fetchGetAddresses();
          setAddresses(result);
          setAddressesLength(result.data.length);
        }
        setAddAddress(false);
        setZipCode("");
        setStreet("");
        setComplement("");
        setNumber("");
        setState("");
        setCity("");
        setDistrict("");
        enqueueSnackbar("Endereço adicionado!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setRequestDataCep({});
      } else if (
        addresses.data.find((add) => add.type === "shipping_address")
      ) {
        enqueueSnackbar(
          "Endereço de Entrega já cadastrado, atualize-o caso necessário!",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    }
  };

  const handleDelete = async (id) => {
    if (user?.systemRole === 4) {
      await fetchDeleteAddresses(id, company?.company_id);
    } else {
      await fetchDeleteAddresses(id);
    }
    if (user?.systemRole === 4) {
      const result = await fetchGetAddresses(company.company_id);
      setAddresses(result);
      setAddressesLength(result.data.length);
    } else {
      const result = await fetchGetAddresses();
      setAddresses(result);
      setAddressesLength(result.data.length);
    }
    enqueueSnackbar("Endereço removido!", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  const handleCancelUpdate = () => {
    setSelectedAddress({});
    setSearch(false);
    setUpdatingAddress(false);
    setZipCode("");
    setStreet("");
    setComplement("");
    setNumber("");
    setState("");
    setCity("");
    setDistrict("");
    setRequestDataCep({});
    setRequestErrorCep(false);
    setRecipient("");
    setCepLoaded({});
    setOldZipCode("");
  };

  const handleCancelAdd = () => {
    setAddAddress(false);
    setSearch(false);
    setZipCode("");
    setStreet("");
    setComplement("");
    setNumber("");
    setState("");
    setCity("");
    setDistrict("");
    setRequestDataCep({});
    setRequestErrorCep(false);
    setRecipient("");
    setCepLoaded({});
    setOldZipCode("");
  };

  const handleAddScreen = () => {
    setSearch(true);
    setAddAddress(true);
  };

  useEffect(() => {
    const loadAddresses = async () => {
      if (Object.keys(addresses).length === 0 && user?.systemRole !== 4) {
        const result = await fetchGetAddresses();
        if (!requestLoading) {
          setAddresses(result);
          setAddressesLength(result.data.length);
        }
      } else if (
        Object.keys(addresses).length === 0 &&
        user?.systemRole === 4 &&
        company?.company_id !== undefined
      ) {
        const result = await fetchGetAddresses(company.company_id);
        if (!requestLoading) {
          setAddresses(result);
          setAddressesLength(result.data.length);
        }
      }
    };
    loadAddresses();

    if (
      (addAddress || updatingAddress) &&
      company?.company_id &&
      company?.companyName &&
      recipient?.length === 0
    ) {
      setRecipient(company?.companyName);
    }

    if (company?.company_id && company?.companyChanged && addAddress) {
      setAddAddress(false);
      handleCancelAdd();
    }

    if (company?.company_id && company?.companyChanged && updatingAddress) {
      setUpdatingAddress(false);
      setSelectedAddress({});
      handleCancelUpdate();
    }

    const loadAddressesChange = async () => {
      if (
        Object.keys(addresses).length !== 0 &&
        user?.systemRole === 4 &&
        company?.company_id !== undefined &&
        company?.companyChanged
      ) {
        const result = await fetchGetAddresses(company.company_id);
        if (!requestLoading) {
          setAddresses(result);
          setAddressesLength(result.data.length);
        }
      }
    };
    loadAddressesChange();

    if (addressTypeSelected === null) {
      setAddressTypeSelected(AddressType[0]);
    }

    const loadSelectedAddressToUpdate = () => {
      if (
        Object.keys(selectedAddress).length !== 0 &&
        !updatingAddress &&
        !addAddress
      ) {
        setZipCode(selectedAddress.zipcode);
        setStreet(selectedAddress.street);
        setComplement(selectedAddress.complement);
        setNumber(selectedAddress.number);
        setState(selectedAddress.state);
        setCity(selectedAddress.city);
        setDistrict(selectedAddress.district);
        setRecipient(selectedAddress.recipient);
        setUpdatingAddress(true);
        setOldZipCode(selectedAddress.zipcode);
      }
    };
    loadSelectedAddressToUpdate();

    const loadCepDataUpdate = async () => {
      if (
        Object.keys(selectedAddress).length !== 0 &&
        oldZipCode !== zipcode &&
        zipcode.length > 8
      ) {
        const result = await fetchGetCep(zipcode);
        if (result?.data?.logradouro) {
          setStreet(result?.data?.logradouro);
        }
        if (result?.data?.localidade) {
          setCity(result?.data?.localidade);
        }
        if (result?.data?.bairro) {
          setDistrict(result?.data?.bairro);
        }
        if (result?.data?.uf) {
          setState(result?.data?.uf);
        }
        setOldZipCode(zipcode);
      }
    };
    loadCepDataUpdate();

    const loadCepDataAdd = async () => {
      if (addAddress && oldZipCode !== zipcode && zipcode.length > 8) {
        const result = await fetchGetCep(zipcode);
        if (result?.data?.logradouro) {
          setStreet(result?.data?.logradouro);
        }
        if (result?.data?.localidade) {
          setCity(result?.data?.localidade);
        }
        if (result?.data?.bairro) {
          setDistrict(result?.data?.bairro);
        }
        if (result?.data?.uf) {
          setState(result?.data?.uf);
        }
        setOldZipCode(zipcode);
      }
    };
    loadCepDataAdd();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestDataAddress,
    addresses,
    oldZipCode,
    requestErrorCep,
    selectedAddress,
    zipcode,
    street,
    complement,
    number,
    state,
    city,
    district,
    updatingAddress,
    requestLoading,
    requestLoadingUpdate,
    requestLoadingDelete,
    addressesLength,
    requestDataCep,
    requestLoadingCep,
    search,
    requestErrorCreate,
    addAddress,
    requestErrorDelete,
    user,
    company,
  ]);

  return (
    <div
      className={
        isMobile && Object.keys(selectedAddress).length === 0 && !addAddress
          ? classes.root
          : (isMobile && Object.keys(selectedAddress).length !== 0) ||
            (isMobile && addAddress)
          ? classes.rootEdit
          : ((!isMobile && Object.keys(selectedAddress).length !== 0) ||
              (!isMobile && addAddress)) &&
            colorMode !== "dark"
          ? classes.rootDesktopEdit
          : (!isMobile && Object.keys(selectedAddress).length !== 0) ||
            (!isMobile && addAddress && colorMode === "dark")
          ? classes.rootDesktopEditDark
          : Object.keys(addresses).length === 0
          ? classes.rootLoading
          : classes.rootDesktop
      }
    >
      {Object.keys(addresses).length !== 0 &&
        Object.keys(selectedAddress).length === 0 &&
        !addAddress && (
          <div className={classes.sessionTitleContainer}>
            <h2 className={classes.sessionTitle}>Endereços</h2>
            {colorMode !== "dark" || isMobile ? (
              <StyledAddCircleSharpIcon onClick={() => handleAddScreen()} />
            ) : (
              <StyledAddCircleSharpIconDark onClick={() => handleAddScreen()} />
            )}
          </div>
        )}

      {Object.keys(addresses).length !== 0 &&
      Object.keys(selectedAddress).length === 0 &&
      !addAddress ? (
        addresses?.data?.map(
          (address, index) =>
            address?.zipcode !== undefined && (
              <div className={classes.iconAndAddressContainer}>
                <div className={classes.addressContainer}>
                  <h3 className={classes.addressTitle}>
                    {address.type === "shipping_address"
                      ? "Endereço de Entrega"
                      : "Endereço Principal"}
                  </h3>
                  <p
                    className={classes.addressInfo}
                  >{`${address.street}, ${address.number}`}</p>
                  <p
                    className={classes.addressInfo}
                  >{`${address.district} - ${address.state}`}</p>
                  <p
                    className={classes.addressInfo}
                  >{`CEP ${address.zipcode}`}</p>
                </div>
                <div className={classes.editAndDeleteIconContainer}>
                  {colorMode !== "dark" || isMobile ? (
                    <StyledEditIcon
                      onClick={() => setSelectedAddress(addresses.data[index])}
                    />
                  ) : (
                    <StyledEditIconDark
                      onClick={() => setSelectedAddress(addresses.data[index])}
                    />
                  )}
                  {user?.systemRole !== 4 && (
                    <StyledDeleteForeverIcon
                      onClick={() => handleDelete(address._id)}
                    />
                  )}
                </div>
              </div>
            )
        )
      ) : (
        <>
          <div>
            {isMobile ? (
              <form
                className={classes.inputsAndButtonsContainer}
                onSubmit={
                  Object.keys(selectedAddress).length !== 0
                    ? handleSubmit(handleUpdate)
                    : handleSubmit(handleAdd)
                }
              >
                <div className={classes.inputsContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <ContactMailSharpIcon />(
                    <StyledTextField
                      required
                      id="zipcode-input"
                      label="CEP"
                      type="text"
                      autoComplete="off"
                      {...register("zipcode")}
                      value={zipcode}
                      onChange={(e) => handleZipCode(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <HomeWorkIcon />
                    <StyledTextField
                      required
                      id="street-input"
                      label="Rua"
                      type="text"
                      autoComplete="off"
                      {...register("street")}
                      value={street}
                      onChange={(e) => handleStreet(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <FormatListNumberedSharpIcon />
                    <StyledTextField
                      required
                      id="number-input"
                      label="Número"
                      type="text"
                      autoComplete="off"
                      {...register("number")}
                      value={number}
                      onChange={(e) => handleNumber(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <BusinessIcon />
                    <StyledTextField
                      id="complement-input"
                      label="Complemento"
                      type="text"
                      autoComplete="off"
                      {...register("complement")}
                      value={complement}
                      onChange={(e) => handleComplement(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <FlagSharpIcon />
                    <StyledTextField
                      required
                      id="state-input"
                      label="Estado"
                      type="text"
                      autoComplete="off"
                      {...register("state")}
                      value={state}
                      onChange={(e) => handleState(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <LocationCityIcon />
                    <StyledTextField
                      required
                      id="city-input"
                      label="Cidade"
                      type="text"
                      autoComplete="off"
                      {...register("city")}
                      value={city}
                      onChange={(e) => handleCity(e)}
                    />
                  </div>

                  <div className={classes.IconAndInputContainer}>
                    <HolidayVillageSharpIcon />
                    <StyledTextField
                      required
                      id="district-input"
                      label="Bairro"
                      type="text"
                      autoComplete="off"
                      {...register("district")}
                      value={district}
                      onChange={(e) => handleDistrict(e)}
                    />
                  </div>
                  <div className={classes.selectContainer}>
                    {Object.keys(selectedAddress).length === 0 && (
                      <>
                        {" "}
                        <AddBusinessIcon />{" "}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addressTypeSelected}
                          label="Tipo"
                          input={<StyledInputBaseMobile />}
                          onChange={(e) =>
                            setAddressTypeSelected(e.target.value)
                          }
                          className={classes.select}
                        >
                          {AddressType.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? classes.buttonsContainerMobile
                      : classes.buttonsContainer
                  }
                >
                  {Object.keys(selectedAddress).length !== 0 ? (
                    <StyledButton type="submit" variant="contained">
                      Salvar
                    </StyledButton>
                  ) : (
                    <StyledButton type="submit" variant="contained">
                      Salvar
                    </StyledButton>
                  )}
                  {Object.keys(selectedAddress).length !== 0 ? (
                    <StyledButtonCancel
                      onClick={() => handleCancelUpdate()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancel>
                  ) : (
                    <StyledButtonCancel
                      onClick={() => handleCancelAdd()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancel>
                  )}
                </div>
              </form>
            ) : (
              <form
                className={classes.inputsAndButtonsContainer}
                onSubmit={
                  Object.keys(selectedAddress).length !== 0
                    ? handleSubmit(handleUpdate)
                    : handleSubmit(handleAdd)
                }
              >
                <div className={classes.inputsContainer}>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <ContactMailSharpIcon />
                    ) : (
                      <StyledContactMailSharpIcon />
                    )}
                    <StyledTextFieldDesktop
                      required
                      id="zipcode-input"
                      label="CEP"
                      type="text"
                      autoComplete="off"
                      {...register("zipcode")}
                      value={zipcode}
                      onChange={(e) => handleZipCode(e)}
                    />
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <HomeWorkIcon />
                    ) : (
                      <StyledWorkIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        required
                        id="street-input"
                        label="Rua"
                        type="text"
                        autoComplete="off"
                        {...register("street")}
                        value={street}
                        onChange={(e) => handleStreet(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDarkDisabled
                        required
                        id="street-input"
                        label="Rua"
                        type="text"
                        autoComplete="off"
                        {...register("street")}
                        value={street}
                        onChange={(e) => handleStreet(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <FormatListNumberedSharpIcon />
                    ) : (
                      <StyledFormatListNumberedSharpIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        required
                        id="number-input"
                        label="Número"
                        type="text"
                        autoComplete="off"
                        {...register("number")}
                        value={number}
                        onChange={(e) => handleNumber(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDark
                        required
                        id="number-input"
                        label="Número"
                        type="text"
                        autoComplete="off"
                        {...register("number")}
                        value={number}
                        onChange={(e) => handleNumber(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <BusinessIcon />
                    ) : (
                      <StyledBusinessIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        id="complement-input"
                        label="Complemento"
                        type="text"
                        autoComplete="off"
                        {...register("complement")}
                        value={complement}
                        onChange={(e) => handleComplement(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDark
                        id="complement-input"
                        label="Complemento"
                        type="text"
                        autoComplete="off"
                        {...register("complement")}
                        value={complement}
                        onChange={(e) => handleComplement(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <FlagSharpIcon />
                    ) : (
                      <StyledFlagSharpIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        required
                        id="state-input"
                        label="Estado"
                        type="text"
                        autoComplete="off"
                        {...register("state")}
                        value={state}
                        onChange={(e) => handleState(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDarkDisabled
                        required
                        id="state-input"
                        label="Estado"
                        type="text"
                        autoComplete="off"
                        {...register("state")}
                        value={state}
                        onChange={(e) => handleState(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <LocationCityIcon />
                    ) : (
                      <StyledLocationCityIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        required
                        id="city-input"
                        label="Cidade"
                        type="text"
                        autoComplete="off"
                        {...register("city")}
                        value={city}
                        onChange={(e) => handleCity(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDarkDisabled
                        required
                        id="city-input"
                        label="Cidade"
                        type="text"
                        autoComplete="off"
                        {...register("city")}
                        value={city}
                        onChange={(e) => handleCity(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    {colorMode !== "dark" ? (
                      <HolidayVillageSharpIcon />
                    ) : (
                      <StyledHolidayVillageSharpIcon />
                    )}
                    {colorMode !== "dark" ? (
                      <StyledTextFieldDesktop
                        required
                        id="district-input"
                        label="Bairro"
                        type="text"
                        autoComplete="off"
                        {...register("district")}
                        value={district}
                        onChange={(e) => handleDistrict(e)}
                      />
                    ) : (
                      <StyledTextFieldDesktopDarkDisabled
                        required
                        id="district-input"
                        label="Bairro"
                        type="text"
                        autoComplete="off"
                        {...register("district")}
                        value={district}
                        onChange={(e) => handleDistrict(e)}
                      />
                    )}
                  </div>
                  <div className={classes.IconAndInputContainer}>
                    <FaceIcon />
                    <StyledTextFieldDesktop
                      required
                      id="recipient-input"
                      label="Destinatário"
                      type="text"
                      autoComplete="off"
                      {...register("recipient")}
                      value={recipient}
                      onChange={(e) => handleRecipient(e)}
                    />
                  </div>
                  <div className={classes.selectContainer}>
                    {Object.keys(selectedAddress).length === 0 && (
                      <>
                        {colorMode !== "dark" ? (
                          <AddBusinessIcon />
                        ) : (
                          <StyledAddBusinessIcon />
                        )}{" "}
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={addressTypeSelected}
                          label="Tipo"
                          input={
                            colorMode !== "dark" ? (
                              <StyledInputBaseDesktop />
                            ) : (
                              <StyledInputBaseDesktopDark />
                            )
                          }
                          onChange={(e) =>
                            setAddressTypeSelected(e.target.value)
                          }
                          className={classes.select}
                        >
                          {AddressType.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isMobile
                      ? classes.buttonsContainerMobile
                      : classes.buttonsContainer
                  }
                >
                  {Object.keys(selectedAddress).length !== 0 &&
                  colorMode !== "dark" ? (
                    <StyledButton type="submit" variant="contained">
                      Salvar
                    </StyledButton>
                  ) : colorMode !== "dark" ? (
                    <StyledButton type="submit" variant="contained">
                      Salvar
                    </StyledButton>
                  ) : (
                    <StyledButtonDark type="submit" variant="contained">
                      Salvar
                    </StyledButtonDark>
                  )}
                  {Object.keys(selectedAddress).length !== 0 &&
                  colorMode !== "dark" ? (
                    <StyledButtonCancel
                      onClick={() => handleCancelUpdate()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancel>
                  ) : Object.keys(selectedAddress).length !== 0 &&
                    colorMode === "dark" ? (
                    <StyledButtonCancelDark
                      onClick={() => handleCancelUpdate()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancelDark>
                  ) : colorMode !== "dark" ? (
                    <StyledButtonCancel
                      onClick={() => handleCancelAdd()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancel>
                  ) : (
                    <StyledButtonCancelDark
                      onClick={() => handleCancelAdd()}
                      variant="contained"
                    >
                      Cancelar
                    </StyledButtonCancelDark>
                  )}
                </div>
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
};

import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { loadItem, removeItem, saveItem } from "../utils/localStorage";

const token = loadItem("token");
const termStoreId = loadItem("termStoreId");
const termStatus = loadItem("termStatus");

const initialState = {
  token: token || null,
  termStoreId: termStoreId || "",
  termStatus: termStatus || "empty",
  termDescription: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setTokenAndTerm":
      saveItem(action.token, "token");
      saveItem(action.termStoreId, "termStoreId");
      saveItem(action.termStatus, "termStatus");

      return {
        ...state,
        token: action.token,
        termStoreId: action.termStoreId,
        termStatus: action.termStatus,
        termDescription: action.termDescription,
      };
    case "setAcceptTerms":
      saveItem("valid", "termStatus");

      return {
        ...state,
        termStatus: "valid",
        termDescription: "",
      };
    case "clear":
      removeItem("token");
      removeItem("termStoreId");
      removeItem("termStatus");

      return { token: null, termStoreId: "", termStatus: "empty" };
    default:
      throw new Error("Unexpected action");
  }
};

export const AuthContext = React.createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

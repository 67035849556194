import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useGetFreight } from "../../API/Hooks/Orders";
import { modeContext } from "../../App";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles({
  root: {
    minHeight: "200px",
    width: "350px",
    position: "fixed",
    backgroundColor: "white",
    border: "1px solid #273682",
    zIndex: 2,
    borderRadius: "5px",
    right: "calc(50vw - 175px)",
    top: "calc(30vh)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "100%",
    transition: "all 0.5s linear",
  },
  rootClosed: {
    minHeight: "200px",
    width: "350px",
    position: "fixed",
    backgroundColor: "white",
    border: "1px solid #273682",
    zIndex: 2,
    borderRadius: "5px",
    right: "calc(50vw - 175px)",
    top: "calc(30vh)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "0%",
    pointerEvents: "none",
    transition: "all 0.5s linear",
  },
  addressTitle: {
    fontSize: "1.4em",
    lineHeight: "1.1em",
    fontWeight: "700",
  },
  addressInfo: {
    fontSize: "1.1em",
    fontWeight: "600",
    color: "#777373",
  },
  addressInfoDark: {
    fontSize: "1.1em",
    fontWeight: "600",
    color: "#2B2B30",
  },
  editAndDeleteIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "80px",
  },
  iconAndAddressContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "80%",
    height: "120px",
  },
  addressContainer: {
    width: "80%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "100px",
  },
});

const StyledCheckbox = styled(Checkbox, {
  name: "StyledButton",
})({
  "& .MuiSvgIcon-root": {
    margin: 0,
  },
});

const StyledCheckboxDark = styled(Checkbox, {
  name: "StyledButton",
})({
  "& .MuiSvgIcon-root": {
    margin: 0,
    color: "#474A56",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  boxShadow: "none!important",
  borderRadius: "10px",
  border: "1px solid white",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    border: "1px solid #273682",
    color: "#273682"
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
  },
});

const StyledButtonCancel = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "#273682",
  color: "white",
  textTransform: "unset",
  fontWeight: "bold",
  boxShadow: "none!important",
  marginTop: "10px",
  fontSize: "1.3em",
  border: "1px solid #273682",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682",
    border: "1px solid #273682",
  },
});

const StyledButtonCancelDark = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "60px",
  width: "130px",
  backgroundColor: "white",
  color: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "10px",
  fontSize: "1.3em",
  border: "1px solid #0B0B0D",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    color: "white",
    border: "1px solid white",
  },
});

export const ChangeOrderAddress = ({
  close,
  addresses,
  open,
  setSelectedAddress,
  selectedAddress,
  setSelectedFreight,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:900px)");

  const colorMode = useContext(modeContext);

  const [checked, setChecked] = useState([]);

  const [initialChecked, setInitialChecked] = useState([]);

  const {
    fetchGetFreight,
    requestDataFreight,
    requestLoadingFreight,
    requestErrorFreight,
  } = useGetFreight();

  const shippingAddresses = addresses?.data?.filter(
    (address) => address.type === "shipping_address"
  );

  const handleChange = (index) => {
    let array = [];
    for (let i = 0; i < shippingAddresses.length; i++) {
      if (i === index) {
        array.push(true);
      } else {
        array.push(false);
      }
    }
    setChecked(array);
  };

  const handleFreight = (zipcode) => {
    return new Promise((resolve, reject) => {
      fetchGetFreight(zipcode);
      setSelectedFreight(requestDataFreight);
      resolve();
    });
  };

  const handleContinue = async () => {
    close(false);
    setSelectedAddress(shippingAddresses[checked.indexOf(true)]);
    await handleFreight(selectedAddress.zipcode);
    if (!requestErrorFreight && !requestLoadingFreight) {
      setSelectedFreight(requestDataFreight);
    }
    setInitialChecked(checked);
  };

  const handleCancel = () => {
    close(false);
    setChecked(initialChecked);
  };

  useEffect(() => {
    const handleSelectedAddress = () => {
      if (
        checked.length === 0 &&
        shippingAddresses.length !== 0 &&
        Object.keys(selectedAddress).length !== 0
      ) {
        let array = [];
        for (let i = 0; i < shippingAddresses.length; i++) {
          if (
            selectedAddress.zipcode === shippingAddresses[i].zipcode &&
            selectedAddress.number === shippingAddresses[i].number
          ) {
            array.push(true);
          } else {
            array.push(false);
          }
        }
        setChecked(array);
        setInitialChecked(array);
      }
    };
    handleSelectedAddress();
  }, [
    checked,
    shippingAddresses,
    open,
    initialChecked,
    selectedAddress,
    requestDataFreight,
    requestErrorFreight,
    requestLoadingFreight,
  ]);

  return (
    <div className={open ? classes.root : classes.rootClosed}>
      {checked.length !== 0 &&
        shippingAddresses.map((address, index) => (
          <div className={classes.iconAndAddressContainer}>
            <div className={classes.editAndDeleteIconContainer}>
              {colorMode !== "dark" || isMobile ? (
                <StyledCheckbox
                  checked={checked[index]}
                  onChange={() => handleChange(index)}
                />
              ) : (
                <StyledCheckboxDark
                  checked={checked[index]}
                  onChange={() => handleChange(index)}
                />
              )}
            </div>
            <div className={classes.addressContainer}>
              <p
                className={
                  colorMode !== "dark" || isMobile
                    ? classes.addressInfo
                    : classes.addressInfoDark
                }
              >{`${address.street}, ${address.number}`}</p>
              <p
                className={
                  colorMode !== "dark" || isMobile
                    ? classes.addressInfo
                    : classes.addressInfoDark
                }
              >{`${address.district} - ${address.state}`}</p>
              <p
                className={
                  colorMode !== "dark" || isMobile
                    ? classes.addressInfo
                    : classes.addressInfoDark
                }
              >{`CEP ${address.zipcode}`}</p>
            </div>
          </div>
        ))}
      <div className={classes.buttonsContainer}>
        {colorMode !== "dark" || isMobile ? (
          <>
            {" "}
            <StyledButton variant="contained" onClick={() => handleContinue()}>
              Continuar
            </StyledButton>
            <StyledButtonCancel
              variant="contained"
              onClick={() => handleCancel()}
            >
              Cancelar
            </StyledButtonCancel>
          </>
        ) : (
          <>
            {" "}
            <StyledButtonDark
              variant="contained"
              onClick={() => handleContinue()}
            >
              Continuar
            </StyledButtonDark>
            <StyledButtonCancelDark
              variant="contained"
              onClick={() => handleCancel()}
            >
              Cancelar
            </StyledButtonCancelDark>{" "}
          </>
        )}
      </div>
    </div>
  );
};

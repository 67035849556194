import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useGetAddresses } from "../../../API/Hooks/Addresses";
import { useGetCart } from "../../../API/Hooks/Cart";
import {
  useGetFreight,
  useGetFreightCalculate,
  useOrder,
} from "../../../API/Hooks/Orders";
import { modeContext } from "../../../App";
import { ChangeOrderAddress } from "../../../components/ChangeOrderAddress";
import { ProductDetailsCart } from "../../../components/ProductDetailsCart";
import { AuthContext } from "../../../contexts/Auth";
import { CartLengthContext } from "../../../contexts/CartLength";
import {
  BpRadio,
  BpRadioDark,
  StyledButtonCart,
  StyledButtonCartDark,
  StyledButtonCep,
  StyledButtonCepDark,
  StyledButtonClose,
  StyledButtonCloseDark,
  StyledFormControl,
  StyledLocalShippingIcon,
  StyledLocalShippingIconDark,
  StyledSentimentVeryDissatisfiedIcon,
  StyledSentimentVeryDissatisfiedIconDark,
  StyledTextField,
  StyledTextFieldDark,
  StyledTextFieldDarkSomethingTyped,
} from "./components";
import { useStyles } from "./styles";
import { useSnackbar } from "notistack";
import { UserContext } from "../../../contexts/User";
import { CompanyContext } from "../../../contexts/Company";
import { SelectedTariffsContext } from "../../../contexts/SelectedTariffs";
import { InputBase, MenuItem, Select, TextField } from "@mui/material";
import { styled } from "@mui/styles";

const Cart = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery("(max-width:900px)");

  const inputReference = useRef(null);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const classes = useStyles();

  const navigate = useNavigate();

  const [zipcode, setZipCode] = useState("");

  const handleZipCode = (event) => {
    event.target.maxLength = 9;
    event.target.value = event.target.value.replace(/\D/g, "");
    setZipCode(event.target.value.replace(/^(\d{5})(\d)/, "$1-$2"));
  };

  const colorMode = useContext(modeContext);
  const [, dispatchCartLengthContext] = useContext(CartLengthContext);

  const [selectedTariffs, dispatchSelectedTariffsContext] = useContext(
    SelectedTariffsContext
  );

  const isBig = useMediaQuery("(min-width:1116px)");

  const { fetchGetCart, requestData, requestLoading } = useGetCart();

  const [products, setProducts] = useState({});
  const [productsLength, setProductsLength] = useState(0);

  const [addresses, setAddresses] = useState({});

  const [signature, setSignature] = useState(null);

  const signatureOptions = [
    { id: "test", label: "Teste" },
    { id: "production", label: "Produção" },
  ];

  const StyledTextField = styled(TextField, {
    name: "StyledTextField",
  })({
    width: "100%",
    backgroundColor: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.5em",
    zIndex: "0",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#273682",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#273682",
      },
    },
    "& label.Mui-focused": {
      color: "#273682",
      fontWeight: "600",
    },
    "& .MuiInputLabel-root": {
      color: "#273682",
      fontWeight: "600",
    },
  });

  const [openAddressModal, setOpenAddressModal] = useState(false);

  const { fetchGetAddresses, requestDataAddress } = useGetAddresses();

  const {
    fetchGetFreight,
    requestDataFreight,
    requestLoadingFreight,
    requestErrorFreight,
  } = useGetFreight();

  const {
    fetchGetFreightCalculate,
    requestDataFreightCalculate,
    requestLoadingFreightCalculate,
    requestErrorFreightCalculate,
  } = useGetFreightCalculate();

  const { fetchOrder } = useOrder();

  const [calculatedFreight, setCalculatedFreight] = useState({});

  const [oldZipCode, setOldZipCode] = useState("");

  const [calculatingFreight, setCalculatingFreight] = useState(false);

  const [lastCartProduct, setLastCartProduct] = useState({});

  const [changingTariff, setChangingTariff] = useState(false);

  const [cart] = useContext(CartLengthContext);

  const [inputFocus, setInputFocus] = useState(false);
  const [purchaseFinished, setPurchaseFinished] = useState(false);
  const [purchaseStart, setPurchaseStart] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState({});

  // const [selectedTariffs, setSelectedTariffs] = useState({});

  const [tariffChanged, setTariffChanged] = useState(false);

  const [quantityChanged, setQuantityChanged] = useState(false);

  const [selectedFreight, setSelectedFreight] = useState({});

  const [value, setValue] = useState("sedex");

  const handleChange = (event) => {
    setValue(event.target.value);
    setQuantityChanged(true);
  };

  const handleProducts = (id = null) => {
    return new Promise((resolve, reject) => {
      if (id !== null) {
        fetchGetCart(id);
      } else {
        fetchGetCart();
      }
      setProducts(requestData);
      resolve(requestData.data.length);
    });
  };

  const handleAddresses = (id = null) => {
    return new Promise((resolve, reject) => {
      if (id !== null) {
        fetchGetAddresses(id);
      } else {
        fetchGetAddresses();
      }
      setAddresses(requestDataAddress);
      resolve([true, false, 1]);
    });
  };

  const handleFreight = (zipcode, quantity) => {
    return new Promise((resolve, reject) => {
      fetchGetFreight(zipcode, quantity);
      setSelectedFreight(requestDataFreight);
      resolve();
    });
  };

  const handleCalculateFreight = () => {
    return new Promise((resolve, reject) => {
      fetchGetFreightCalculate(
        zipcode,
        products.data.reduce((acc, curVal) => acc + curVal.quantity, 0)
      );
      resolve();
    });
  };

  const [cleared, setCleared] = useState(false);

  const handleCalculate = () => {
    setZipCode("");
    setOldZipCode("");
    setCleared(true);
    setCalculatedFreight({});
  };

  const data = () => {
    const shopping_ids = products?.data.map((item) => {
      const promoTariffId = selectedTariffs[item.id]?.tariffId;

      const { id, product_id, product } = item;

      const productId = product_id;

      const shoppingId = id;

      const planId = product.plan_id;

      if (promoTariffId !== undefined) {
        return {
          shoppingId,
          productId,
          promoTariffId,
          planId,
        };
      } else {
        return {
          shoppingId,
          productId,
          planId,
        };
      }
    });

    const shipping_address_id = selectedAddress?._id;
    const message = "teste";
    const companyId = company?.company_id;
    const subsidiaryId = company?.companySubsidiaryId;
    const correios_code =
      selectedAddress?._id && user?.systemRole !== 4 && value === "sedex"
        ? "04014"
        : selectedAddress?._id && user?.systemRole !== 4
        ? "04510"
        : null;
    const qtd_simcards = products.data.reduce(
      (acc, curVal) => acc + curVal.quantity,
      0
    );
    if (company?.company_id && !company?.companySubsidiaryId) {
      return {
        shopping_ids,
        shipping_address_id,
        message,
        companyId,
        signature,
        correios_code,
        qtd_simcards,
      };
    } else if (company?.company_id && company?.companySubsidiaryId) {
      return {
        shopping_ids,
        shipping_address_id,
        message,
        companyId,
        subsidiaryId,
        signature,
        correios_code,
        qtd_simcards,
      };
    } else {
      return {
        shopping_ids,
        shipping_address_id,
        message,
        correios_code,
        qtd_simcards,
      };
    }
  };

  const handleCreateOrder = () => {
    if (user?.systemRole === 4 && !signature) {
      enqueueSnackbar(`Defina a assinatura antes de realizar o pedido!`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else if (!selectedAddress?._id) {
      enqueueSnackbar(
        `Cadastre um endereço de entrega antes de realizar o pedido!`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } else {
      setOpenAddressModal(false);
      setPurchaseStart(true);
      const body = data();
      fetchOrder(body).then(() => {
        dispatchCartLengthContext({
          type: "setCartLength",
          length: 0,
        });
        enqueueSnackbar(`Pedido enviado com sucesso!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setPurchaseFinished(true);
      });
    }
  };

  const [token] = useContext(AuthContext);

  const [totalValue, setTotalValue] = useState(0);

  const correctProductTariff = (product) => {
    const tariffFound = selectedTariffs[product.id];
    if (tariffFound !== undefined) {
      return tariffFound.tariff;
    } else {
      return product.product.price_unit;
    }
  };

  const priceFormatted = `${totalValue
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(2)
    .split("")
    .reverse()
    .join("")},${totalValue
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(0, 2)
    .split("")
    .reverse()
    .join("")}`;

  const result = parseFloat(priceFormatted.replace(",", ".")).toLocaleString(
    "pt-BR",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    }
  );

  useEffect(() => {
    const redirectUnloggedUser = () => {
      if (!token.token) {
        return navigate("/login");
      } else if (
        user &&
        company &&
        !company?.company_id &&
        user?.systemRole === 4
      ) {
        return navigate("/");
      }
    };
    redirectUnloggedUser();

    const loadAddresses = async () => {
      if (user?.systemRole && token?.token) {
        if (Object.keys(addresses).length === 0 && user.systemRole !== 4) {
          await handleAddresses();
        } else if (
          Object.keys(addresses).length === 0 &&
          user.systemRole === 4 &&
          company?.company_id !== undefined
        ) {
          const result = await fetchGetAddresses(company.company_id);
          if (result !== undefined) {
            setAddresses(result);
          }
        } else if (
          user.systemRole === 4 &&
          company?.company_id !== undefined &&
          company.companyChanged === true
        ) {
          const result = await fetchGetAddresses(company.company_id);
          if (result !== undefined) {
            setAddresses(result);
          }
          dispatchCompanyContext({
            ...company,
            type: "setComercialCompany",
            companyChanged: false,
          });
        }
      }
    };
    loadAddresses();

    const setFirstShippingAddressSelected = () => {
      if (
        Object.keys(addresses).length !== 0 &&
        selectedAddress &&
        Object.keys(selectedAddress).length === 0
      ) {
        const shippingAddresses = addresses.data.filter(
          (address) => address.type === "shipping_address"
        );
        setSelectedAddress(shippingAddresses[0]);
      }
    };
    setFirstShippingAddressSelected();

    const setFirstFreightSelected = async () => {
      if (
        Object.keys(addresses).length !== 0 &&
        Object.keys(selectedAddress).length !== 0 &&
        Object.keys(selectedFreight).length === 0 &&
        Object.keys(products).length !== 0 &&
        products.data.length !== 0
      ) {
        await handleFreight(
          selectedAddress.zipcode,
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0)
        );
        if (!requestErrorFreight && !requestLoadingFreight) {
          setSelectedFreight(requestDataFreight);
        }
      }
    };
    setFirstFreightSelected();

    const calculatedFreightHandler = async () => {
      if (zipcode.length > 8 && calculatingFreight) {
        setCleared(false);
        await handleCalculateFreight();
        setCalculatingFreight(false);
        setOldZipCode(zipcode);
      }
      if (zipcode.length < 9 || zipcode !== oldZipCode) {
        setCalculatingFreight(true);
      }
      if (!cleared) {
        if (
          Object.keys(requestDataFreightCalculate).length !== 0 &&
          Object.keys(requestDataFreightCalculate.data).length !== 0 &&
          !requestLoadingFreightCalculate &&
          !requestErrorFreightCalculate
        ) {
          setCalculatedFreight(requestDataFreightCalculate);
        }
      }
      if (
        requestErrorFreightCalculate &&
        zipcode.length !== 0 &&
        Object.keys(calculatedFreight).length !== 0
      ) {
        setZipCode("");
        setOldZipCode("");
        setCleared(true);
        setCalculatedFreight({});
      }
    };
    calculatedFreightHandler();

    const loadCartLength = async () => {
      if (user.systemRole && token?.token) {
        if (Object.keys(products).length === 0 && user.systemRole !== 4) {
          const result = await handleProducts();
          setProductsLength(result);
        } else if (
          (Object.keys(products).length === 0 ||
            (products.data.length === 0 && company.companyChanged)) &&
          user.systemRole === 4 &&
          company.company_id !== undefined
        ) {
          const result = await fetchGetCart(company.company_id);
          if (result !== undefined && !requestLoading) {
            setProducts(result);
            setProductsLength(result.data.length);
          }
        }
      }
    };
    loadCartLength();

    const loadTotalValueOfCart = async () => {
      if (
        Object.keys(products).length !== 0 &&
        user.systemRole &&
        token.token
      ) {
        setLastCartProduct(products.data[products.data.length - 1]);
        const initialValue = 0;
        if (
          !requestLoading &&
          Object.keys(selectedFreight).length !== 0 &&
          Object.keys(selectedFreight.data).length !== 0 &&
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0) <
            50 &&
          Object.keys(selectedTariffs).length === 0 &&
          user?.systemRole !== 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              value === "sedex"
                ? Number(selectedFreight.data[0].Valor.replace(",", ""))
                : Number(selectedFreight.data[1].Valor.replace(",", ""))
            )
          );
        } else if (
          !requestLoading &&
          Object.keys(selectedFreight).length !== 0 &&
          Object.keys(selectedFreight.data).length !== 0 &&
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0) >=
            50 &&
          Object.keys(selectedTariffs).length === 0 &&
          user?.systemRole !== 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              initialValue
            )
          );
        } else if (
          !requestLoading &&
          totalValue === 0 &&
          Object.keys(selectedTariffs).length === 0 &&
          user?.systemRole === 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              initialValue
            )
          );
        }

        if (
          (tariffChanged || quantityChanged) &&
          !requestLoading &&
          Object.keys(selectedFreight).length !== 0 &&
          Object.keys(selectedFreight.data).length !== 0 &&
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0) <
            50 &&
          user?.systemRole !== 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              value === "sedex"
                ? Number(selectedFreight.data[0].Valor.replace(",", ""))
                : Number(selectedFreight.data[1].Valor.replace(",", ""))
            )
          );
          setQuantityChanged(false);
        } else if (
          (tariffChanged || quantityChanged) &&
          !requestLoading &&
          Object.keys(selectedFreight).length !== 0 &&
          Object.keys(selectedFreight.data).length !== 0 &&
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0) >=
            50 &&
          user?.systemRole !== 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              initialValue
            )
          );
          setQuantityChanged(false);
        } else if (
          (tariffChanged || quantityChanged) &&
          !requestLoading &&
          user?.systemRole === 4
        ) {
          setTotalValue(
            products.data.reduce(
              (acc, curVal, index) =>
                acc +
                curVal.quantity * correctProductTariff(products.data[index]),
              initialValue
            )
          );
          setQuantityChanged(false);
        }

        if (productsLength !== products.data.length && user.systemRole !== 4) {
          await handleProducts();
        } else if (
          productsLength !== products.data.length &&
          user.systemRole === 4
        ) {
          const result = await fetchGetCart(company.company_id);
          if (result !== undefined) {
            setProducts(result);
          }
        }

        if (
          user.systemRole === 4 &&
          Object.keys(products).length !== 0 &&
          company.company_id !== undefined
        ) {
          if (products.data[0].company_id !== company.company_id) {
            const result = await fetchGetCart(company.company_id);
            if (result !== undefined && !requestLoading) {
              setProducts(result);
              setProductsLength(result.data.length);
            }
            const result2 = await fetchGetAddresses(company.company_id);
            if (result2 !== undefined) {
              setAddresses(result2);
            }
            const shippingAddresses = result2.data.filter(
              (address) => address.type === "shipping_address"
            );
            setSelectedAddress(shippingAddresses[0]);
            const result3 = await fetchGetFreight(
              shippingAddresses[0].zipcode,
              result.data.reduce((acc, curVal) => acc + curVal.quantity, 0)
            );
            if (!requestErrorFreight && !requestLoadingFreight) {
              setSelectedFreight(result3);
            }
          }
        }
      }
    };
    loadTotalValueOfCart();

    const controlFocus = () => {
      if (
        zipcode.length === 1 &&
        !inputFocus &&
        colorMode === "dark" &&
        !isMobile
      ) {
        inputReference.current.focus();
        setInputFocus(true);
      }
      if (zipcode.length === 0) {
        setInputFocus(false);
      }
    };
    controlFocus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestData,
    products,
    selectedAddress,
    lastCartProduct,
    openAddressModal,
    token,
    totalValue,
    productsLength,
    cart,
    addresses,
    requestLoading,
    selectedFreight,
    requestDataFreight,
    value,
    calculatedFreight,
    zipcode,
    requestLoadingFreight,
    requestErrorFreight,
    oldZipCode,
    calculatingFreight,
    requestDataFreightCalculate,
    requestLoadingFreightCalculate,
    requestErrorFreightCalculate,
    cleared,
    inputFocus,
    company,
    user,
    tariffChanged,
    selectedTariffs,
    changingTariff,
    quantityChanged,
  ]);

  if (purchaseFinished) {
    return <Navigate to="/orders" />;
  }

  return (
    <>
      <div
        className={
          colorMode !== "dark" || isMobile
            ? classes.sessionTitleContainer
            : classes.sessionTitleContainerDark
        }
      >
        <h1
          className={
            colorMode !== "dark" || isMobile
              ? classes.sessionTitle
              : classes.sessionTitleDark
          }
        >
          Seu carrinho de compras
        </h1>
      </div>
      {Object.keys(addresses).length !== 0 && selectedAddress && (
        <ChangeOrderAddress
          addresses={addresses}
          open={openAddressModal}
          close={setOpenAddressModal}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          setSelectedFreight={setSelectedFreight}
        />
      )}
      {productsLength === 0 && !requestLoading ? (
        <div
          className={
            isMobile
              ? classes.noOrdersContainerMobile
              : colorMode !== "dark"
              ? classes.noOrdersContainer
              : classes.noOrdersContainerDark
          }
        >
          {" "}
          <h3
            className={
              isMobile
                ? classes.noOrdersTextMobile
                : colorMode !== "dark"
                ? classes.noOrdersText
                : classes.noOrdersTextDark
            }
          >
            Você ainda não adicionou nenhum item ao carrinho!
          </h3>
          {colorMode !== "dark" || isMobile ? (
            <StyledSentimentVeryDissatisfiedIcon />
          ) : (
            <StyledSentimentVeryDissatisfiedIconDark />
          )}
        </div>
      ) : (
        <div
          className={
            isMobile
              ? classes.root
              : colorMode !== "dark"
              ? classes.rootDesktop
              : classes.rootDesktopDark
          }
        >
          <div
            className={
              isMobile ? classes.cardsContainerMobile : classes.cardsContainer
            }
          >
            {!requestLoading &&
              products?.data?.map((product, index) => (
                <ProductDetailsCart
                  carrier={product?.product?.telco}
                  id={product?.id}
                  index={index}
                  cartItemId={product?.id}
                  productId={product?.product_id}
                  name={product?.product?.name}
                  setChangingTariff={setChangingTariff}
                  type={product?.product?.product_type}
                  setProductsLength={setProductsLength}
                  setProducts={setProducts}
                  setQuantityChanged={setQuantityChanged}
                  products={products}
                  quantityBackEnd={product?.quantity}
                  lastCartProduct={lastCartProduct}
                  promotionalTariffsList={product?.promotional_tariffs}
                  price={product?.product?.price_unit}
                  selectedTariffs={selectedTariffs}
                  selectedAddress={selectedAddress?.zipcode}
                  setSelectedTariffs={dispatchSelectedTariffsContext}
                  tariffChanged={tariffChanged}
                  selectedFreight={selectedFreight}
                  setTariffChanged={setTariffChanged}
                  setSelectedFreight={setSelectedFreight}
                  setTotalValue={setTotalValue}
                  value={value}
                />
              ))}
            {!requestLoading && Object.keys(products).length !== 0 && (
              <div
                className={
                  isMobile
                    ? classes.freightContainerMobile
                    : classes.freightContainer
                }
              >
                <div className={classes.inputFreightContainerAndText}>
                  <p
                    className={
                      colorMode !== "dark" || isMobile
                        ? classes.freeShippingParagraph
                        : classes.freeShippingParagraphDark
                    }
                  >
                    * Frete grátis a partir de 50 unidades
                  </p>
                </div>
              </div>
            )}
          </div>
          {!requestLoading && Object.keys(products).length !== 0 && (
            <div
              className={
                isMobile
                  ? classes.orderDetailsContainer
                  : isBig && colorMode !== "dark" && user?.systemRole !== 4
                  ? classes.orderDetailsContainerDesktopBigger
                  : isBig && user?.systemRole === 4
                  ? classes.orderDetailsContainerDesktopBiggerComercial
                  : isBig && colorMode === "dark"
                  ? classes.orderDetailsContainerDesktopBiggerDark
                  : !isBig && colorMode !== "dark"
                  ? classes.orderDetailsContainerDesktop
                  : classes.orderDetailsContainerDesktopDark
              }
            >
              <div className={classes.valueParagraphContainer}>
                {Object.keys(products).length !== 0 &&
                products.data.length !== 0 &&
                user?.systemRole !== 4 &&
                products.data.reduce(
                  (acc, curVal) => acc + curVal.quantity,
                  0
                ) < 50 ? (
                  <p className={classes.detailsParagraphLeftTotal}>
                    Total com Frete:
                  </p>
                ) : (
                  <p className={classes.detailsParagraphLeftTotal}>Total:</p>
                )}
                <p
                  className={
                    colorMode !== "dark" || isMobile
                      ? classes.detailsParagraphRightBold
                      : classes.detailsParagraphRightBoldDark
                  }
                >
                  {Object.keys(products).length !== 0 &&
                  products.data.length !== 0 &&
                  isNaN(
                    products.data.reduce(
                      (acc, curVal) => acc + curVal.quantity,
                      0
                    )
                  ) === true ? (
                    <span className={classes.noQuantity}>
                      Item sem Quantidade!
                    </span>
                  ) : (
                    !changingTariff && `R$ ${result}`
                  )}
                </p>
              </div>
              {user?.systemRole !== 4 && (
                <div className={classes.paragraphsContainer}>
                  {user?.systemRole !== 4 && (
                    <p className={classes.detailsParagraphLeft}>
                      <span className={classes.companyNameTitle}>
                        Endereço:
                      </span>{" "}
                      {Object.keys(selectedAddress).length !== 0 &&
                        `${selectedAddress.street}, ${selectedAddress.number} - ${selectedAddress.city}, ${selectedAddress.state} - CEP ${selectedAddress.zipcode}`}
                    </p>
                  )}

                  <div
                    className={
                      isBig
                        ? classes.radioContainerBigger
                        : Object.keys(products).length !== 0 &&
                          products.data.length !== 0 &&
                          products.data.reduce(
                            (acc, curVal) => acc + curVal.quantity,
                            0
                          ) < 50
                        ? classes.radioContainer
                        : classes.radioContainerFreeShipping
                    }
                  >
                    {user?.systemRole !== 4 && (
                      <div className={classes.sentParagraph}>
                        <p className={classes.detailsParagraphLeftSent}>
                          Envio:
                        </p>
                      </div>
                    )}

                    <StyledFormControl>
                      <RadioGroup
                        className={
                          isMobile
                            ? classes.containerRadiosMobile
                            : classes.containerRadios
                        }
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                      >
                        {Object.keys(products).length !== 0 &&
                          products.data.length !== 0 &&
                          products.data.reduce(
                            (acc, curVal) => acc + curVal.quantity,
                            0
                          ) >= 50 && (
                            <h2 className={classes.freeShipping}>
                              Frete Grátis
                            </h2>
                          )}
                        {Object.keys(products)?.length !== 0 &&
                          products?.data?.length !== 0 &&
                          !requestLoadingFreight &&
                          selectedFreight?.data?.[0]?.Codigo !== "---" && (
                            <>
                              {" "}
                              {Object.keys(selectedFreight).length !== 0 &&
                                Object.keys(selectedFreight.data).length !==
                                  0 &&
                                isBig &&
                                (colorMode !== "dark" || isMobile) &&
                                products.data.reduce(
                                  (acc, curVal) => acc + curVal.quantity,
                                  0
                                ) < 50 &&
                                selectedFreight.data.map((freight) => (
                                  <FormControlLabel
                                    value={freight.Type.toLowerCase()}
                                    control={<BpRadio />}
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.9em",
                                          width: "100%",
                                        }}
                                      >
                                        {freight.Type} - R$ {freight.Valor} -{" "}
                                        {freight.PrazoEntrega}{" "}
                                        {freight.PrazoEntrega === "1"
                                          ? "dia útil"
                                          : "dias úteis"}
                                      </span>
                                    }
                                  />
                                ))}
                            </>
                          )}
                        {Object.keys(products).length !== 0 &&
                          products.data.length !== 0 && (
                            <>
                              {" "}
                              {Object.keys(selectedFreight).length !== 0 &&
                                Object.keys(selectedFreight.data).length !==
                                  0 &&
                                !isBig &&
                                (colorMode !== "dark" || isMobile) &&
                                products.data.reduce(
                                  (acc, curVal) => acc + curVal.quantity,
                                  0
                                ) < 50 &&
                                selectedFreight.data.map((freight) => (
                                  <FormControlLabel
                                    value={freight.Type.toLowerCase()}
                                    control={<BpRadio />}
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.9em",
                                          width: "100%",
                                        }}
                                      >
                                        {freight.Type} - R$ {freight.Valor}
                                      </span>
                                    }
                                  />
                                ))}
                            </>
                          )}
                        {Object.keys(products).length !== 0 &&
                          products.data.length !== 0 &&
                          !requestLoadingFreight &&
                          selectedFreight?.data?.[0]?.Codigo !== "---" && (
                            <>
                              {Object.keys(selectedFreight).length !== 0 &&
                                Object.keys(selectedFreight.data).length !==
                                  0 &&
                                isBig &&
                                colorMode === "dark" &&
                                !isMobile &&
                                products.data.reduce(
                                  (acc, curVal) => acc + curVal.quantity,
                                  0
                                ) < 50 &&
                                selectedFreight.data.map((freight) => (
                                  <FormControlLabel
                                    value={freight.Type.toLowerCase()}
                                    control={<BpRadioDark />}
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.9em",
                                          width: "100%",
                                        }}
                                      >
                                        {freight.Type} - R$ {freight.Valor} -{" "}
                                        {freight.PrazoEntrega}{" "}
                                        {freight.PrazoEntrega === "1"
                                          ? "dia útil"
                                          : "dias úteis"}
                                      </span>
                                    }
                                  />
                                ))}
                            </>
                          )}
                        {Object.keys(products).length !== 0 &&
                          products.data.length !== 0 && (
                            <>
                              {" "}
                              {Object.keys(selectedFreight).length !== 0 &&
                                Object.keys(selectedFreight.data).length !==
                                  0 &&
                                !isBig &&
                                colorMode === "dark" &&
                                !isMobile &&
                                products.data.reduce(
                                  (acc, curVal) => acc + curVal.quantity,
                                  0
                                ) < 50 &&
                                selectedFreight.data.map((freight) => (
                                  <FormControlLabel
                                    value={freight.Type.toLowerCase()}
                                    control={<BpRadioDark />}
                                    label={
                                      <span
                                        style={{
                                          fontSize: "0.9em",
                                          width: "100%",
                                        }}
                                      >
                                        {freight.Type} - R$ {freight.Valor}
                                      </span>
                                    }
                                  />
                                ))}
                            </>
                          )}
                      </RadioGroup>
                    </StyledFormControl>
                    {Object.keys(selectedFreight).length !== 0 &&
                      selectedFreight.data.length !== 0 &&
                      !requestLoadingFreight &&
                      selectedFreight?.data?.[0]?.Codigo !== "---" &&
                      !isBig &&
                      products.data.reduce(
                        (acc, curVal) => acc + curVal.quantity,
                        0
                      ) < 50 && (
                        <p className={classes.paragraphFreight}>
                          *{" "}
                          {selectedFreight.data[0]?.PrazoEntrega === "1"
                            ? selectedFreight.data[0]?.PrazoEntrega +
                              " dia útil"
                            : selectedFreight.data[0]?.PrazoEntrega +
                              " dias úteis"}{" "}
                          para SEDEX e {selectedFreight.data[1]?.PrazoEntrega}{" "}
                          dias úteis para PAC.
                        </p>
                      )}
                  </div>
                  {Object.keys(products).length !== 0 &&
                  products.data.length !== 0 &&
                  user?.systemRole !== 4 &&
                  products.data.reduce(
                    (acc, curVal) => acc + curVal.quantity,
                    0
                  ) < 50 ? (
                    <>
                      <Link
                        className={
                          isBig && colorMode !== "dark" && !isMobile
                            ? classes.linkAddressBigger
                            : isBig && colorMode === "dark" && !isMobile
                            ? classes.linkAddressBiggerDark
                            : colorMode !== "dark" || isMobile
                            ? classes.linkAddress
                            : classes.linkAddressDark
                        }
                        onClick={() => setOpenAddressModal(true)}
                        to={{}}
                      >
                        Alterar Endereço
                      </Link>
                    </>
                  ) : (
                    user?.systemRole !== 4 && (
                      <Link
                        className={
                          isBig && colorMode !== "dark" && !isMobile
                            ? classes.linkAddressBiggerFreeShipping
                            : isBig && colorMode === "dark" && !isMobile
                            ? classes.linkAddressBiggerDarkFreeShipping
                            : colorMode !== "dark" || isMobile
                            ? classes.linkAddressFreeShipping
                            : classes.linkAddressDarkFreeShipping
                        }
                        onClick={() => setOpenAddressModal(true)}
                        to={{}}
                      >
                        Alterar Endereço
                      </Link>
                    )
                  )}
                </div>
              )}
              {user?.systemRole === 4 && (
                <div style={{ width: "100%" }}>
                  <StyledTextField
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={signature}
                    select
                    InputLabelProps={{ shrink: true }}
                    label="Assinatura"
                    onChange={(e) => setSignature(e.target.value)}
                    className={classes.signatureSelected}
                  >
                    {signatureOptions.map((item) => (
                      <MenuItem value={item.id}>{item.label}</MenuItem>
                    ))}
                  </StyledTextField>
                </div>
              )}
              <div className={classes.buttonContainer}>
                <div className={classes.buttonContainer}>
                  {colorMode !== "dark" || isMobile ? (
                    <StyledButtonCart
                      onClick={() => navigate(`/`)}
                      variant="contained"
                    >
                      Continuar Comprando
                    </StyledButtonCart>
                  ) : (
                    <StyledButtonCartDark
                      onClick={() => navigate(`/`)}
                      variant="contained"
                    >
                      Continuar Comprando
                    </StyledButtonCartDark>
                  )}
                  {Object.keys(products).length !== 0 &&
                  products.data.length !== 0 &&
                  isNaN(
                    products.data.reduce(
                      (acc, curVal) => acc + curVal.quantity,
                      0
                    )
                  ) === true ? (
                    <StyledButtonClose disabled variant="contained">
                      {purchaseStart ? "Processando" : "Fechar Pedido"}
                    </StyledButtonClose>
                  ) : colorMode !== "dark" || isMobile ? (
                    <StyledButtonClose
                      onClick={() => handleCreateOrder()}
                      variant="contained"
                      disabled={purchaseStart}
                    >
                      {purchaseStart ? "Processando" : "Fechar Pedido"}
                    </StyledButtonClose>
                  ) : (
                    <StyledButtonCloseDark
                      onClick={() => handleCreateOrder()}
                      variant="contained"
                      disabled={purchaseStart}
                    >
                      {purchaseStart ? "Processando" : "Fechar Pedido"}
                    </StyledButtonCloseDark>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;

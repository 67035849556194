import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setProducts":
      return {
        id: action.id,
        name: action.name,
        product_type: action.product_type,
        description: action.description,
        telco: action.telco,
        currency: action.currency,
        price_unit: action.price_unit,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const ProductsContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <ProductsContext.Provider value={context}>
      {children}
    </ProductsContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

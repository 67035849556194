import { useSnackbar } from "notistack";

import { useLogout } from "./Auth";

const event401 = new Event("error-401");
const logoutErros = [
  "NOT_AUTHENTICATED",
  "MISSING_AUTHENTICATION_TOKEN",
  "INVALID_AUTHENTICATION_TOKEN",
  "EXPIRED_AUTHENTICATION_TOKEN",
];

export const useValidateErrorResponse = () => {
  const { fetchLogout } = useLogout();
  const { enqueueSnackbar } = useSnackbar();

  const validateError = (statusCode, type = null) => {

    if (type === 'createAddress') {
      enqueueSnackbar("Erro ao cadastrar Endereço!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3500,
      });
    }

    if (type === 'updateAddress') {
      enqueueSnackbar("Erro ao atualizar Endereço!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3500,
      });
    }

    if (type === 'deleteAddress' && statusCode !== 400) {
      enqueueSnackbar("Erro ao deletar Endereço!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3500,
      });
    }

    if (statusCode === 401 && type !== 'deleteAddress') {
      fetchLogout();
    }
    if (statusCode === 400) {
      enqueueSnackbar("Você precisa de pelo menos 1 endereço de cada tipo!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        autoHideDuration: 3500,
      });
    }
    if (statusCode.erro) {
      enqueueSnackbar("Cep não encontado!", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode === 406) {
      enqueueSnackbar("Email e senha não conferem!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode === "Email inválido") {
      enqueueSnackbar("Email inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode[0] === "company_already_exists") {
      enqueueSnackbar("CNPJ já cadastrado!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode[0] === "cnpj_is_invalid") {
      enqueueSnackbar("CNPJ inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode === "999") {
      enqueueSnackbar("CEP inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode[0] === "not_found") {
      enqueueSnackbar("Token expirado!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    if (statusCode[0] === "invalid_email") {
      enqueueSnackbar("Email Inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return { validateError };
};

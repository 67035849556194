import { useParams } from "react-router-dom";
import { ProductDetails } from "../../../components/ProductDetails";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useSpecificProduct } from "../../../API/Hooks/Products";
import { modeContext } from "../../../App";
import { loadItem } from "../../../utils/localStorage";
import { UserContext } from "../../../contexts/User";
import { CompanyContext } from "../../../contexts/Company";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  rootDesktop: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    height: "calc(100vh - 50px)",
    flexDirection: "column",
    alignItems: "center",
  },
  rootDesktopDark: {
    display: "flex",
    backgroundColor: "#474A56",
    width: "100%",
    height: "calc(100vh - 50px)",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Product = () => {
  const isMobile = useMediaQuery("(max-width:900px)");

  const classes = useStyles();

  const [user, dispatchUserContext] = useContext(UserContext)

  const [company, dispatchCompanyContext] = useContext(CompanyContext)

  const colorMode = useContext(modeContext)

  const params = useParams();

  const { fetchSpecificProduct, fetchSpecificUserProduct, requestData } = useSpecificProduct();

  const [product, setProduct] = useState({});

  useEffect(() => {
    const loadSelectedProduct = () => {
      if (Object.keys(product).length === 0 && !loadItem("token")) {
        fetchSpecificProduct(params.id);
        setProduct(requestData);
      } else if (Object.keys(product).length === 0 && loadItem("token") && user.systemRole !== 4) {
        fetchSpecificUserProduct(params.id);
        setProduct(requestData);
      } else if (Object.keys(product).length === 0 && loadItem("token") && user.systemRole === 4 && company.company_id !== undefined) {
        fetchSpecificUserProduct(params.id, company.company_id);
        setProduct(requestData);
      }
    };
    loadSelectedProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData, product, params.id]);

  return (
    <div className={isMobile ? classes.root : colorMode !== 'dark' ? classes.rootDesktop : classes.rootDesktopDark}>
      {Object.keys(product).length !== 0 && (
        <ProductDetails
          id={product?.data?.id}
          name={product?.data?.name}
          type={product?.data?.product_type?.type}
          description={product?.data?.description}
          price={product?.data?.price_unit}
          available={true}
          carrier={product?.data?.telco}
        />
      )}
    </div>
  );
};

export default Product;

import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typography } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "400px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  rootDesktop: {
    width: "500px",
    height: "400px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  paragraph: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLink: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  paragraphDesktop: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  inputsContainer: {
    height: "140px",
    display: "flex",
    marginBottom: "40px",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  buttonAndTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100px",
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "250px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  border: "1px solid #273682",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682"
  },
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
  fontFamily: 'password'
});

const StyledLockIcon = styled(LockIcon, {
  name: "StyledLocalPostOfficeRoundedIcon",
})({
  height: "35px",
  width: "35px",
  marginRight: "15px",
  color: "#273682",
  "&:hover": {
    cursor: "pointer",
  },
});


const formSchema = yup.object().shape({
  password: yup.string().required("Senha obrigatória").min(6, "A senha precisa ter no pelo menos 6 dígitos"),
  confirmPassword: yup
  .string()
  .required("Confirmação de senha obrigatória").oneOf([yup.ref("password")], "As senhas não coincidem!"),
});

const StyledTypography = styled(Typography, {
  name: "StyledCheckbox",
})({
  marginLeft: "60px",
});

export const ForgotPasswordForm = ({ confirmPassword, password, handleChange, setPassword, setConfirmPassword }) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const isMobile = useMediaQuery("(max-width:768px)");

  const handleChangePassword = (e) => {
    setFocus('password')
    setPassword(e.target.value)
  }

  const handleChangeConfirmPassword = (e) => {
    setFocus('confirmPassword')
    setConfirmPassword(e.target.value)
  }

  const classes = useStyles();

  return (
    <div className={isMobile ? classes.root : classes.rootDesktop}>
      <h2 className={classes.sessionTitle}>Minha Conta</h2>
      {isMobile ? (
        <form onSubmit={handleSubmit(handleChange)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLockIcon />
                  <StyledTextField
                    id="outlined-password-input"
                    label="Nova senha"
                    type="password"
                    value={password.value}
                    {...register("password")}
                    error={errors.password ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangePassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.password?.message}
                </StyledTypography>
              </div>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLockIcon />
                  <StyledTextField
                    id="outlined-confirm-password-input"
                    label="Confirmar nova senha"
                    type="password"
                    value={confirmPassword.value}
                    {...register("confirmPassword")}
                    error={errors.confirmPassword ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangeConfirmPassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.confirmPassword?.message}
                </StyledTypography>
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Redefinir Senha
              </StyledButton>
            </div>
          </>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handleChange)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLockIcon />
                  <StyledTextFieldDesktop
                    id="outlined-password-input-test"
                    label="Nova senha"
                    type="password"
                    value={password.value}
                    {...register("password")}
                    error={errors.password ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangePassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.password?.message}
                </StyledTypography>
              </div>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLockIcon />
                  <StyledTextFieldDesktop
                    id="outlined-confirm-password-input-test"
                    label="Confirmar nova senha"
                    type="password"
                    value={confirmPassword.value}
                    {...register("confirmPassword")}
                    error={errors.confirmPassword ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangeConfirmPassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.confirmPassword?.message}
                </StyledTypography>
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Redefinir senha
              </StyledButton>
            </div>
          </>
        </form>
      )}
    </div>
  );
};

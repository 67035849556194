import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles({
  rootClosed: {
    width: "90%",
    borderRadius: "15px",
    border: "2px solid #4A5591",
    height: "70px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
  },
  rootClosedDesktop: {
    width: "70%",
    borderRadius: "15px",
    border: "2px solid #4A5591",
    height: "70px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpenDesktop: {
    width: "70%",
    borderRadius: "15px",
    border: "3px solid #4A5591",
    height: "200px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpen: {
    width: "90%",
    borderRadius: "15px",
    border: "2px solid #4A5591",
    height: "300px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
  },
  rootOpenOnePackage: {
    width: "90%",
    borderRadius: "15px",
    border: "2px solid #4A5591",
    height: "200px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
  },
  orderItemsNameContainer: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
  },
  priceAndIconContainer: {
    height: "50px",
    width: "30%",
    color: "#4A5591",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  lista: {
    height: "100px",
    width: "90%",
    fontWeight: "bold",
    paddingLeft: "4px",
  },
  details: {
    color: "#4A5591",
    fontWeight: "600",
    width: "69px",
    paddingBottom: "1px",
  },
  price: {
    width: "90px",
    textAlign: 'center'
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",
    '&:hover': {
      cursor: 'pointer'
    }
  },
  firstInfosContainer: {
    display: "flex",
    width: "97%",
    marginTop: "12px",
    justifyContent: "space-around",
  },
  firstInfosContainerBiggerScreen: {
    display: "flex",
    width: "90%",
    marginTop: "20px",
    justifyContent: "space-around",
  },
  rotate: {
    transform: "rotate(-180deg)",
    transition: "transform 0.2s ease-in",
    height: "20px",
  },
  rotateBack: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in",
    height: "20px",
  },
  listContainer: {
    width: "95%",
    marginBottom: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  orderNumberTitle: {
    width: "100px",
    fontWeight: "bold",
  },
  orderNumberTitleDateMobile: {
    width: "100px",
    fontWeight: "bold",
    textAlign: "center",
    paddingRight: "10px",
  },
  orderNumber: {
    width: "100px",
  },
  orderNumber2: {
    width: "100px",
    textAlign: "center",
    paddingRight: "15px",
  },
  orderNumberContainer: {
    width: "100px",
  },
  footer: {
    display: "flex",
    width: "100%",
    marginBottom: "5px",
    justifyContent: "space-around",
  },
  footerBold: {
    fontWeight: "bold",
    color: "green",
  },
  list: {
    width: "50%",
    height: "20px",
    fontSize: "0.8em",
    textAlign: "center",
  },
  spanList: {
    display: "inline-block",
    width: "82%",
  },
});

const StyledArrowBackIosIconClosed = styled(KeyboardArrowDownIcon, {
  name: "StyledSentimentVeryDissatisfiedIcon",
})({
  height: "20px",
  width: "20px",
  color: "#666666",
  "&:hover": {
    cursor: "pointer",
  },
  margin: "0",
});

export const StatusProductMobile = ({ lastOrder }) => {
  const isMobile = useMediaQuery("(max-width:900px)");

  const priceFormatted = `${lastOrder.price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(2)
    .split("")
    .reverse()
    .join("")},${lastOrder.price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(0, 2)
    .split("")
    .reverse()
    .join("")}`;

  const total = () => {
    let result = parseFloat(priceFormatted.replace(",", "."));
    result = result.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
    return `R$ ${result}`;
  };

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    isMobile && (
      <div
        className={
          open && lastOrder.orders.length > 1
            ? classes.rootOpen
            : open && lastOrder.orders.length === 1
            ? classes.rootOpenOnePackage
            : classes.rootClosed
        }
      >
        <div className={classes.firstInfosContainer}>
          <div className={classes.orderItemsNameContainer}>
            <div className={classes.orderNumberContainer}>
              <h2 className={classes.orderNumberTitle}>Nº do Pedido</h2>
              <p className={classes.orderNumber2}>#{lastOrder._id.slice(-5).toUpperCase()}</p>
            </div>
            <div className={classes.orderNumberContainer}>
              <h2 className={classes.orderNumberTitleDateMobile}>Data</h2>
              <p className={classes.orderNumber}>
                {typeof lastOrder.orders[0].date_created_at === 'string' && lastOrder.orders[0].date_created_at.includes(' ') ? moment(
                lastOrder.orders[0].date_created_at
                  .split(" ")[0]
                  .split("-")
                  .reverse()
                  .join(""),
                "DDMMYYYY"
              ).format("DD/MM/YYYY") : lastOrder.orders[0].date_created_at.includes('T') ? moment(
                lastOrder.orders[0].date_created_at
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join(""),
                "DDMMYYYY"
              ).format("DD/MM/YYYY") : 'Data antiga'}
              </p>
            </div>
          </div>
          <div className={classes.priceAndIconContainer}>
            <h2 className={classes.price}>{total()}</h2>
            <div
              className={classes.detailsContainer}
              onClick={() => setOpen(!open)}
            >
              <div className={open ? classes.rotate : classes.rotateBack}>
                <StyledArrowBackIosIconClosed />
              </div>
              <p className={classes.details}>Detalhes</p>
            </div>
          </div>
        </div>
        {lastOrder.orders.length > 1
          ? lastOrder.orders.map((item, index) => (
              <>
                <div className={classes.footer}>
                <h2>
                    <span className={classes.footerBold}>
                      Status do pacote {index + 1}:
                    </span>
                    {(item.comments[item.comments.length - 1].statusNow  === "awaiting_payment" || item.comments[item.comments.length - 1].statusNow  === "awaiting_payment_for_chip_order") ? (
                      <span> Pedido Realizado</span>
                    ) : item.comments[item.comments.length - 1].statusNow  === "chips_order" ? <span> Pagamento Aprovado</span> : item.comments[item.comments.length - 1].statusNow  === "packing" ? <span> Preparando Pedido</span> : item.comments[item.comments.length - 1].statusNow  === "posted" ? <span> Pedido Enviado</span> : <span> Pedido Entregue</span>}
                  </h2>
                </div>
                <div className={classes.listContainer}>
                  {item.products.map((product) => (
                    <li className={classes.list}>
                      <span className={classes.spanList}>
                        {product.quantity} {product.product.name}
                      </span>
                    </li>
                  ))}
                </div>
              </>
            ))
          : lastOrder.orders.map((item, index) => (
              <>
                <div className={classes.listContainer}>
                  {item.products.map((product) => (
                    <li className={classes.list}>
                      <span className={classes.spanList}>
                        {product.quantity} {product.product.name}
                      </span>
                    </li>
                  ))}
                </div>
                <div className={classes.footer}>
                <h2>
                    <span className={classes.footerBold}>
                      Status do pacote:
                    </span>
                    {(item.comments[item.comments.length - 1].statusNow  === "awaiting_payment" || item.comments[item.comments.length - 1].statusNow  === "awaiting_payment_for_chip_order") ? (
                      <span> Pedido Realizado</span>
                    ) : item.comments[item.comments.length - 1].statusNow  === "chips_order" ? <span> Pagamento Aprovado</span> : item.comments[item.comments.length - 1].statusNow  === "packing" ? <span> Preparando Pedido</span> : item.comments[item.comments.length - 1].statusNow  === "posted" ? <span> Pedido Enviado</span> : <span> Pedido Entregue</span>}
                  </h2>
                </div>
              </>
            ))}
      </div>
    )
  );
};

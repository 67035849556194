import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Auth";
import { StatusProductMobile } from "../../../components/statusProductMobile";
import { useGetOrder } from "../../../API/Hooks/Orders";
import { UserContext } from "../../../contexts/User";
import { CompanyContext } from "../../../contexts/Company";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 60px)",
  },
  rootWithoutTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#4A5591",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsAndStatusContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

const Orders = () => {
  const isMobile = useMediaQuery("(max-width:900px)", { noSsr: true });

  const classes = useStyles();

  const [somethingOpen, setSomethingOpen] = useState([]);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const navigate = useNavigate();

  const [orders, setOrders] = useState({});

  const [token] = useContext(AuthContext);

  const { fetchGetOrder, requestData, requestLoading } = useGetOrder();

  const handleOrders = () => {
    return new Promise((resolve, reject) => {
      fetchGetOrder();
      setOrders(requestData);
      resolve();
    });
  };

  useEffect(() => {
    const redirectUnloggedUser = () => {
      if (!token.token) {
        return navigate("/login");
      } else if (
        user &&
        company &&
        !company?.company_id &&
        user?.systemRole === 4
      ) {
        return navigate("/");
      }
    };
    redirectUnloggedUser();

    const loadOrders = async () => {
      if (Object.keys(orders).length === 0) {
        await handleOrders();
      }
    };
    loadOrders();

    const manageOpenOrder = () => {
      if (Object.keys(orders).length !== 0) {
        if (orders.data.length !== 0 && somethingOpen.length === 0) {
          let array = [];
          for (let i = 0; i < orders.length; i++) {
            if (i === 0) {
              array.push(true);
            } else {
              array.push(false);
            }
          }
          setSomethingOpen(array);
        }
      }
    };
    manageOpenOrder();

    const redirectDesktop = () => {
      if (!isMobile) {
        return navigate("/status");
      }
    };
    redirectDesktop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isMobile, orders, requestData, requestLoading, company, user]);

  return (
    <div className={classes.root}>
      <div className={classes.sessionTitleContainer}>
        <h1 className={classes.sessionTitle}>Meus Pedidos</h1>
      </div>
      <div className={classes.rootWithoutTitle}>
        {Object.keys(orders).length !== 0 &&
          !requestLoading &&
          orders.data.length !== 0 &&
          orders.data.map((order) => <StatusProductMobile lastOrder={order} />)}
      </div>
    </div>
  );
};

export default Orders;

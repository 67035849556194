import axios from "axios";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts/Auth";
import { ThemeContext } from "../../contexts/Theme";
import { UserContext } from "../../contexts/User";
import { useSettingsUser } from "./User";
import { loginAuthBodyDefault } from "./APIConstants";
import { normalizeAuthAndUserData } from "./Normalizers";
import { useValidateErrorResponse } from "./useValidateErrorResponse";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useLogin = () => {
  const { fetchActiveTerm } = useTerms();
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();
  const { setSettingsUserData } = useSettingsUser();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const handleError = (obj, reject) => {
    if (obj.token === undefined) {
      setRequestError(true);
      const errMessage = validateError("Email inválido");
      reject(errMessage);
    } else {
      setRequestData(obj);
    }
  };

  const { token, term } = requestData || {};

  React.useEffect(() => {
    if (token) {
      fetchActiveTerm()
        .then((data) => ({
          termDescription: data?.term,
          termStatus: term?.active_term
            ? "valid"
            : JSON.stringify(term?.last_term) === "{}"
            ? "empty"
            : "needs_update",
        }))
        .then((payloadContextData) => {
          dispatchAuthContext({
            type: "setTokenAndTerm",
            token,
            termStoreId: term?._id,
            ...payloadContextData,
          });
          setSettingsUserData(requestData);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatchAuthContext]);

  const fetchLogin = ({ username, password }) => {
    if (!requestLoading && username && password) {
      setRequestLoading(true);
      setRequestError(false);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          email: username,
          password,
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}/public/auth/login`, body, {
            headers: {},
            cancelToken,
          })
          .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => handleError(values, reject))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return {
    requestError,
    requestLoading,
    fetchLogin,
  };
};

export const useRegister = () => {
  const { fetchActiveTerm } = useTerms();
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();
  const { setSettingsUserData } = useSettingsUser();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const handleError = (data, reject) => {
    if (!Array.isArray(data)) {
      const values = normalizeAuthAndUserData(data);
      setRequestData(values);
    } else {
      setRequestError(true);
      const errMessage = validateError(data);
      reject(errMessage);
    }
  };

  const { token, term } = requestData || {};

  React.useEffect(() => {
    if (token) {
      fetchActiveTerm()
        .then((data) => ({
          termDescription: data?.term,
          termStatus: term?.active_term
            ? "valid"
            : JSON.stringify(term?.last_term) === "{}"
            ? "empty"
            : "needs_update",
        }))
        .then((payloadContextData) => {
          dispatchAuthContext({
            type: "setTokenAndTerm",
            token,
            termStoreId: term?._id,
            ...payloadContextData,
          });
          setSettingsUserData(requestData);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatchAuthContext]);

  const fetchRegister = ({ company, type, user }) => {
    if (!requestLoading && company && type && user) {
      setRequestLoading(true);
      setRequestError(false);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          company,
          type,
          user,
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}/public/user/`, body, {
            headers: {},
            cancelToken,
          })
          .then(({ data }) => handleError(data, reject))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchRegister };
};

export const useRecoveryPassword = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({ status: false });

  const fetchRecoveryPassword = ({ username }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && username) {
        setRequestError(false);
        setRequestLoading(true);
        const axiosToken = CancelToken.source();
        const cancelToken = axiosToken?.token;

        const body = {
          email: username,
        };

        axios
          .put(
            `${process.env.REACT_APP_API_URL}/user/request-new-password`,
            body,
            { cancelToken }
          )
          .then(() => {
            setRequestData({ status: true });
            resolve();
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      } else {
        reject();
      }
    });
  };

  return { requestData, requestError, requestLoading, fetchRecoveryPassword };
};

export const useChangePassword = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({ status: false });

  const fetchChangePassword = ({ password, token }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && password && token) {
        setRequestError(false);
        setRequestLoading(true);
        const axiosToken = CancelToken.source();
        const cancelToken = axiosToken?.token;

        const body = {
          password,
          token,
        };

        axios
          .put(`${process.env.REACT_APP_API_URL}/user/new-password`, body, {
            cancelToken,
          })
          .then(() => {
            setRequestData({ status: true });
            resolve();
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      } else {
        reject();
      }
    });
  };

  return { requestData, requestError, requestLoading, fetchChangePassword };
};

export const useLogout = () => {
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const [, dispatchUserContext] = useContext(UserContext);
  const [, dispatchThemeContext] = useContext(ThemeContext);

  const [requestLoadingLogout, setRequestLoadingLogout] = React.useState(false);

  const fetchLogout = () => {
    if (stateAuthContext.token) {
      return new Promise((resolve, reject) => {
        if (!requestLoadingLogout) {
          setRequestLoadingLogout(true);
          const headers = createHeaders();

          const body = {};

          axios
            .put(`${process.env.REACT_APP_API_URL}/private/user/logout`, body, {
              headers,
            })
            .finally(() => {
              dispatchAuthContext({ type: "clear" });
              dispatchUserContext({ type: "clear" });
              dispatchThemeContext({ type: "clear" });
              resolve();
              setRequestLoadingLogout(false);
            });
        } else {
          reject();
        }
      });
    }
  };

  return { fetchLogout, requestLoadingLogout };
};

export const useResetPassword = () => {
  const { fetchActiveTerm } = useTerms();
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();
  const { setSettingsUserData } = useSettingsUser();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const handleError = (data, reject) => {
    if (!Array.isArray(data)) {
      const values = normalizeAuthAndUserData(data);
      setRequestData(values);
    } else {
      setRequestError(true);
      const errMessage = validateError(data);
      reject(errMessage);
    }
  };

  const { token, term } = requestData || {};

  React.useEffect(() => {
    if (token) {
      fetchActiveTerm()
        .then((data) => ({
          termDescription: data?.term,
          termStatus: term?.active_term
            ? "valid"
            : JSON.stringify(term?.last_term) === "{}"
            ? "empty"
            : "needs_update",
        }))
        .then((payloadContextData) => {
          dispatchAuthContext({
            type: "setTokenAndTerm",
            token,
            termStoreId: term?._id,
            ...payloadContextData,
          });
          setSettingsUserData(requestData);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatchAuthContext]);

  const fetchResetPassword = ({ password, token }) => {
    if (!requestLoading && password && token) {
      setRequestLoading(true);
      setRequestError(false);

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          password,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/private/user/newPasswordEmail`,
            body,
            {
              headers,
              cancelToken,
            }
          )
          .then(({ data }) => handleError(data, reject))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchResetPassword };
};

export const useUpdateUserPassword = () => {
  const { fetchActiveTerm } = useTerms();
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();
  const { setSettingsUserData } = useSettingsUser();
  const [requestLoadingUpdatePassword, setRequestLoadingUpdatePassword] =
    React.useState(false);
  const [requestErrorUpdatePassword, setRequestErrorUpdatePassword] =
    React.useState(false);
  const [requestDataUpdatePassword, setRequestDataUpdatePassword] =
    React.useState({});

  const handleError = (data, reject) => {
    if (!Array.isArray(data)) {
      const values = normalizeAuthAndUserData(data);
      setRequestDataUpdatePassword(values);
    } else {
      setRequestErrorUpdatePassword(true);
      const errMessage = validateError(data);
      reject(errMessage);
    }
  };

  const { token, term } = requestDataUpdatePassword || {};

  React.useEffect(() => {
    if (token) {
      fetchActiveTerm()
        .then((data) => ({
          termDescription: data?.term,
          termStatus: term?.active_term
            ? "valid"
            : JSON.stringify(term?.last_term) === "{}"
            ? "empty"
            : "needs_update",
        }))
        .then((payloadContextData) => {
          dispatchAuthContext({
            type: "setTokenAndTerm",
            token,
            termStoreId: term?._id,
            ...payloadContextData,
          });
          setSettingsUserData(requestDataUpdatePassword);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatchAuthContext]);

  const headers = createHeaders();

  const fetchUpdateUserPassword = ({ password }) => {
    if (!requestLoadingUpdatePassword && password) {
      setRequestErrorUpdatePassword(false);
      setRequestLoadingUpdatePassword(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          password,
        };

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/private/user/newPassword `,
            body,
            {
              headers,
              cancelToken,
            }
          )
          .then(({ data }) => handleError(data, reject))
          .catch((err) => {
            setRequestErrorUpdatePassword(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingUpdatePassword(false));
      });
    }
  };

  return {
    requestErrorUpdatePassword,
    requestLoadingUpdatePassword,
    fetchUpdateUserPassword,
    requestDataUpdatePassword,
  };
};

export const useTerms = () => {
  const [, dispatchAuthContext] = React.useContext(AuthContext);
  const { validateError } = useValidateErrorResponse();

  const fetchActiveTerm = () => {
    const axiosToken = CancelToken.source();
    const cancelToken = axiosToken?.token;

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/public/terms/store_use`, {
          headers: {},
          cancelToken,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          const errMessage = validateError(err?.response?.status);
          reject(errMessage);
        });
    });
  };

  const acceptActiveTerm = () => {
    const axiosToken = CancelToken.source();
    const cancelToken = axiosToken?.token;

    const headers = createHeaders();
    const body = {};

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/private/user/newTerm`, body, {
          headers,
          cancelToken,
        })
        .then(() => {
          dispatchAuthContext({
            type: "setAcceptTerms",
          });
          resolve();
        })
        .catch((err) => {
          const errMessage = validateError(err?.response?.status);
          reject(errMessage);
        });
    });
  };

  return {
    fetchActiveTerm,
    acceptActiveTerm,
  };
};

import { makeStyles } from "@mui/styles";
import { useContext, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import claro from "../../assets/img/claro.png";
import vivo from "../../assets/img/vivo.png";
import nlt from "../../assets/img/nlt.png";
import tim from "../../assets/img/tim.png";
import arqia from "../../assets/img/arqia.png";
import oi from "../../assets/img/oi.png";
import algar from "../../assets/img/algarpng.png";
import linksField from "../../assets/img/linksfield.png";
import { modeContext } from "../../App";

const useStyles = makeStyles({
  rootClosed: {
    width: "100%",
    minWidth: "580px",
    borderRadius: "15px",
    border: "2px solid #273682",
    height: "70px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
  },
  rootClosedDesktop: {
    width: "100%",
    minWidth: "580px",
    borderRadius: "15px",
    border: "2px solid tranparent",
    maxHeight: "70px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootClosedDesktopDark: {
    width: "80%",
    borderRadius: "15px",
    border: "2px solid #0B0B0D",
    maxHeight: "70px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpenDesktop: {
    width: "100%",
    minWidth: "580px",
    borderRadius: "15px",
    height: "auto",
    maxHeight: "500px",
    border: "3px solid #273682",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpenDesktopDark: {
    width: "80%",
    minWidth: "580px",
    borderRadius: "15px",
    height: "auto",
    maxHeight: "500px",
    border: "3px solid #0B0B0D",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpenDesktopOnePackage: {
    width: "100%",
    minWidth: "580px",
    borderRadius: "15px",
    maxHeight: "500px",
    border: "3px solid #273682",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpenDesktopOnePackageDark: {
    width: "80%",
    borderRadius: "15px",
    maxHeight: "500px",
    border: "3px solid #0B0B0D",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "max-height 0.5s ease-in-out",
    margin: "0 20px 40px 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  rootOpen: {
    width: "100%",
    minWidth: "580px",
    borderRadius: "15px",
    border: "2px solid #273682",
    height: "300px",
    display: "flex",
    backgroundColor: "white",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "height 0.3s ease-in-out",
    margin: "0 20px 40px 20px",
  },
  orderItemsNameContainer: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
  },
  orderNumberContainer: {
    width: "100px",
    textAlign: "center",
  },
  orderNumberContainerStatus: {
    minWidth: "160px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  orderNumberContainerDate: {
    width: "110px",
    textAlign: "center",
  },
  priceAndIconContainer: {
    height: "50px",
    width: "30%",
    color: "#273682",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  lista: {
    height: "100px",
    width: "90%",
    fontWeight: "bold",
    paddingLeft: "4px",
  },
  details: {
    color: "#273682",
    fontWeight: "600",
    width: "69px",
    paddingBottom: "1px",
  },
  price: {
    width: "110px",
    textAlign: "right",
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",
  },
  firstInfosContainer: {
    display: "flex",
    width: "100%",
    margin: "5px 0 30px 0",
    justifyContent: "space-around",
  },
  firstInfosContainerBiggerScreen: {
    display: "flex",
    width: "100%",
    marginTop: "20px",
    justifyContent: "space-around",
  },
  rotate: {
    transform: "rotate(-180deg)",
    transition: "transform 0.2s ease-in",
    height: "20px",
  },
  rotateBack: {
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in",
    height: "20px",
  },
  listContainer: {
    width: "95%",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  orderNumberTitle: {
    width: "100px",
    fontWeight: "bold",
  },
  orderNumberTitleDate: {
    width: "110px",
    fontWeight: "bold",
  },
  orderNumber: {
    color: "white",
    fontWeight: "600",
    border: "1px solid #273682",
    borderRadius: "15px",
    backgroundColor: "#273682",
  },
  orderNumberStatus: {
    color: "white",
    fontWeight: "600",
    border: "1px solid transparent",
    borderRadius: "15px",
    // backgroundColor: "#273682",
  },
  orderNumberDark: {
    color: "white",
    fontWeight: "600",
    border: "1px solid #474A56",
    borderRadius: "15px",
    backgroundColor: "#474A56",
  },
  list: {
    width: "50%",
    height: "20px",
    textAlign: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    margin: "5px 0 15px 0",
  },
  footerBold: {
    fontWeight: "bold",
  },
  spanList: {
    display: "inline-block",
    width: "75%",
  },
  table: {
    width: "95%",
  },
  tableFirstRow: {
    backgroundColor: "#273682",
    height: "30px",
  },
  tableFirstRowDark: {
    backgroundColor: "#474A56",
    opacity: "80%",
    height: "30px",
  },
  otherRows: {
    height: "35px",
    lineHeight: "1.2em",
  },
  shorterColumn: {
    width: "24%",
    color: "white",
    fontWeight: "600",
    border: "1px solid #B9B9B9",
  },
  shortestColumn: {
    width: "14%",
    color: "white",
    fontWeight: "600",
    border: "1px solid #B9B9B9",
  },
  middleColumn: {
    width: "26%",
    color: "white",
    fontWeight: "600",
    border: "1px solid #B9B9B9",
  },
  biggerColumn: {
    width: "38%",
    color: "white",
    fontWeight: "600",
    border: "1px solid #B9B9B9",
  },
  middleColumn2: {
    width: "26%",
    borderBottom: "1px solid #B9B9B9",
    borderLeft: "1px solid #B9B9B9",
    borderRight: "1px solid #B9B9B9",
  },
  biggerColumn2: {
    width: "64%",
    fontWeight: "600",
    borderBottom: "1px solid #B9B9B9",
    borderLeft: "1px solid #B9B9B9",
    borderRight: "1px solid #B9B9B9",
  },
  shorterColumn2: {
    width: "14%",
    borderBottom: "1px solid #B9B9B9",
    borderLeft: "1px solid #B9B9B9",
    borderRight: "1px solid #B9B9B9",
  },
  cells: {
    border: "1px solid #B9B9B9",
  },
  logoClaro: {
    width: "30px",
    height: "30px",
    verticalAlign: "middle",
  },
  logoTim: {
    width: "25px",
    height: "25px",
    verticalAlign: "middle",
  },
  logoArqia: {
    width: "25px",
    height: "25px",
    verticalAlign: "middle",
  },
  logoOi: {
    width: "25px",
    height: "25px",
    verticalAlign: "middle",
  },
  logoLinksField: {
    width: "115px",
    height: "20px",
    verticalAlign: "middle",
  },
  logoAlgar: {
    width: "30px",
    height: "30px",
    verticalAlign: "middle",
  },
  logoNlt: {
    width: "30px",
    height: "30px",
    verticalAlign: "middle",
  },
  logoVivo: {
    width: "26px",
    height: "28px",
    verticalAlign: "middle",
    marginBottom: "3px",
  },
  request: {
    color: "white",
    fontWeight: "600",
    borderRadius: "15px",
    backgroundColor: "#9ad499",
    padding: "0 10px 1px 10px",
  },
  requestBeingAnalyzed: {
    color: "white",
    fontWeight: "600",
    borderRadius: "15px",
    backgroundColor: "#FFBD00",
    padding: "0 10px 1px 10px",
  },
  requestProcessing: {
    color: "white",
    fontWeight: "600",
    borderRadius: "15px",
    backgroundColor: "#6EB6FE",
    padding: "0 10px 1px 10px",
  },
  requestCancelled: {
    color: "white",
    fontWeight: "600",
    borderRadius: "15px",
    backgroundColor: "#CF0E15",
    padding: "0 10px 1px 10px",
  },
  packageStatus: {
    width: "100%",
  },
});

export const StatusProduct = ({
  order,
  index,
  setSelected,
  somethingOpen,
  setSomethingOpen,
  loaded,
  setLoaded,
}) => {
  useEffect(() => {}, [somethingOpen]);

  const classes = useStyles();

  const colorMode = useContext(modeContext);

  const isShorter = useMediaQuery("(max-width:1115px)");

  function resolveAfter1Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  }

  const handleSelectedOrder = async () => {
    setLoaded(false);
    somethingOpen[index] = true;
    const array2 = somethingOpen.map((val, i) => (i === index ? val : false));
    setSelected(order);
    setSomethingOpen(array2);
    const result = await resolveAfter1Seconds();
    setLoaded(result);
  };

  const priceFormatted = `${order.price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(2)
    .split("")
    .reverse()
    .join("")},${order.price
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(0, 2)
    .split("")
    .reverse()
    .join("")}`;

  const total = () => {
    let result = parseFloat(priceFormatted.replace(",", "."));
    result = result.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
    return `R$ ${result}`;
  };

  const formatterPrices = (priceFormattedParameter) => {
    let result = parseFloat(priceFormattedParameter.replace(",", "."));
    result = result.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
    return `R$ ${result}`;
  };

  return (
    <div
      onClick={() => handleSelectedOrder()}
      className={
        somethingOpen[index] && order.orders.length > 1 && colorMode !== "dark"
          ? classes.rootOpenDesktop
          : somethingOpen[index] &&
            order.orders.length > 1 &&
            colorMode === "dark"
          ? classes.rootOpenDesktopDark
          : somethingOpen[index] &&
            order.orders.length === 1 &&
            colorMode !== "dark"
          ? classes.rootOpenDesktopOnePackage
          : somethingOpen[index] &&
            order.orders.length === 1 &&
            colorMode === "dark"
          ? classes.rootOpenDesktopOnePackageDark
          : colorMode !== "dark"
          ? classes.rootClosedDesktop
          : classes.rootClosedDesktopDark
      }
    >
      <div className={classes.firstInfosContainer}>
        <div className={classes.orderNumberContainer}>
          <h2 className={classes.orderNumberTitle}>Nº do Pedido</h2>
          <p
            className={
              colorMode !== "dark"
                ? classes.orderNumber
                : classes.orderNumberDark
            }
          >
            #{order._id.slice(-5).toUpperCase()}
          </p>
        </div>
        <div className={classes.orderNumberContainerDate}>
          <h2 className={classes.orderNumberTitleDate}>Data</h2>
          <p
            className={
              colorMode !== "dark"
                ? classes.orderNumber
                : classes.orderNumberDark
            }
          >
            {typeof order.orders[0].date_created_at === "string" &&
            order.orders[0].date_created_at.includes(" ")
              ? moment(
                  order.orders[0].date_created_at
                    .split(" ")[0]
                    .split("-")
                    .reverse()
                    .join(""),
                  "DDMMYYYY"
                ).format("DD/MM/YYYY")
              : order.orders[0].date_created_at.includes("T")
              ? moment(
                  order.orders[0].date_created_at
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join(""),
                  "DDMMYYYY"
                ).format("DD/MM/YYYY")
              : "Data antiga"}
          </p>
        </div>
        <div className={classes.orderNumberContainerStatus}>
          <h2 className={classes.orderNumberTitle}>Status</h2>
          <p
            className={
              colorMode !== "dark"
                ? classes.orderNumberStatus
                : classes.orderNumberDark
            }
          >
            {order.comments[order.comments.length - 1].statusNow ===
              "being_analyzed" ||
            order.comments[order.comments.length - 1].statusNow === "enable" ? (
              <span className={classes.requestBeingAnalyzed}>Em Análise</span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "comercial_being_analyzed" ? (
              <span className={classes.requestBeingAnalyzed}>
                Em Aprovação Comercial
              </span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "approved_by_commercial" ? (
              <span className={classes.request}>Aprovado pelo Comercial</span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "disapproved_by_commercial" ? (
              <span className={classes.requestCancelled}>
                Reprovado pelo Comercial
              </span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "cadastral_problem" ? (
              <span className={classes.requestCancelled}>
                Problema Cadastral
              </span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "processing" ? (
              <span className={classes.requestProcessing}>
                Em Processamento
              </span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "cancelled" ? (
              <span className={classes.requestCancelled}>Cancelado</span>
            ) : order.comments[order.comments.length - 1].statusNow ===
              "preparing_logistics" ? (
              <span className={classes.requestBeingAnalyzed}>
                Em preparo pela logística
              </span>
            ) : (
              order.comments[order.comments.length - 1].statusNow ===
                "finished" && (
                <span className={classes.request}>Pedido Entregue</span>
              )
            )}
          </p>
        </div>
        <div className={classes.orderNumberContainer}>
          <h2 className={classes.orderNumberTitle}>Frete</h2>
          {order.total_freight !== 0 ? (
            <p
              className={
                colorMode !== "dark"
                  ? classes.orderNumber
                  : classes.orderNumberDark
              }
            >
              {`R$ ${order.total_freight
                .toString()
                .split("")
                .reverse()
                .join("")
                .substring(2)
                .split("")
                .reverse()
                .join("")},${order.total_freight
                .toString()
                .split("")
                .reverse()
                .join("")
                .substring(0, 2)
                .split("")
                .reverse()
                .join("")}`}
            </p>
          ) : (
            <p
              className={
                colorMode !== "dark"
                  ? classes.orderNumber
                  : classes.orderNumberDark
              }
            >
              Grátis
            </p>
          )}
        </div>
        <div className={classes.orderNumberContainer}>
          <h2 className={classes.orderNumberTitle}>Total Geral</h2>
          <p
            className={
              colorMode !== "dark"
                ? classes.orderNumber
                : classes.orderNumberDark
            }
          >
            {total()}
          </p>
        </div>
      </div>
      {order.orders.length > 1
        ? order.orders.map((item, index) => (
            <>
              <div className={classes.listContainer}>
                <table className={classes.table}>
                  <tr
                    className={
                      colorMode !== "dark"
                        ? classes.tableFirstRow
                        : classes.tableFirstRowDark
                    }
                  >
                    <th className={classes.middleColumn}>Operadora</th>
                    <th className={classes.biggerColumn}>Itens</th>
                    <th className={classes.shortestColumn}>Qtd.</th>
                    <th className={classes.shorterColumn}>Valor</th>
                  </tr>
                  {item.products.map((product) => (
                    <tr className={classes.otherRows}>
                      <td className={classes.cells}>
                        <img
                          className={
                            product.product.telco
                              .toLowerCase()
                              .includes("claro")
                              ? classes.logoClaro
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("vivo")
                              ? classes.logoVivo
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("nlt")
                              ? classes.logoNlt
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("tim")
                              ? classes.logoTim
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("arqia")
                              ? classes.logoArqia
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("oi")
                              ? classes.logoOi
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("links field") ||
                                product.product.telco
                                  .toLowerCase()
                                  .includes("linksfield")
                              ? classes.logoLinksField
                              : classes.logoAlgar
                          }
                          src={
                            product.product.telco
                              .toLowerCase()
                              .includes("claro")
                              ? claro
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("vivo")
                              ? vivo
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("tim")
                              ? tim
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("algar")
                              ? algar
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("nlt")
                              ? nlt
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("oi")
                              ? oi
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("links field") ||
                                product.product.telco
                                  .toLowerCase()
                                  .includes("linksfield")
                              ? linksField
                              : arqia
                          }
                          alt={product.product.telco}
                        ></img>
                      </td>
                      <td className={classes.cells}>
                        {isShorter
                          ? product.product.name.split("CHIP ").join("")
                          : product.product.name}
                      </td>
                      <td className={classes.cells}>{product.quantity}</td>
                      <td className={classes.cells}>
                        {index === 0
                          ? formatterPrices(
                              `${product.price
                                .toString()
                                .split("")
                                .reverse()
                                .join("")
                                .substring(2)
                                .split("")
                                .reverse()
                                .join("")},${product.price
                                .toString()
                                .split("")
                                .reverse()
                                .join("")
                                .substring(0, 2)
                                .split("")
                                .reverse()
                                .join("")}`
                            )
                          : "---"}
                      </td>
                    </tr>
                  ))}
                </table>
                <table className={classes.table}>
                  <tr className={classes.otherRows}>
                    <th className={classes.biggerColumn2}>
                      {index === 0
                        ? "Total Pedido"
                        : `Total Pacote ${
                            order.orders.indexOf(order.orders[index]) + 1
                          }`}
                    </th>
                    <th className={classes.shorterColumn2}>
                      {item.products.reduce(
                        (previousValue, currentValue) =>
                          previousValue + currentValue.quantity,
                        0
                      )}
                    </th>
                    {order.total_freight !== 0 ? (
                      <th className={classes.middleColumn2}>
                        {formatterPrices(
                          `${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              0
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")},${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              0
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")}`
                        )}
                      </th>
                    ) : (
                      <th className={classes.middleColumn2}>
                        {index === 0
                          ? formatterPrices(
                              `${item.products
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.price,
                                  0
                                )
                                .toString()
                                .split("")
                                .reverse()
                                .join("")
                                .substring(2)
                                .split("")
                                .reverse()
                                .join("")},${item.products
                                .reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue.price,
                                  0
                                )
                                .toString()
                                .split("")
                                .reverse()
                                .join("")
                                .substring(0, 2)
                                .split("")
                                .reverse()
                                .join("")}`
                            )
                          : "---"}
                      </th>
                    )}
                  </tr>
                </table>
                {order?.comments?.[order?.comments?.length - 1]?.observation &&
                  order?.comments?.[order?.comments?.length - 1]?.observation
                    ?.length > 0 &&
                  index === order.orders.length - 1 && (
                    <div style={{ marginTop: 10 }}>
                      <h2>
                        <span style={{ fontWeight: "bold", fontSize: "1em" }}>
                          Observação:&nbsp;
                        </span>
                        <span>
                          {
                            order?.comments?.[order?.comments?.length - 1]
                              ?.observation
                          }
                        </span>
                      </h2>
                    </div>
                  )}
              </div>
            </>
          ))
        : order.orders.map((item, index) => (
            <>
              <div className={classes.listContainer}>
                <table className={classes.table}>
                  <tr
                    className={
                      colorMode !== "dark"
                        ? classes.tableFirstRow
                        : classes.tableFirstRowDark
                    }
                  >
                    <th className={classes.middleColumn}>Operadora</th>
                    <th className={classes.biggerColumn}>Itens</th>
                    <th className={classes.shortestColumn}>Qtd.</th>
                    <th className={classes.shorterColumn}>Valor</th>
                  </tr>
                  {item.products.map((product) => (
                    <tr className={classes.otherRows}>
                      <td className={classes.cells}>
                        <img
                          className={
                            product.product.telco
                              .toLowerCase()
                              .includes("claro")
                              ? classes.logoClaro
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("vivo")
                              ? classes.logoVivo
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("nlt")
                              ? classes.logoNlt
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("tim")
                              ? classes.logoTim
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("arqia")
                              ? classes.logoArqia
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("oi")
                              ? classes.logoOi
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("links field") ||
                                product.product.telco
                                  .toLowerCase()
                                  .includes("linksfield")
                              ? classes.logoLinksField
                              : classes.logoAlgar
                          }
                          src={
                            product.product.telco
                              .toLowerCase()
                              .includes("claro")
                              ? claro
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("vivo")
                              ? vivo
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("tim")
                              ? tim
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("algar")
                              ? algar
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("nlt")
                              ? nlt
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("oi")
                              ? oi
                              : product.product.telco
                                  .toLowerCase()
                                  .includes("links field") ||
                                product.product.telco
                                  .toLowerCase()
                                  .includes("linksfield")
                              ? linksField
                              : arqia
                          }
                          alt={product.product.telco}
                        ></img>
                      </td>
                      <td className={classes.cells}>
                        {isShorter
                          ? product.product.name.split("CHIP ").join("")
                          : product.product.name}
                      </td>
                      <td className={classes.cells}>{product.quantity}</td>
                      <td className={classes.cells}>
                        {formatterPrices(
                          `${product.price
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")},${product.price
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")}`
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
                <table className={classes.table}>
                  <tr className={classes.otherRows}>
                    <th className={classes.biggerColumn2}>Total Pedido</th>
                    <th className={classes.shorterColumn2}>
                      {item.products.reduce(
                        (previousValue, currentValue) =>
                          previousValue + currentValue.quantity,
                        0
                      )}
                    </th>
                    {order.total_freight !== 0 ? (
                      <th className={classes.middleColumn2}>
                        {formatterPrices(
                          `${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              order.total_freight
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")},${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              order.total_freight
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")}`
                        )}
                      </th>
                    ) : (
                      <th className={classes.middleColumn2}>
                        {formatterPrices(
                          `${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              0
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")},${item.products
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue.price,
                              0
                            )
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")}`
                        )}
                      </th>
                    )}
                  </tr>
                </table>
                {order?.comments?.[1]?.observation &&
                  order?.comments?.[1]?.observation?.length > 0 && (
                    <div style={{ marginTop: 10 }}>
                      <h2>
                        <span style={{ fontWeight: "bold", fontSize: "1em" }}>
                          Observação:&nbsp;
                        </span>
                        <span>{order?.comments?.[1]?.observation}</span>
                      </h2>
                    </div>
                  )}
              </div>
            </>
          ))}
    </div>
  );
};

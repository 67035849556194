import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArticleIcon from "@mui/icons-material/Article";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FlagSharpIcon from "@mui/icons-material/FlagSharp";
import ContactMailSharpIcon from "@mui/icons-material/ContactMailSharp";
import FormatListNumberedSharpIcon from "@mui/icons-material/FormatListNumberedSharp";
import HolidayVillageSharpIcon from "@mui/icons-material/HolidayVillageSharp";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import BusinessIcon from "@mui/icons-material/Business";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Typography from "@mui/material/Typography";

const formSchema = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "A senha precisa ter no pelo menos 6 dígitos"),
  confirmPassword: yup
    .string()
    .required("Confirmação de Senha obrigatória")
    .oneOf([yup.ref("password")], "As senhas não coincidem!"),
  company_name: yup.string().required("Senha obrigatória"),
  phone: yup
    .string()
    .required("Telefone obrigatório")
    .matches(
      /^\((?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/g,
      "Telefone inválido"
    ),
  cpf: yup
    .string()
    .required("CPF obrigatório")
    .matches(
      /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/g,
      "CPF inválido"
    ),
  cnpj: yup
    .string()
    .required("CNPJ obrigatório")
    .matches(
      /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g,
      "CNPJ inválido"
    ),
  zipcode: yup.string().required("CEP obrigatório"),
  number: yup.string().required("Número obrigatório"),
  state_registration: yup.string().required("Inscrição estadual obrigatória"),
  firstName: yup.string().required("Nome obrigatório"),
  lastName: yup.string().required("Sobrenome obrigatório"),
  birthdate: yup.string().required("Data de nascimento obrigatória"),
});

const formSchema2 = yup.object().shape({
  email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "A senha precisa ter no pelo menos 6 dígitos"),
  confirmPassword: yup
    .string()
    .required("Confirmação de Senha obrigatória")
    .oneOf([yup.ref("password")], "As senhas não coincidem!"),
  company_name: yup.string().required("Senha obrigatória"),
  phone: yup
    .string()
    .required("Telefone obrigatório")
    .matches(
      /^\((?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/g,
      "Telefone inválido"
    ),
  cpf: yup
    .string()
    .required("CPF obrigatório")
    .matches(
      /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/g,
      "CPF inválido"
    ),
  cnpj: yup
    .string()
    .required("CNPJ obrigatório")
    .matches(
      /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g,
      "CNPJ inválido"
    ),
  zipcode: yup.string().required("CEP obrigatório"),
  number: yup.string().required("Número obrigatório"),
  zipcode2: yup.string().required("CEP obrigatório"),
  number2: yup.string().required("Número obrigatório"),
  state_registration: yup.string().required("Inscrição estadual obrigatória"),
  firstName: yup.string().required("Nome obrigatório"),
  lastName: yup.string().required("Sobrenome obrigatório"),
  birthdate: yup.string().required("Data de nascimento obrigatória"),
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "1600px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: '30px'
  },
  rootDoubleAddress: {
    width: "100%",
    height: "2100px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: '30px'
  },
  rootDesktop: {
    width: "500px",
    height: "1600px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: '30px'
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionAddress: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
    marginTop: "35px",
  },
  paragraph: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLink: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  paragraphDesktop: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  inputsContainer: {
    height: "1400px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  inputsContainerDoubleAddress: {
    height: "1900px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  buttonAndTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100px",
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
  },
  typeRegisterButtonsContainer: {
    display: "flex",
    alignItems: "center",
    width: "400px",
    justifyContent: "space-between",
  },
  typeRegisterButtonsContainerMobile: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-around",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
    height: "100px",
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  marginTop: "20px",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#273682",
    filter: "brightness(120%)",
  },
});

const StyledButtonPJ = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "190px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#273682",
    filter: "brightness(120%)",
  },
  color: "white",
});

const StyledButtonPJMobile = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "45%",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#273682",
    filter: "brightness(120%)",
  },
  color: "white",
});

const StyledButtonPFMobile = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "45%",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    filter: "brightness(120%)",
  },
  color: "black",
});

const StyledButtonPF = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "190px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    filter: "brightness(120%)",
  },
  color: "black",
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
});

const StyledCheckbox = styled(Checkbox, {
  name: "StyledCheckbox",
})({
  "& .MuiSvgIcon-root": {
    paddingTop: "1px",
    height: "24px",
    width: "23px",
    margin: "0",
  },
  "& .MuiCheckbox-root": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledTypography = styled(Typography, {
  name: "StyledCheckbox",
})({
  marginLeft: "60px",
});

export const RegisterForm = ({
  email,
  password,
  subscription,
  handleRegister,
  confirmPassword,
  social,
  cnpj,
  date,
  tel,
  cpf,
  street,
  street2,
  number,
  number2,
  district,
  district2,
  state,
  state2,
  cep,
  cep2,
  checked,
  firstName,
  lastName,
  complement,
  complement2,
  city,
  city2,
  handleErase,
  requestErrorCep,
  handleEraseCpfAndCnpj
}) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(checked.value ? formSchema : formSchema2),
  });

  return (
    <div
      className={
        isMobile && checked.value
          ? classes.root
          : isMobile && !checked.value
          ? classes.rootDoubleAddress
          : !isMobile && checked.value
          ? classes.rootDesktop
          : classes.rootDoubleAddress
      }
    >
      <div className={classes.sessionTitleContainer}>
      <h2 className={classes.sessionTitle}>Cadastro</h2>
      </div>
      {isMobile ? (
        <form onSubmit={handleSubmit(handleRegister)}>
          <div
            className={
              checked.value
                ? classes.inputsContainer
                : classes.inputsContainerDoubleAddress
            }
          >
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ArticleIcon />
                <StyledTextField
                  id="outlined-social-input"
                  label="Razão Social"
                  type="text"
                  value={social.value}
                  {...register("company_name")}
                  error={errors.company_name ? true : false}
                  autoComplete="off"
                  onChange={social.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.company_name?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ReceiptLongIcon />
                <StyledTextField
                  id="outlined-subscription-input"
                  label="Inscrição Estadual"
                  type="text"
                  value={subscription.value}
                  {...register("state_registration")}
                  error={errors.state_registration ? true : false}
                  autoComplete="off"
                  onChange={subscription.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.state_registration?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <SummarizeIcon />
                <StyledTextField
                  id="outlined-cnpj-input"
                  label="CNPJ"
                  type="text"
                  value={cnpj.value}
                  onKeyDown={handleEraseCpfAndCnpj}
                  {...register("cnpj")}
                  error={errors.cnpj ? true : false}
                  autoComplete="off"
                  onChange={cnpj.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.cnpj?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextField
                  id="outlined-firstName"
                  label="Nome"
                  type="text"
                  value={firstName.value}
                  {...register("firstName")}
                  error={errors.firstName ? true : false}
                  autoComplete="off"
                  onChange={firstName.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.firstName?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextField
                  id="outlined-lastName"
                  label="Sobrenome"
                  type="text"
                  value={lastName.value}
                  {...register("lastName")}
                  error={errors.lastName ? true : false}
                  autoComplete="off"
                  onChange={lastName.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.lastName?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <EventNoteIcon />
                <StyledTextField
                  id="outlined-date"
                  label="Data de Nascimento"
                  type="text"
                  value={date.value}
                  {...register("birthdate")}
                  error={errors.birthdate ? true : false}
                  autoComplete="off"
                  onKeyDown={handleErase}
                  onChange={date.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.birthdate?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AssignmentIndIcon />
                <StyledTextField
                  id="outlined-cpf"
                  label="CPF"
                  type="text"
                  value={cpf.value}
                  {...register("cpf")}
                  onKeyDown={handleEraseCpfAndCnpj}
                  error={errors.cpf ? true : false}
                  autoComplete="off"
                  onChange={cpf.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.cpf?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <LocalPostOfficeRoundedIcon />
                <StyledTextField
                  id="outlined-email-input-register"
                  label="Email"
                  type="text"
                  value={email.value}
                  {...register("email")}
                  error={errors.email ? true : false}
                  autoComplete="off"
                  onChange={email.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.email?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <CallRoundedIcon />
                <StyledTextField
                  id="outlined-tel-input"
                  label="Telefone"
                  type="text"
                  value={tel.value}
                  {...register("phone")}
                  error={errors.phone ? true : false}
                  autoComplete="off"
                  onKeyDown={handleEraseCpfAndCnpj}
                  onChange={tel.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.phone?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <VisibilityOffIcon />
                <StyledTextField
                  id="outlined-password-input-register"
                  label="Senha"
                  type="password"
                  value={password.value}
                  {...register("password")}
                  error={errors.password ? true : false}
                  autoComplete="off"
                  onChange={password.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.password?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <VisibilityOffIcon />
                <StyledTextField
                  id="outlined-confirm-password-input-register"
                  label="Confirmar senha"
                  type="password"
                  value={confirmPassword.value}
                  {...register("confirmPassword")}
                  error={errors.confirmPassword ? true : false}
                  autoComplete="off"
                  onChange={confirmPassword.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.confirmPassword?.message}
              </StyledTypography>
            </div>
            <h3 className={classes.sessionAddress}>Endereço Principal</h3>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ContactMailSharpIcon />
                <StyledTextField
                  id="outlined-cep"
                  label="CEP"
                  type="text"
                  value={cep.value}
                  {...register("zipcode")}
                  error={errors.zipcode ? true : requestErrorCep ? true : false}
                  autoComplete="off"
                  onChange={cep.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.zipcode?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <HomeWorkIcon />
                <StyledTextField
                  disabled
                  id="outlined-street"
                  label="Rua"
                  type="text"
                  value={street.value}
                  {...register("street")}
                  error={errors.street ? true : false}
                  autoComplete="off"
                  onChange={street.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.street?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <FormatListNumberedSharpIcon />
                <StyledTextField
                  id="outlined-house-number"
                  label="Número"
                  type="text"
                  value={number.value}
                  {...register("number")}
                  error={errors.number ? true : false}
                  autoComplete="off"
                  onChange={number.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.number?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <BusinessIcon />
                <StyledTextField
                  id="outlined-complement"
                  label="Complemento"
                  type="text"
                  value={complement.value}
                  {...register("complement")}
                  error={errors.complement ? true : false}
                  autoComplete="off"
                  onChange={complement.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.complement?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <FlagSharpIcon />
                <StyledTextField
                  disabled
                  id="outlined-state"
                  label="Estado"
                  type="text"
                  value={state.value}
                  {...register("state")}
                  error={errors.state ? true : false}
                  autoComplete="off"
                  onChange={state.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.state?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <LocationCityIcon />
                <StyledTextField
                  disabled
                  id="outlined-city"
                  label="Cidade"
                  type="text"
                  value={city.value}
                  {...register("city")}
                  error={errors.city ? true : false}
                  autoComplete="off"
                  onChange={city.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.city?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <HolidayVillageSharpIcon />
                <StyledTextField
                  disabled
                  id="outlined-district"
                  label="Bairro"
                  type="text"
                  value={district.value}
                  {...register("district")}
                  error={errors.district ? true : false}
                  autoComplete="off"
                  onChange={district.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.district?.message}
              </StyledTypography>
            </div>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={checked.value}
                  onChange={checked.onChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Definir meu endereço de entrega igual ao endereço principal."
            />

            {!checked.value && (
              <>
                <h3 className={classes.sessionAddress}>Endereço de Entrega</h3>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <ContactMailSharpIcon />
                    <StyledTextField
                      id="outlined-cep"
                      label="CEP"
                      type="text"
                      value={cep2.value}
                      {...register("zipcode2")}
                      error={
                        errors.zipcode2 ? true : requestErrorCep ? true : false
                      }
                      autoComplete="off"
                      onChange={cep2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.zipcode2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <HomeWorkIcon />
                    <StyledTextField
                      disabled
                      id="outlined-street"
                      label="Rua"
                      type="text"
                      value={street2.value}
                      {...register("street2")}
                      error={errors.street2 ? true : false}
                      autoComplete="off"
                      onChange={street2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.street2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <FormatListNumberedSharpIcon />
                    <StyledTextField
                      id="outlined-house-number"
                      label="Número"
                      type="text"
                      value={number2.value}
                      {...register("number2")}
                      error={errors.number2 ? true : false}
                      autoComplete="off"
                      onChange={number2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.number2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <BusinessIcon />
                    <StyledTextField
                      id="outlined-complement"
                      label="Complemento"
                      type="text"
                      value={complement2.value}
                      {...register("complement2")}
                      error={errors.complement2 ? true : false}
                      autoComplete="off"
                      onChange={complement2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.complement2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <FlagSharpIcon />
                    <StyledTextField
                      disabled
                      id="outlined-state"
                      label="Estado"
                      type="text"
                      value={state2.value}
                      {...register("state2")}
                      error={errors.state2 ? true : false}
                      autoComplete="off"
                      onChange={state2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.state2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <LocationCityIcon />
                    <StyledTextField
                      disabled
                      id="outlined-city"
                      label="Cidade"
                      type="text"
                      value={city2.value}
                      {...register("city2")}
                      error={errors.city2 ? true : false}
                      autoComplete="off"
                      onChange={city2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.city2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <HolidayVillageSharpIcon />
                    <StyledTextField
                      disabled
                      id="outlined-district"
                      label="Bairro"
                      type="text"
                      value={district2.value}
                      {...register("district2")}
                      error={errors.district2 ? true : false}
                      autoComplete="off"
                      onChange={district2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.district2?.message}
                  </StyledTypography>
                </div>
              </>
            )}
          </div>
          <div className={classes.buttonAndTextContainer}>
            <StyledButton type="submit" variant="contained">
              Cadastrar
            </StyledButton>
            <h3
              className={
                isMobile ? classes.paragraph : classes.paragraphDesktop
              }
            >
              Faça seu{" "}
              <Link to="/login" className={classes.paragraphLink}>
                Login.
              </Link>
            </h3>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handleRegister)}>
          <div
            className={
              checked.value
                ? classes.inputsContainer
                : classes.inputsContainerDoubleAddress
            }
          >
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ArticleIcon />
                <StyledTextFieldDesktop
                  id="outlined-social-input"
                  label="Razão Social"
                  type="text"
                  value={social.value}
                  {...register("company_name")}
                  error={errors.company_name ? true : false}
                  autoComplete="off"
                  onChange={social.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.company_name?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ReceiptLongIcon />
                <StyledTextFieldDesktop
                  id="outlined-subscription-input"
                  label="Inscrição Estadual"
                  type="text"
                  value={subscription.value}
                  {...register("state_registration")}
                  error={errors.state_registration ? true : false}
                  autoComplete="off"
                  onChange={subscription.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.state_registration?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <SummarizeIcon />
                <StyledTextFieldDesktop
                  id="outlined-cnpj-input"
                  label="CNPJ"
                  type="text"
                  value={cnpj.value}
                  {...register("cnpj")}
                  onKeyDown={handleEraseCpfAndCnpj}
                  error={errors.cnpj ? true : false}
                  autoComplete="off"
                  onChange={cnpj.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.cnpj?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextFieldDesktop
                  id="outlined-firstName"
                  label="Nome"
                  type="text"
                  value={firstName.value}
                  {...register("firstName")}
                  error={errors.firstName ? true : false}
                  autoComplete="off"
                  onChange={firstName.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.firstName?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AccountBoxRoundedIcon />
                <StyledTextFieldDesktop
                  id="outlined-lastName"
                  label="Sobrenome"
                  type="text"
                  value={lastName.value}
                  {...register("lastName")}
                  error={errors.lastName ? true : false}
                  autoComplete="off"
                  onChange={lastName.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.lastName?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <EventNoteIcon />
                <StyledTextFieldDesktop
                  id="outlined-date"
                  label="Data de Nascimento"
                  type="text"
                  value={date.value}
                  {...register("birthdate")}
                  error={errors.birthdate ? true : false}
                  autoComplete="off"
                  onKeyDown={handleErase}
                  onChange={date.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.birthdate?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <AssignmentIndIcon />
                <StyledTextFieldDesktop
                  id="outlined-cpf"
                  label="CPF"
                  type="text"
                  value={cpf.value}
                  onKeyDown={handleEraseCpfAndCnpj}
                  {...register("cpf")}
                  error={errors.cpf ? true : false}
                  autoComplete="off"
                  onChange={cpf.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.cpf?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <LocalPostOfficeRoundedIcon />
                <StyledTextFieldDesktop
                  id="outlined-email-input-register"
                  label="Email"
                  type="text"
                  value={email.value}
                  {...register("email")}
                  error={errors.email ? true : false}
                  autoComplete="off"
                  onChange={email.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.email?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <CallRoundedIcon />
                <StyledTextFieldDesktop
                  id="outlined-tel-input"
                  label="Telefone"
                  type="text"
                  value={tel.value}
                  {...register("phone")}
                  error={errors.phone ? true : false}
                  autoComplete="off"
                  onKeyDown={handleEraseCpfAndCnpj}
                  onChange={tel.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.phone?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <VisibilityOffIcon />
                <StyledTextFieldDesktop
                  id="outlined-password-input-register"
                  label="Senha"
                  type="password"
                  value={password.value}
                  {...register("password")}
                  error={errors.password ? true : false}
                  autoComplete="off"
                  onChange={password.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.password?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <VisibilityOffIcon />
                <StyledTextFieldDesktop
                  id="outlined-confirm-password-input-register"
                  label="Confirmar senha"
                  type="password"
                  value={confirmPassword.value}
                  {...register("confirmPassword")}
                  error={errors.confirmPassword ? true : false}
                  autoComplete="off"
                  onChange={confirmPassword.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.confirmPassword?.message}
              </StyledTypography>
            </div>
            <h3 className={classes.sessionAddress}>Endereço Principal</h3>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <ContactMailSharpIcon />
                <StyledTextFieldDesktop
                  id="outlined-cep"
                  label="CEP"
                  type="text"
                  value={cep.value}
                  {...register("zipcode")}
                  error={errors.zipcode ? true : requestErrorCep ? true : false}
                  autoComplete="off"
                  onChange={cep.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.zipcode?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <HomeWorkIcon />
                <StyledTextFieldDesktop
                  disabled
                  id="street"
                  label="Rua"
                  type="text"
                  value={street.value}
                  {...register("street")}
                  error={errors.street ? true : false}
                  autoComplete="off"
                  onChange={street.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.street?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <FormatListNumberedSharpIcon />
                <StyledTextFieldDesktop
                  id="outlined-house-number"
                  label="Número"
                  type="text"
                  value={number.value}
                  {...register("number")}
                  error={errors.number ? true : false}
                  autoComplete="off"
                  onChange={number.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.number?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <BusinessIcon />
                <StyledTextFieldDesktop
                  id="outlined-complement"
                  label="Complemento"
                  type="text"
                  value={complement.value}
                  {...register("complement")}
                  error={errors.complement ? true : false}
                  autoComplete="off"
                  onChange={complement.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.complement?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <FlagSharpIcon />
                <StyledTextFieldDesktop
                  disabled
                  id="outlined-state"
                  label="Estado"
                  type="text"
                  value={state.value}
                  {...register("state")}
                  error={errors.state ? true : false}
                  autoComplete="off"
                  onChange={state.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.state?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <LocationCityIcon />
                <StyledTextFieldDesktop
                  disabled
                  id="outlined-city"
                  label="Cidade"
                  type="text"
                  value={city.value}
                  {...register("city")}
                  error={errors.city ? true : false}
                  autoComplete="off"
                  onChange={city.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.city?.message}
              </StyledTypography>
            </div>
            <div className={classes.errorAndInputContainer}>
              <div className={classes.IconAndInputContainer}>
                <HolidayVillageSharpIcon />
                <StyledTextFieldDesktop
                  disabled
                  id="outlined-district"
                  label="Bairro"
                  type="text"
                  value={district.value}
                  {...register("district")}
                  error={errors.district ? true : false}
                  autoComplete="off"
                  onChange={district.onChange}
                />
              </div>
              <StyledTypography variant="inherit" color="#d32f2f">
                {errors.district?.message}
              </StyledTypography>
            </div>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={checked.value}
                  onChange={checked.onChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Definir meu endereço de entrega igual ao endereço principal."
            />
            {!checked.value && (
              <>
                <h3 className={classes.sessionAddress}>Endereço de Entrega</h3>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <ContactMailSharpIcon />
                    <StyledTextFieldDesktop
                      id="outlined-cep"
                      label="CEP"
                      type="text"
                      value={cep2.value}
                      {...register("zipcode2")}
                      error={
                        errors.zipcode2 ? true : requestErrorCep ? true : false
                      }
                      autoComplete="off"
                      onChange={cep2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.zipcode2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <HomeWorkIcon />
                    <StyledTextFieldDesktop
                      disabled
                      id="street"
                      label="Rua"
                      type="text"
                      value={street2.value}
                      {...register("street2")}
                      error={errors.street2 ? true : false}
                      autoComplete="off"
                      onChange={street2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.street2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <FormatListNumberedSharpIcon />
                    <StyledTextFieldDesktop
                      id="outlined-house-number"
                      label="Número"
                      type="text"
                      value={number2.value}
                      {...register("number2")}
                      error={errors.number2 ? true : false}
                      autoComplete="off"
                      onChange={number2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.number2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <BusinessIcon />
                    <StyledTextFieldDesktop
                      id="outlined-complement"
                      label="Complemento"
                      type="text"
                      value={complement2.value}
                      {...register("complement2")}
                      error={errors.complement2 ? true : false}
                      autoComplete="off"
                      onChange={complement2.onChange}
                    />
                  </div>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <FlagSharpIcon />
                    <StyledTextFieldDesktop
                      disabled
                      id="outlined-state"
                      label="Estado"
                      type="text"
                      value={state2.value}
                      {...register("state2")}
                      error={errors.state2 ? true : false}
                      autoComplete="off"
                      onChange={state2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.state2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <LocationCityIcon />
                    <StyledTextFieldDesktop
                      disabled
                      id="outlined-city"
                      label="Cidade"
                      type="text"
                      value={city2.value}
                      {...register("city2")}
                      error={errors.city2 ? true : false}
                      autoComplete="off"
                      onChange={city2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.city2?.message}
                  </StyledTypography>
                </div>
                <div className={classes.errorAndInputContainer}>
                  <div className={classes.IconAndInputContainer}>
                    <HolidayVillageSharpIcon />
                    <StyledTextFieldDesktop
                      disabled
                      id="outlined-district"
                      label="Bairro"
                      type="text"
                      value={district2.value}
                      {...register("district2")}
                      error={errors.district2 ? true : false}
                      autoComplete="off"
                      onChange={district2.onChange}
                    />
                  </div>
                  <StyledTypography variant="inherit" color="#d32f2f">
                    {errors.district2?.message}
                  </StyledTypography>
                </div>
              </>
            )}
          </div>
          <div className={classes.buttonAndTextContainer}>
            <StyledButton type="submit" variant="contained">
              Cadastrar
            </StyledButton>
            <h3
              className={
                isMobile ? classes.paragraph : classes.paragraphDesktop
              }
            >
              Faça seu{" "}
              <Link to="/login" className={classes.paragraphLink}>
                Login.
              </Link>
            </h3>
          </div>
        </form>
      )}
    </div>
  )
};

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { loginAuthBodyDefault } from "./APIConstants";
import { OrdersContext } from "../../contexts/Orders";
import { useValidateErrorResponse } from "./useValidateErrorResponse";
import { FreightContext } from "../../contexts/Freight";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useOrder = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [, setRequestData] = useState({});

  const headers = createHeaders();

  const fetchOrder = ({
    shopping_ids,
    shipping_address_id,
    message,
    correios_code,
    companyId,
    signature,
    qtd_simcards,
  }) => {
    if (
      !requestLoading &&
      shopping_ids &&
      message &&
      qtd_simcards
    ) {
      setRequestLoading(true);
      setRequestError(false);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          shopping_ids,
          shipping_address_id,
          message,
          correios_code,
          signature,
          companyId,
          qtd_simcards,
        };

        axios
          .post(`${process.env.REACT_APP_API_URL}/private/orders`, body, {
            headers,
            cancelToken,
          })
          .then((values) => {
            setRequestData(values);
            resolve();
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchOrder };
};

export const useGetOrder = () => {
  const [dispatchOrdersContext] = useContext(OrdersContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestData, setRequestData] = useState({});

  const headers = createHeaders();

  const {
    company,
    shipping_address,
    user_order,
    status,
    products,
    date_created_at,
    date_awaiting_payment,
    date_awaiting_payment_for_chip_order,
    date_chips_order,
    date_packing,
    date_ready_to_post,
    date_posted,
    date_in_transit,
    date_delivered,
    message,
    _id,
    company_id,
    freight,
    value_freight,
    price,
    __v,
  } = requestData;

  useEffect(() => {
    if (
      company &&
      shipping_address &&
      user_order &&
      status &&
      products &&
      date_created_at &&
      date_awaiting_payment &&
      date_awaiting_payment_for_chip_order &&
      date_chips_order &&
      date_packing &&
      date_ready_to_post &&
      date_posted &&
      date_in_transit &&
      date_delivered &&
      message &&
      _id &&
      company_id &&
      freight &&
      value_freight &&
      price &&
      __v
    ) {
      dispatchOrdersContext({
        type: "setOrders",
        company,
        shipping_address,
        user_order,
        status,
        products,
        date_created_at,
        date_awaiting_payment,
        date_awaiting_payment_for_chip_order,
        date_chips_order,
        date_packing,
        date_ready_to_post,
        date_posted,
        date_in_transit,
        date_delivered,
        message,
        _id,
        company_id,
        freight,
        value_freight,
        price,
        __v,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company,
    shipping_address,
    user_order,
    status,
    products,
    date_created_at,
    date_awaiting_payment,
    date_awaiting_payment_for_chip_order,
    date_chips_order,
    date_packing,
    date_ready_to_post,
    date_posted,
    date_in_transit,
    date_delivered,
    message,
    _id,
    company_id,
    freight,
    value_freight,
    price,
    __v,
  ]);

  const fetchGetOrder = (companyId = null) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = "/private/orders"

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => {
            setRequestData(values)
            resolve(values)
          })
          .catch((err) => {
            setRequestError(true);
            console.log(err?.response?.status);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchGetOrder, requestData };
};

export const useGetFreight = () => {
  const [dispatchFreightContext] = useContext(FreightContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingFreight, setRequestLoadingFreight] = useState(false);
  const [requestErrorFreight, setRequestErrorFreight] = useState(false);
  const [requestDataFreight, setRequestDataFreight] = useState({});

  const {
    Codigo,
    Valor,
    PrazoEntrega,
    ValorSemAdicionais,
    ValorMaoPropria,
    ValorAvisoRecebimento,
    ValorValorDeclarado,
    EntregaDomiciliar,
    EntregaSabado,
    obsFim,
    Erro,
    MsgErro,
    Type,
  } = requestDataFreight;

  useEffect(() => {
    if (
      Codigo &&
      Valor &&
      PrazoEntrega &&
      ValorSemAdicionais &&
      ValorMaoPropria &&
      ValorAvisoRecebimento &&
      ValorValorDeclarado &&
      EntregaDomiciliar &&
      EntregaSabado &&
      Erro &&
      Type
    ) {
      dispatchFreightContext({
        type: "setFreight",
        Codigo,
        Valor,
        PrazoEntrega,
        ValorSemAdicionais,
        ValorMaoPropria,
        ValorAvisoRecebimento,
        ValorValorDeclarado,
        EntregaDomiciliar,
        EntregaSabado,
        obsFim,
        Erro,
        MsgErro,
        Type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Codigo,
    Valor,
    PrazoEntrega,
    ValorSemAdicionais,
    ValorMaoPropria,
    ValorAvisoRecebimento,
    ValorValorDeclarado,
    EntregaDomiciliar,
    EntregaSabado,
    obsFim,
    Erro,
    MsgErro,
    Type,
  ]);

  const fetchGetFreight = (zipcode, quantity) => {
    if (!requestLoadingFreight) {
      setRequestErrorFreight(false);
      setRequestLoadingFreight(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/public/freight/${zipcode}/${quantity}`,
            {
              cancelToken,
            }
          )
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => {
            setRequestDataFreight(values)
            resolve(values)
          })
          .catch((err) => {
            setRequestErrorFreight(true);
            console.log(err?.response?.status);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingFreight(false));
      });
    }
  };

  return {
    requestErrorFreight,
    requestLoadingFreight,
    fetchGetFreight,
    requestDataFreight,
  };
};

export const useGetFreightCalculate = () => {
  const [dispatchFreightContext] = useContext(FreightContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingFreightCalculate, setRequestLoadingFreightCalculate] =
    useState(false);
  const [requestErrorFreightCalculate, setRequestErrorFreightCalculate] =
    useState(false);
  const [requestDataFreightCalculate, setRequestDataFreightCalculate] =
    useState({});

  const handleError = (obj, reject) => {
    if (obj.data[0].Erro === "0") {
      setRequestDataFreightCalculate(obj);
    } else {
      setRequestErrorFreightCalculate(true);
      const errMessage = validateError(obj.data[0].Erro);
      console.log(obj.data[0].Erro);
      reject(errMessage);
    }
  };

  const {
    Codigo,
    Valor,
    PrazoEntrega,
    ValorSemAdicionais,
    ValorMaoPropria,
    ValorAvisoRecebimento,
    ValorValorDeclarado,
    EntregaDomiciliar,
    EntregaSabado,
    obsFim,
    Erro,
    MsgErro,
    Type,
  } = requestDataFreightCalculate;

  useEffect(() => {
    if (
      Codigo &&
      Valor &&
      PrazoEntrega &&
      ValorSemAdicionais &&
      ValorMaoPropria &&
      ValorAvisoRecebimento &&
      ValorValorDeclarado &&
      EntregaDomiciliar &&
      EntregaSabado &&
      Erro &&
      Type
    ) {
      dispatchFreightContext({
        type: "setFreight",
        Codigo,
        Valor,
        PrazoEntrega,
        ValorSemAdicionais,
        ValorMaoPropria,
        ValorAvisoRecebimento,
        ValorValorDeclarado,
        EntregaDomiciliar,
        EntregaSabado,
        obsFim,
        Erro,
        MsgErro,
        Type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Codigo,
    Valor,
    PrazoEntrega,
    ValorSemAdicionais,
    ValorMaoPropria,
    ValorAvisoRecebimento,
    ValorValorDeclarado,
    EntregaDomiciliar,
    EntregaSabado,
    obsFim,
    Erro,
    MsgErro,
    Type,
  ]);

  const fetchGetFreightCalculate = (zipcode, quantity) => {
    if (!requestLoadingFreightCalculate) {
      setRequestErrorFreightCalculate(false);
      setRequestLoadingFreightCalculate(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/public/freight/${zipcode}/${quantity}`,
            {
              cancelToken,
            }
          )
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => handleError(values, reject))
          .catch((err) => {
            setRequestErrorFreightCalculate(true);
            console.log(err?.response?.status);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingFreightCalculate(false));
      });
    }
  };

  return {
    requestErrorFreightCalculate,
    requestLoadingFreightCalculate,
    fetchGetFreightCalculate,
    requestDataFreightCalculate,
  };
};

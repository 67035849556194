import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AddressesContext } from "../../contexts/Addresses";
import { loginAuthBodyDefault } from "./APIConstants";
import { useValidateErrorResponse } from "./useValidateErrorResponse";
import { UserContext } from "../../contexts/User";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useGetAddresses = () => {
  const [dispatchAddressesContext] = useContext(AddressesContext);
  const [user, dispatchUserContext] = useContext(UserContext)
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestErrorCreate, setRequestErrorCreate] = useState(false);
  const [requestDataAddress, setRequestDataAddress] = useState({});

  const headers = createHeaders();

  const {
    _id,
    zipcode,
    street,
    complement,
    number,
    state,
    city,
    district,
    type,
    company_id,
    __v,
  } = requestDataAddress;

  useEffect(() => {
    if (
      _id &&
      zipcode &&
      street &&
      complement &&
      number &&
      state &&
      city &&
      district &&
      type &&
      company_id &&
      __v
    ) {
      dispatchAddressesContext({
        type: "setAddresses",
        _id,
        zipcode,
        street,
        complement,
        number,
        state,
        city,
        district,
        type,
        company_id,
        __v,
      });
    }
  }, [
    _id,
    zipcode,
    street,
    complement,
    number,
    state,
    city,
    district,
    type,
    company_id,
    __v,
    requestDataAddress,
  ]);

  const fetchGetAddresses = (companyId = null) => {
    if (!requestLoading) {
      setRequestErrorCreate(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = "/private/companies";

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      const handleFixCep = async (result) => {
          let data = {}
          let finalResult = {}
          let newResultArray = []
          for(let i = 0; i < result.data.length; i++){
            if(result?.data?.[i]?.zipcode !== undefined){
              let district = ""
              let requestCepData = {}
              let zipcode = ""
              if(!result.data[i].zipcode.includes('-')){
                zipcode = `${result.data[i].zipcode.slice(0, 5)}-${result.data[i].zipcode.slice(5)}`
                requestCepData = await axios
                .get(`https://viacep.com.br/ws/${zipcode}/json/`, {
                  headers: {},
                  cancelToken,
                })
                district = requestCepData.data.bairro
              } else {
                zipcode = result.data[i].zipcode
                district = result.data[i].district
              }
              newResultArray.push({...result.data[i], zipcode, district})

            data = newResultArray;
          } else {
            data[i] = result?.data?.[i]
          }
          }
          if(result?.data?.length === 0){
            return result
          } else {
            finalResult = {...result, data}
            return finalResult
          }
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            cancelToken,
          })

          .then((values) => {
            if(user?.systemRole !== 4){
              setRequestDataAddress(values)
              resolve(values)
            } else {
              setRequestDataAddress(handleFixCep(values));
              resolve(handleFixCep(values));
            }
          })
          .catch((err) => {
            setRequestErrorCreate(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return {
    requestErrorCreate,
    requestLoading,
    fetchGetAddresses,
    requestDataAddress,
  };
};

export const useUpdateAddresses = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingUpdate, setRequestLoadingUpdate] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchUpdateAddresses = ({
    _id,
    zipcode,
    street,
    complement,
    number,
    state,
    city,
    district,
    recipient,
    companyId = null
  }) => {
    if (
      !requestLoadingUpdate &&
      _id &&
      zipcode &&
      street &&
      number &&
      state &&
      city &&
      district && recipient
    ) {
      setRequestError(false);
      setRequestLoadingUpdate(true);

      let request = "/private/companies";

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          _id,
          zipcode,
          street,
          complement,
          number,
          state,
          city,
          district,
          recipient
        };
        axios
          .put(`${process.env.REACT_APP_API_URL}${request}`, body, {
            headers,
            cancelToken,
          })
          .then((values) => resolve(values.data))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data, 'updateAddress');
            reject(errMessage);
          })
          .finally(() => setRequestLoadingUpdate(false));
      });
    }
  };

  return { requestError, requestLoadingUpdate, fetchUpdateAddresses };
};

export const useCreateAddresses = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingUpdate, setRequestLoadingUpdate] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchCreateAddresses = ({
    zipcode,
    street,
    complement,
    number,
    state,
    city,
    district,
    type,
    recipient,
    companyId = null
  }) => {
    if (
      !requestLoadingUpdate &&
      zipcode &&
      street &&
      number &&
      state &&
      city &&
      district &&
      recipient &&
      type
    ) {
      setRequestError(false);
      setRequestLoadingUpdate(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = "/private/companies";

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          zipcode,
          street,
          complement,
          number,
          state,
          city,
          district,
          type,
          recipient
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}${request}`, body, {
            headers,
            cancelToken,
          })
          .then((values) => resolve(values.data))
          .catch((err) => {
            setRequestError(true);
            console.log(err?.response?.data)
            const errMessage = validateError(err?.response?.data, 'createAddress');
            reject(errMessage);
          })
          .finally(() => setRequestLoadingUpdate(false));
      });
    }
  };

  return { requestError, requestLoadingUpdate, fetchCreateAddresses };
};

export const useDeleteAddresses = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingDelete, setRequestLoadingDelete] = React.useState(false);
  const [requestErrorDelete, setRequestErrorDelete] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchDeleteAddresses = (id, companyId = null) => {
    if (!requestLoadingDelete && id) {
      setRequestErrorDelete(false);
      setRequestLoadingDelete(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = `/private/companies/${id}`;

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            data: {},
          })
          .then((values) => resolve(values.data))
          .catch((err) => {
            setRequestErrorDelete(true);
            const errMessage = validateError(err?.response?.status, 'deleteAddress');
            reject(errMessage);
          })
          .finally(() => {
            setRequestLoadingDelete(false);
          });
      });
    }
  };

  return { requestErrorDelete, requestLoadingDelete, fetchDeleteAddresses };
};

export const useGetCep = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingCep, setRequestLoadingCep] = useState(false);
  const [requestErrorCep, setRequestErrorCep] = useState(false);
  const [requestDataCep, setRequestDataCep] = useState({});

  const handleError = (obj, reject, resolve) => {
    if (!obj.data.erro) {
      setRequestDataCep(obj);
      resolve(obj)
    } else {
      setRequestErrorCep(true);
      const errMessage = validateError(obj.data);
      reject(errMessage);
    }
  };

  const fetchGetCep = (cep) => {
    if (!requestLoadingCep) {
      setRequestErrorCep(false);
      setRequestLoadingCep(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`, {
            headers: {},
            cancelToken,
          })

          .then((values) => handleError(values, reject, resolve))
          .catch((err) => {
            setRequestErrorCep(true);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingCep(false));
      });
    }
  };

  return {
    requestErrorCep,
    requestLoadingCep,
    fetchGetCep,
    requestDataCep,
    setRequestDataCep,
    setRequestErrorCep,
  };
};

export const useGetCep2 = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingCep2, setRequestLoadingCep2] = useState(false);
  const [requestErrorCep2, setRequestErrorCep2] = useState(false);
  const [requestDataCep2, setRequestDataCep2] = useState({});

  const handleError = (obj, reject) => {
    if (!obj.data.erro) {
      setRequestDataCep2(obj);
    } else {
      setRequestErrorCep2(true);
      const errMessage = validateError(obj.data);
      reject(errMessage);
    }
  };

  const fetchGetCep2 = (cep) => {
    if (!requestLoadingCep2) {
      setRequestErrorCep2(false);
      setRequestLoadingCep2(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`, {
            headers: {},
            cancelToken,
          })

          .then((values) => handleError(values, reject))
          .catch((err) => {
            setRequestErrorCep2(true);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoadingCep2(false));
      });
    }
  };

  return {
    requestErrorCep2,
    requestLoadingCep2,
    fetchGetCep2,
    requestDataCep2,
    setRequestDataCep2,
    setRequestErrorCep2,
  };
};

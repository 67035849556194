import { createTheme } from "@mui/material/styles";

export const DESKTOP_THEME = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontFamily: "Titillium Web, sans-serif",
            fontSize: "1.2em",
            padding: "5px 5px 5px 10px",
            height: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Titillium Web, sans-serif",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "35px",
          width: "35px",
          marginRight: "15px",
          color: "#273682",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0",
          span: {
            fontFamily: "Titillium Web, sans-serif",
            lineHeight: "1em",
            fontWeight: "600",
          },
        },
      },
    },
  },
});

export const MOBILE_THEME = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontFamily: "Titillium Web, sans-serif",
            fontSize: "1.2em",
            padding: "5px 5px 5px 10px",
            height: "40px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Titillium Web, sans-serif",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: "35px",
          width: "35px",
          marginRight: "15px",
          color: "#273682",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: "90%",
          margin: "0",
          span: {
            fontFamily: "Titillium Web, sans-serif",
            lineHeight: "1em",
            fontWeight: "600",
          },
        },
      },
    },
  },
});

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../contexts/Products";
import { useValidateErrorResponse } from "./useValidateErrorResponse";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useProducts = () => {
  const [dispatchProductsContext] = useContext(ProductsContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestData, setRequestData] = useState({});

  const { id, name, product_type, description, telco, currency, price_unit } =
    requestData;

  useEffect(() => {
    if (
      id &&
      name &&
      product_type &&
      description &&
      telco &&
      currency &&
      price_unit
    ) {
      dispatchProductsContext({
        type: "setProducts",
        id,
        name,
        product_type,
        description,
        telco,
        currency,
        price_unit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    product_type,
    description,
    telco,
    currency,
    price_unit,
    dispatchProductsContext,
  ]);

  const fetchProducts = () => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/public/products`, {
            headers: {},
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => {
            setRequestData(values)
            resolve(values)
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  const fetchUserProducts = (id = null) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = "/private/products"

      if(id !== null){
        request += `?companyId=${id}`
      }

      const headers = createHeaders();

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => {
            setRequestData(values)
            resolve(values)
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchProducts, fetchUserProducts, requestData };
};

export const useSpecificProduct = () => {
  const [, dispatchProductsContext] = useContext(ProductsContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestData, setRequestData] = useState({});

  const { id, name, product_type, description, telco, currency, price_unit } =
    requestData;

  useEffect(() => {
    if (
      id &&
      name &&
      product_type &&
      description &&
      telco &&
      currency &&
      price_unit
    ) {
      dispatchProductsContext({
        type: "setProducts",
        id,
        name,
        product_type,
        description,
        telco,
        currency,
        price_unit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    name,
    product_type,
    description,
    telco,
    currency,
    price_unit,
    dispatchProductsContext,
  ]);

  const fetchSpecificProduct = (id) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/public/products/${id}`, {
            headers: {},
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  const fetchSpecificUserProduct = (id, companyId = null) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = `/private/products/${id}`

      if(companyId !== null){
        request += `?companyId=${companyId}`
      }

      const headers = createHeaders();

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchSpecificProduct, fetchSpecificUserProduct, requestData };
};

export const useProductsQuantity = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestData, setRequestData] = useState({});

  const fetchProductsQuantity = (name) => {
    if (!requestLoading && name) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/public/products/quantity/${name}`,
            {
              headers: {},
              cancelToken,
            }
          )
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchProductsQuantity, requestData };
};

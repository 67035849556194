import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts/Cart";
import { loginAuthBodyDefault } from "./APIConstants";
import { useValidateErrorResponse } from "./useValidateErrorResponse";

const { CancelToken } = axios;

const loadItem = (item) => {
  try {
    const serializedItem = sessionStorage.getItem(item);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

const createHeaders = () => {
  const token = loadItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

export const useCart = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchCart = ({
    productId,
    productType,
    quantity,
    companyId = null,
  }) => {
    if (!requestLoading && productId && productType && quantity) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        let body = {
          ...loginAuthBodyDefault,
          productId,
          productType,
          quantity,
        };

        if (companyId !== null) {
          body.companyId = companyId;
        }

        axios
          .post(`${process.env.REACT_APP_API_URL}/private/shoppings`, body, {
            headers,
            cancelToken,
          })
          .then((values) => {
            setRequestData(values)
            resolve()
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchCart };
};

export const useGetCart = () => {
  const [dispatchCartContext] = useContext(CartContext);
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestData, setRequestData] = useState({});

  const headers = createHeaders();

  const { id, product_id, company_id, quantity, product } = requestData;

  useEffect(() => {
    if (id && product_id && company_id && quantity && product) {
      dispatchCartContext({
        type: "setCart",
        id,
        product_id,
        company_id,
        quantity,
        product,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, product_id, company_id, quantity, product]);

  const fetchGetCart = (id = null) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = "/private/shoppings";

      if (id !== null) {
        request += `?companyId=${id}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            cancelToken,
          })
          // .then(({ data }) => normalizeAuthAndUserData(data))
          .then((values) => {
            setRequestData(values);
            resolve(values);
          })
          .catch((err) => {
            setRequestError(true);
            console.log(err?.response?.status);
            const errMessage = validateError(err?.response?.status);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchGetCart, requestData };
};

export const useDeleteCart = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoadingDelete, setRequestLoadingDelete] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchDeleteCart = (id, companyId = null) => {
    if (!requestLoadingDelete && id) {
      setRequestError(false);
      setRequestLoadingDelete(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      let request = `/private/shoppings/${id}`;

      if (companyId !== null) {
        request += `?companyId=${companyId}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.REACT_APP_API_URL}${request}`, {
            headers,
            data: {},
          })
          .then((values) => {
            setRequestData(values);
            resolve(values);
          })
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => {
            setRequestLoadingDelete(false);
          });
      });
    }
  };

  return { requestError, requestLoadingDelete, fetchDeleteCart };
};

export const useUpdateCart = () => {
  const { validateError } = useValidateErrorResponse();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const headers = createHeaders();

  const fetchUpdateCart = ({
    itemId,
    productId,
    productType,
    quantity,
    companyId = null,
  }) => {
    if (!requestLoading && itemId && productId && productType && quantity) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        let body = {
          ...loginAuthBodyDefault,
          itemId,
          productId,
          productType,
          quantity,
        };

        if (companyId !== null) {
          body.companyId = companyId;
        }

        axios
          .put(`${process.env.REACT_APP_API_URL}/private/shoppings`, body, {
            headers,
            cancelToken,
          })
          .then((values) =>{
            setRequestData(values)
            resolve(values)
          } )
          .catch((err) => {
            setRequestError(true);
            const errMessage = validateError(err?.response?.data);
            reject(errMessage);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestError, requestLoading, fetchUpdateCart };
};

import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Header } from "./components/Header";
import AllRoutes from "./Routes";
import { DESKTOP_THEME, MOBILE_THEME } from "./utils/themeConstants";
import WrapperSnackBar from "./WrapperSnackBar";
import { UNSAFE_NavigationContext } from "react-router-dom";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const modeContext = createContext("");

const App = () => {
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const modeMode = useMemo(
    () => mode,
    [mode]
  );

  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  useEffect(() => {
    const unlisten = navigation.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [navigation]);

  const themeDarkLight = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  const isMobile = useMediaQuery("(max-width:768px)");

  const themeInUse = isMobile ? MOBILE_THEME : DESKTOP_THEME;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <modeContext.Provider value={modeMode}>
      <WrapperSnackBar colorMode={mode}>
        <ThemeProvider theme={themeDarkLight}>
          <Header />
          <ThemeProvider
            theme={(theme) => createTheme({
                ...theme,
                ...themeInUse,
              })
            }
          >
            <CssBaseline />
            <AllRoutes />
          </ThemeProvider>
        </ThemeProvider>
      </WrapperSnackBar>
      </modeContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default App;

import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "400px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  rootDesktop: {
    width: "500px",
    height: "400px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  paragraph: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLink: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  paragraphDesktop: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLinkForgot: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'right'
  },
  inputsContainer: {
    height: "160px",
    display: "flex",
    marginBottom: "25px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: 'right'
  },
  buttonAndTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100px",
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#273682",
    filter: "brightness(120%)",
  },
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
  fontFamily: 'password'
});

const StyledLocalPostOfficeRoundedIcon = styled(LocalPostOfficeRoundedIcon, {
  name: "StyledLocalPostOfficeRoundedIcon",
})({
  height: "35px",
  width: "35px",
  marginRight: "15px",
  color: "#273682",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledVisibilityOffIcon = styled(VisibilityOffIcon, {
  name: "StyledLocalPostOfficeRoundedIcon",
})({
  height: "35px",
  width: "35px",
  marginRight: "15px",
  color: "#273682",
  "&:hover": {
    cursor: "pointer",
  },
});

const formSchema = yup.object().shape({
  username: yup
    .string()
    .required("E-mail obrigatório")
    .email("E-mail inválido"),
  password: yup.string().required("Senha obrigatória"),
});

const StyledTypography = styled(Typography, {
  name: "StyledCheckbox",
})({
  marginLeft: "60px",
});

export const LoginForm = ({ email, password, handleLogin, setPassword, setEmail, setForgotPassword }) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [canType, setCanType] = useState(false)

  const isMobile = useMediaQuery("(max-width:768px)");

  const handleChangePassword = (e) => {
    setFocus('password')
    setPassword(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setFocus('username')
    setEmail(e.target.value)
  }

  const handleEnterClick = (event, inputType) => {
    if (event.keyCode === 13) {
      if(inputType === 'password'){
        setFocus('username')
        setFocus('password')
      }else{
        setFocus('password')
        setFocus('username')
      }

      handleSubmit(handleLogin)
    }
  };

  const classes = useStyles();

  return (
    <div className={isMobile ? classes.root : classes.rootDesktop}>
      <h2 className={classes.sessionTitle}>Iniciar Sessão</h2>
      {isMobile ? (
        <form onSubmit={handleSubmit(handleLogin)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLocalPostOfficeRoundedIcon />
                  <StyledTextField
                    id="outlined-email-input"
                    label="Email"
                    type="text"
                    value={email.value}
                    onKeyDown={(e) => handleEnterClick(e, 'email')}
                    {...register("username")}
                    defaultValue="example@gmail.com"
                    error={errors.username ? true : false}
                    autoComplete="current-email"
                    onChange={(e) => handleChangeEmail(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.username?.message}
                </StyledTypography>
              </div>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledVisibilityOffIcon />
                  <StyledTextField
                    id="outlined-password-input"
                    label="Senha"
                    type="password"
                    value={password.value}
                    onKeyDown={(e) => handleEnterClick(e, 'password')}
                    {...register("password")}
                    error={errors.password ? true : false}
                    autoComplete="current-password"
                    onChange={(e) => handleChangePassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.password?.message}
                </StyledTypography>
              </div>
              <div className={classes.linkContainer}>
              <Link onClick={() => setForgotPassword(true)} to="" className={classes.paragraphLinkForgot}>
                  Esqueci minha senha
                </Link>
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Entrar
              </StyledButton>
              <h3
                className={
                  isMobile ? classes.paragraph : classes.paragraphDesktop
                }
              >
                Ainda não tem uma conta?{" "}
                <Link to="/register" className={classes.paragraphLink}>
                  Registre-se.
                </Link>
              </h3>
            </div>
          </>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handleLogin)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLocalPostOfficeRoundedIcon />
                  <StyledTextFieldDesktop
                    id="outlined-email-input-test2"
                    label="Email"
                    type="text"
                    onKeyDown={(e) => handleEnterClick(e, 'email')}
                    value={email.value}
                    {...register("username")}
                    error={errors.username ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangeEmail(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.username?.message}
                </StyledTypography>
              </div>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledVisibilityOffIcon />
                  <StyledTextFieldDesktop
                    id="outlined-password-input-test"
                    label="Senha"
                    type="password"
                    onKeyDown={(e) => handleEnterClick(e, 'password')}
                    value={password.value}
                    {...register("password")}
                    error={errors.password ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangePassword(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.password?.message}
                </StyledTypography>
              </div>
              <div className={classes.linkContainer}>
              <Link onClick={() => setForgotPassword(true)} to="" className={classes.paragraphLinkForgot}>
                  Esqueci minha senha
                </Link>
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Entrar
              </StyledButton>
              <h3
                className={
                  isMobile ? classes.paragraph : classes.paragraphDesktop
                }
              >
                Ainda não tem uma conta?{" "}
                <Link to="/register" className={classes.paragraphLink}>
                  Registre-se.
                </Link>
              </h3>
            </div>
          </>
        </form>
      )}
    </div>
  );
};

import { LoginForm } from "../../../components/LoginForm";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useContext, useEffect } from "react";
import { useLogin } from "../../../API/Hooks/Auth";
import { useSnackbar } from "notistack";
import { UserContext } from "../../../contexts/User";
import { ForgotPasswordForm } from "../../../components/ForgotPasswordForm";
import { useResetPassword } from "../../../API/Hooks/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
  rootMobile: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#EEF5ED",
    height: "calc(100vh - 60px)",
  },
  rootDesktop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 50px)",
    backgroundColor: "#EEF5ED",
  },
});

const ForgotPassword = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("token")

  console.log(searchParams.get("token"))

  const classes = useStyles();

  const navigate = useNavigate();

  const { fetchResetPassword, requestError, requestLoading } = useResetPassword();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [changing, setChanging] = useState(false);

  const handleChange = (data) => {
    delete data.confirmPassword
    data.password = password;
    data.token = searchParams.get("token")
    fetchResetPassword(data);
    console.log(data)
    setChanging(true);
  };

  useEffect(() => {
    const showMessage = () => {
      if (
        !requestError &&
        !requestLoading &&
        changing &&
        confirmPassword !== "" &&
        password !== ""
      ) {
        navigate("/")
        enqueueSnackbar(`Senha Alterada com sucesso!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setChanging(false);
      }
    };
    showMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestError, changing, requestLoading, confirmPassword, password]);

  return (
    <>
      <div className={isMobile ? classes.rootMobile : classes.rootDesktop}>
        <ForgotPasswordForm
          password={{
            id: "password",
            value: password,
          }}
          confirmPassword={{
            id: "confirmPassword",
            value: confirmPassword,
          }}
          handleChange={handleChange}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
        />
      </div>
    </>
  );
};

export default ForgotPassword;

import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { loadItem, saveItem, removeItem } from "../utils/localStorage";

const userTheme = loadItem("userTheme");

const currentTheme = userTheme || "light";

const reducer = (state, action) => {
  switch (action.type) {
    case "setUserTheme":
      saveItem(action.userTheme, "userTheme");
      return action.userTheme;
    case "clear":
      removeItem("userTheme");
      return "light";
    default:
      throw new Error("Unexpected action");
  }
};

export const ThemeContext = React.createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, currentTheme);
  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "430px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  rootDesktop: {
    width: "500px",
    height: "400px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  paragraph: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLink: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  paragraphDesktop: {
    color: "black",
    fontSize: "1em",
    fontWeight: "600",
  },
  paragraphLinkForgot: {
    color: "#273682",
    textDecoration: "none",
    "&:hover": {
      filter: "brightness(120%)",
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'right'
  },
  inputsContainer: {
    height: "200px",
    display: "flex",
    marginBottom: "10px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: 'center'
  },
  buttonAndTextContainer: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: '0 50px 0 50px',
    justifyContent: "space-around",
  },
  buttonAndTextContainerMobile: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    padding: '0 10px 0 10px',
    justifyContent: "space-around",
  },
  IconAndInputContainer: {
    display: "flex",
    alignItems: "center",
  },
  instructions: {
      textAlign: 'justify',
      fontSize: '1.2em',
      fontWeight: '600',
      opacity: '70%'
  },
  instructionsMobile: {
    textAlign: 'justify',
    fontSize: '1.2em',
    fontWeight: '600',
    opacity: '70%',
    padding: '0 20px 0 20px'
}
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "60px",
  width: "150px",
  backgroundColor: "#273682",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  border: "1px solid #273682",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682"
  },
});

const StyledButtonCancel = styled(Button, {
    name: "StyledButtonCart",
  })({
    height: "60px",
    width: "150px",
    backgroundColor: "#273682",
    color: "white",
    textTransform: "unset",
    fontWeight: "bold",
    fontSize: "1.5em",
    border: "1px solid #273682",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "white",
      color: "#273682"
    },
  });

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "300px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  borderRadius: "20px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
});

const StyledTextFieldDesktop = styled(TextField, {
  name: "StyledTextField",
})({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderStyle: "none",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
  },
  width: "450px",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  borderRadius: "20px",
  fontFamily: 'password'
});

const StyledLocalPostOfficeRoundedIcon = styled(LocalPostOfficeRoundedIcon, {
  name: "StyledLocalPostOfficeRoundedIcon",
})({
  height: "35px",
  width: "35px",
  marginRight: "15px",
  color: "#273682",
  "&:hover": {
    cursor: "pointer",
  },
});

const formSchema = yup.object().shape({
  user: yup
    .string()
    .required("Digite o email")
    .email("E-mail inválido"),
});

const StyledTypography = styled(Typography, {
  name: "StyledCheckbox",
})({
  marginLeft: "60px",
});

export const SentResetToEmail = ({ emailReset, handleReset, setEmailReset, handleCancel }) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const isMobile = useMediaQuery("(max-width:768px)");

  const handleChangeEmailReset = (e) => {
    setFocus('user')
    setEmailReset(e.target.value)
  }

  const classes = useStyles();

  return (
    <div className={isMobile ? classes.root : classes.rootDesktop}>
      <h2 className={classes.sessionTitle}>Recuperação de Senha</h2>
      {isMobile ? (
        <form onSubmit={handleSubmit(handleReset)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
            <h2 className={classes.instructionsMobile}>Informe o e-mail de cadastro e enviaremos as instruções para a reinicialização de sua senha.</h2>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLocalPostOfficeRoundedIcon />
                  <StyledTextField
                    id="outlined-email-reset-input"
                    label="Email"
                    type="text"
                    value={emailReset.value}
                    {...register("user")}
                    error={errors.user ? true : false}
                    autoComplete="current-email"
                    onChange={(e) => handleChangeEmailReset(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.user?.message}
                </StyledTypography>
              </div>
            </div>
            <div className={classes.buttonAndTextContainerMobile}>
              <StyledButton type="submit" variant="contained">
                Entrar
              </StyledButton>
              <StyledButtonCancel onClick={() => handleCancel()} variant="contained">
                Cancelar
              </StyledButtonCancel>
            </div>
          </>
        </form>
      ) : (
        <form onSubmit={handleSubmit(handleReset)}>
          <>
          <input type='hidden' value='something'/>
            <div className={classes.inputsContainer}>
                <h2 className={classes.instructions}>Informe o e-mail de cadastro e enviaremos as instruções para a reinicialização de sua senha.</h2>
              <div className={classes.errorAndInputContainer}>
                <div className={classes.IconAndInputContainer}>
                  <StyledLocalPostOfficeRoundedIcon />
                  <StyledTextFieldDesktop
                    id="outlined-email-reset-input-test2"
                    label="Email"
                    type="text"
                    value={emailReset.value}
                    {...register("user")}
                    error={errors.user ? true : false}
                    autoComplete="off"
                    onChange={(e) => handleChangeEmailReset(e)}
                  />
                </div>
                <StyledTypography variant="inherit" color="#d32f2f">
                  {errors.user?.message}
                </StyledTypography>
              </div>
            </div>
            <div className={classes.buttonAndTextContainer}>
              <StyledButton type="submit" variant="contained">
                Entrar
              </StyledButton>
              <StyledButtonCancel onClick={() => handleCancel()} variant="contained">
                Cancelar
              </StyledButtonCancel>
            </div>
          </>
        </form>
      )}
    </div>
  );
};

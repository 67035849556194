import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setAddresses":
      return {
        _id: action._id,
        zipcode: action.zipcode,
        street: action.street,
        complement: action.complement,
        number: action.number,
        state: action.state,
        city: action.city,
        district: action.district,
        type: action.type,
        company_id: action.company_id,
        __v: action.__v,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const AddressesContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <AddressesContext.Provider value={context}>
      {children}
    </AddressesContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;

import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useForgotPassword } from "../../../API/Hooks";
import { useLogin } from "../../../API/Hooks/Auth";
import { LoginForm } from "../../../components/LoginForm";
import { SentResetToEmail } from "../../../components/SentResetToEmail";
import { LoginMessageContext } from "../../../contexts/LoginMessage";
import { loadItem } from "../../../utils/localStorage";

const useStyles = makeStyles({
  rootMobile: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#EEF5ED",
    height: "calc(100vh - 60px)",
  },
  rootDesktop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 50px)",
    backgroundColor: "#EEF5ED",
  },
});

const Login = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const { fetchLogin, requestError, requestLoading } = useLogin();

  const [login, dispatchLoginMessageContext] = useContext(LoginMessageContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { fetchForgotPassword, requestErrorReset, requestLoadingReset } =
    useForgotPassword();

  const [emailReset, setEmailReset] = useState("");

  const [forgotPassword, setForgotPassword] = useState(false);

  const [logging, setLogging] = useState(false);

  const [forgot, setForgot] = useState(false);

  const handleLogin = (data) => {
    data.username = email;
    data.password = password;
    fetchLogin(data);
    setLogging(true);
  };

  const handleReset = (data) => {
    if (!requestLoadingReset) {
      fetchForgotPassword(data);
    }
    setForgot(true);
  };

  const handleCancel = () => {
    setForgotPassword(false);
    setEmailReset("");
  };

  useEffect(() => {
    const showMessage = () => {
      if (
        !requestError &&
        !requestLoading &&
        logging &&
        email !== "" &&
        password !== ""
      ) {
        dispatchLoginMessageContext({
          type: "setLoginMessage",
          canShow: true
        });
        setLogging(false);
      }
    };
    showMessage();

    const showMessageAnother = () => {
      if (
        !requestErrorReset &&
        !requestLoadingReset &&
        forgot &&
        emailReset !== ""
      ) {
        enqueueSnackbar(`Link de recuperação de senha enviado!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setForgot(false);
      }
    };
    showMessageAnother();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestError,
    logging,
    requestLoading,
    email,
    password,
    forgot,
    forgotPassword,
    requestErrorReset,
    requestLoadingReset,
  ]);

  if (loadItem("token")) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className={isMobile ? classes.rootMobile : classes.rootDesktop}>
        {forgotPassword ? (
          <SentResetToEmail
            emailReset={{
              id: "emailReset",
              value: emailReset,
            }}
            handleReset={handleReset}
            setEmailReset={setEmailReset}
            handleCancel={handleCancel}
          />
        ) : (
          <LoginForm
            email={{
              id: "email",
              value: email,
            }}
            password={{
              id: "password",
              value: password,
            }}
            handleLogin={handleLogin}
            setPassword={setPassword}
            setEmail={setEmail}
            setForgotPassword={setForgotPassword}
          />
        )}
      </div>
    </>
  );
};

export default Login;

import axios from "axios";

const event401 = new Event("error-401");

const validateError = (err) => {
  if (axios.isCancel(err)) {
    return `cancelled`;
  }

  const errMessage = { listMessages: [], listExceptions: [] };

  const responseErrors = err && err.response && err.response.data;

  if (responseErrors && responseErrors === "Authentication Error") {
    errMessage.listMessages.push("phrases.401");
    window.dispatchEvent(event401);

    return errMessage;
  }

  if (responseErrors && responseErrors.listExceptions) {
    const { listExceptions } = responseErrors;
    const exceptionsMessages = [];
    listExceptions.map((lineError) => {
      const errorBody = { ...lineError };
      delete errorBody.errorMessage;
      return exceptionsMessages.push();
    });
    errMessage.listExceptions = exceptionsMessages;
  }

  if (responseErrors && responseErrors.errorDescription) {
    const { errorDescription, errorBody } = responseErrors;

    if (
      errorDescription === "INVALID_TOKEN" ||
      errorDescription === "token_required" ||
      errorDescription === "decoded_token_error" ||
      errorDescription === "login_expired" ||
      errorDescription === "inactive_user" ||
      errorDescription === "error_at_new_login_expiration" ||
      errorDescription === "invalidToken"
    ) {
      errMessage.listMessages.push("phrases.401");
      window.dispatchEvent(event401);

      return errMessage;
    }

    errMessage.listMessages.push();
    return errMessage;
  }

  errMessage.listMessages.push("phrases.errorNotFound");
  return errMessage;
};

export default validateError;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import UserProvider from "./contexts/User";
import AuthProvider from "./contexts/Auth";
import ThemeProvider from "./contexts/Theme";
import ProductsProvider from "./contexts/Products";
import CartProvider from "./contexts/Cart";
import CartLengthProvider from "./contexts/CartLength";
import AddressesProvider from "./contexts/Addresses";
import OrdersProvider from "./contexts/Orders";
import FreightProvider from "./contexts/Freight";
import LoginMessageProvider from "./contexts/LoginMessage";
import CompanyProvider from "./contexts/Company";
import SelectedTariffsProvider from "./contexts/SelectedTariffs";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <CompanyProvider>
          <SelectedTariffsProvider>
            <ThemeProvider>
              <ProductsProvider>
                <CartProvider>
                  <CartLengthProvider>
                    <AddressesProvider>
                      <OrdersProvider>
                        <FreightProvider>
                          <LoginMessageProvider>
                            <BrowserRouter>
                              <App />
                            </BrowserRouter>
                          </LoginMessageProvider>
                        </FreightProvider>
                      </OrdersProvider>
                    </AddressesProvider>
                  </CartLengthProvider>
                </CartProvider>
              </ProductsProvider>
            </ThemeProvider>
          </SelectedTariffsProvider>
        </CompanyProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

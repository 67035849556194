import React, { useContext, useEffect, useState } from "react";
import LyraM2M from "../../assets/img/newLogoHeader.png";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonIcon from "@mui/icons-material/Person";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { loadItem } from "../../utils/localStorage";
import { useLogout } from "../../API/Hooks/Auth";
import { useGetCart } from "../../API/Hooks/Cart";
import { CartLengthContext } from "../../contexts/CartLength";
import { UserContext } from "../../contexts/User";
import { AuthContext } from "../../contexts/Auth";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../../App";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Autocomplete, TextField } from "@mui/material";
import { normalizecompaniesList } from "../../API/Hooks/Normalizers";
import { CompanyContext } from "../../contexts/Company";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  rootHeaderMobile: {
    height: "60px",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#273682",
    display: "flex",
    justifyContent: "space-between",
  },
  rootHeaderDesktop: {
    height: "50px",
    backgroundColor: "#273682",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  rootDesktopDark: {
    height: "50px",
    width: "100%",
    display: "flex",
    backgroundColor: "#0B0B0D",
    justifyContent: "space-between",
  },
  leftContainer: {
    display: "flex",
    height: "100%",
    width: "250px",
    justifyContent: "center",
    alignItems: "center",
  },
  leftContainerDesktop: {
    display: "flex",
    height: "100%",
    width: "150px",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainer: {
    height: "100%",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainerDesktopLogged: {
    height: "100%",
    width: "350px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  rightContainerDesktop: {
    height: "100%",
    width: "180px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  imageSize: {
    height: "140px",
    width: "170px",
    marginTop: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageSizeDesktop: {
    height: "120px",
    marginTop: "2px",
    width: "150px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loggedUsername: {
    color: "#EEF5ED",
    margin: "5px",
    fontWeight: "normal",
    textTransform: "uppercase",
    fontSize: "1em",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loggedUsernameDark: {
    color: "white",
    margin: "5px",
    fontWeight: "normal",
    textTransform: "uppercase",
    fontSize: "1em",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  userContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rootAsideOpen: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "80%",
    height: "calc(100vh - 60px)",
    transform: "translateX(0)",
    backgroundColor: "#273682",
    transition: "transform 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    zIndex: 2,
  },
  rootAsideClosed: {
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "80%",
    height: "calc(100vh - 60px)",
    transform: "translateX(-100%)",
    backgroundColor: "#273682",
    transition: "transform 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    zIndex: 2,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "50px",
  },
  cartIconContainer: {
    height: "50px",
    width: "35px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartLength: {
    height: 23,
    color: "#273682",
    fontSize: "0.9em",
    fontWeight: 600,
    paddingRight: "1px",
  },
  cartLengthDark: {
    height: 23,
    color: "#2A2438",
    fontSize: "0.9em",
    fontWeight: "600",
  },
  cartLengthBackground: {
    height: "18px",
    width: "18px",
    backgroundColor: "#EEF5ED",
    position: "absolute",
    borderRadius: "10px",
    top: 5,
    right: -3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartLengthBackgroundDark: {
    height: "18px",
    width: "18px",
    backgroundColor: "white",
    position: "absolute",
    borderRadius: "10px",
    top: 5,
    right: -3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartLengthBackgroundMobile: {
    height: 23,
    width: 23,
    backgroundColor: "#EEF5ED",
    position: "absolute",
    borderRadius: 23,
    top: 3,
    right: -3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartLengthMobile: {
    height: "26px",
    color: "#273682",
    fontSize: "1em",
    fontWeight: 600,
  },
  cartIconContainerMobile: {
    height: "60px",
    width: "50px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  store: {
    color: "#EEF5ED",
    margin: "5px",
    fontWeight: 600,
    fontSize: "1.5em",
  },
  select: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0!important",
      backgroundColor: "#EEF5ED!important",
    },
  },
});

const StyledCartIcon = styled(ShoppingCartOutlinedIcon, {
  name: "StyledMenuIcon",
})({
  height: "45px",
  width: "45px",
  color: "#EEF5ED",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledPersonIcon = styled(PersonIcon, {
  name: "StyledMenuIcon",
})({
  height: "25px",
  width: "25px",
  color: "#EEF5ED",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledHomeIcon = styled(HomeIcon, {
  name: "StyledMenuIcon",
})({
  height: "37px",
  width: "37px",
  color: "#EEF5ED",
  marginLeft: "24px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledBrightness5Icon = styled(Brightness5Icon, {
  name: "StyledMenuIcon",
})({
  height: "25px",
  width: "25px",
  color: "white",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledDarkIcon = styled(Brightness7Icon, {
  name: "StyledMenuIcon",
})({
  height: "25px",
  width: "25px",
  color: "#EEF5ED",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledExitToAppIcon = styled(ExitToAppIcon, {
  name: "StyledMenuIcon",
})({
  height: "25px",
  width: "25px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledCartDesktopIcon = styled(ShoppingCartOutlinedIcon, {
  name: "StyledMenuIcon",
})({
  height: "25px",
  width: "25px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledListAltIcon = styled(ListAltIcon, {
  name: "StyledListAltIcon",
})({
  height: "25px",
  width: "25px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledHomeIconDesktop = styled(HomeIcon, {
  name: "StyledHomeIcon",
})({
  height: "27px",
  width: "27px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledLink = styled(Link, {
  name: "StyledLink",
})({
  fontSize: "1.5em",
  color: "#EEF5ED",
  textDecoration: "None",
  marginLeft: "30px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledEnterToAppIcon = styled(ExitToAppIcon, {
  name: "StyledHomeIcon",
})({
  height: "35px",
  width: "35px",
  color: "#EEF5ED",
  marginLeft: "24px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledListAltIconMobile = styled(ListAltIcon, {
  name: "StyledHomeIcon",
})({
  height: "35px",
  width: "35px",
  color: "#EEF5ED",
  marginLeft: "24px",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledPersonIconMobile = styled(PersonIcon, {
  name: "StyledPersonIconMobile",
})({
  height: "35px",
  width: "35px",
  color: "#EEF5ED",
  marginLeft: "24px",
  "&:hover": {
    cursor: "pointer",
  },
});

export const Header = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const { fetchLogout } = useLogout();

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const { enqueueSnackbar } = useSnackbar();

  const { fetchGetCart, requestData, requestLoading } = useGetCart();

  const [products, setProducts] = useState({});

  const handleProducts = (id = null) => {
    return new Promise((resolve, reject) => {
      if (id !== null) {
        fetchGetCart(company.company_id);
      } else {
        fetchGetCart();
      }
      setProducts(requestData);
      resolve([requestData.data.length, true]);
    });
  };

  const [cart, dispatchCartLengthContext] = useContext(CartLengthContext);

  const isLogged = loadItem("token");

  const [userLoggedName, setUserLoggedName] = useState("");

  const [token] = useContext(AuthContext);

  const { companies } = user;

  const firstName = user?.userName;
  const lastName = user?.userLastName;
  const fullName = `${firstName} ${lastName}`;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:900px)");

  const isDesktopLogged = isLogged && !isMobile;

  const isMobileLogged = isLogged && isMobile;

  const [autoCompleteInput, setAutoCompleteInput] = useState("EMPRESA");

  const handleLoginAction = () => {
    navigate("/login");
    setOpen(false);
  };

  const handleLogoutAction = async () => {
    await fetchLogout();
    setOpen(false);
    window.location.reload();
  };

  const handleHomeAction = () => {
    navigate("/");
    setOpen(false);
  };

  const handleCartAction = () => {
    if (user?.systemRole !== 4 || (user?.systemRole === 4 && company?.company_id !== undefined)) {
      navigate("/cart");
      setOpen(false);
    } else if (user?.systemRole === 4 && company?.company_id === undefined) {
      enqueueSnackbar("Escolha uma empresa antes de acessar o carrinho", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleOrdersAction = () => {
    if (user?.systemRole !== 4 || (user?.systemRole === 4 && company?.company_id !== undefined)) {
      navigate("/status");
      setOpen(false);
    } else if (user?.systemRole === 4 && company?.company_id === undefined) {
      enqueueSnackbar("Escolha uma empresa antes de acessar os pedidos", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleUserAction = () => {
    if (user?.systemRole !== 4 || (user?.systemRole === 4 && company?.company_id !== undefined)) {
      navigate("/user");
      setOpen(false);
    } else if (user?.systemRole === 4 && company?.company_id === undefined) {
      enqueueSnackbar("Escolha uma empresa antes de acessar os dados do usuário", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const [closed, setClosed] = useState(false);

  const StyledTextField = styled(TextField, {
    name: "StyledTextField",
  })({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderStyle: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#273682",
    },
  });

  const handleCompanyChange = (newCompany) => {
    if (newCompany?.id) {
      setAutoCompleteInput(newCompany);
      dispatchCompanyContext({
        type: "setComercialCompany",
        company_id: newCompany.id,
        companyName: newCompany.label,
        companySubsidiaryId: newCompany?.companySubsidiaryId || null,
        companyChanged: true,
      });
    }
  };

  useEffect(() => {
    const loadCartLength = async () => {
      if (user.systemRole !== null && token.token) {
        if (Object.keys(products).length === 0 && user.systemRole !== 4) {
          const result = await handleProducts();
          dispatchCartLengthContext({
            type: "setCartLength",
            length: result[0],
          });
        } else if (
          user.systemRole === 4 &&
          company.companyChanged === true &&
          company?.company_id !== undefined
        ) {
          const result = await fetchGetCart(company.company_id);
          if (!requestLoading) {
            dispatchCartLengthContext({
              type: "setCartLength",
              length: result.data.length,
            });
          }
        } else if (
          Object.keys(products).length === 0 &&
          user.systemRole === 4 &&
          company?.company_id !== undefined
        ) {
          const result = await handleProducts(company.company_id);
          dispatchCartLengthContext({
            type: "setCartLength",
            length: result,
          });
        }
      }
    };
    loadCartLength();

    const handleEraseAutoCompleteInput = () => {
      if (
        autoCompleteInput === null &&
        company?.company_id !== undefined &&
        closed === true
      ) {
        setAutoCompleteInput({
          id: company.company_id,
          label: company.companyName,
          subsidiaryId: company?.companySubsidiaryId || null
        });
        setClosed(false);
      } else if (
        autoCompleteInput === null &&
        company?.company_id === undefined &&
        closed === true
      ) {
        console.log("teste");
        setAutoCompleteInput("EMPRESA");
        setClosed(false);
      }
    };
    handleEraseAutoCompleteInput();

    if (
      user.systemRole === 4 &&
      !requestLoading &&
      company.companyChanged &&
      (requestData?.data?.length || requestData?.data?.length === 0)
    ) {
      dispatchCompanyContext({
        ...company,
        type: "setComercialCompany",
        companyChanged: false,
      });
    }

    const handleNameSizeDisplay = () => {
      if (fullName.split("").length >= 1 && fullName.split("").length <= 15) {
        setUserLoggedName(fullName);
      } else if (firstName.length > 15) {
        setUserLoggedName(firstName.split(" ")[0]);
      } else {
        setUserLoggedName(firstName);
      }
    };
    handleNameSizeDisplay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    products,
    requestData,
    cart,
    userLoggedName,
    user,
    token,
    fullName,
    firstName,
    company,
    requestLoading,
    autoCompleteInput,
  ]);

  return (
    <>
      <div
        className={
          isMobile
            ? classes.rootHeaderMobile
            : !isMobile && theme.palette.mode === "dark"
            ? classes.rootDesktopDark
            : classes.rootHeaderDesktop
        }
      >
        <div
          className={
            isMobile ? classes.leftContainer : classes.leftContainerDesktop
          }
        >
          {isMobile && (
            <MenuIcon
              style={
                !open
                  ? {
                      height: "55px",
                      width: "55px",
                      color: "#EEF5ED",
                      "&:hover": { cursor: "pointer" },
                      transform: "rotate(0deg)",
                      transition: "transform 0.4s ease-in-out",
                    }
                  : {
                      height: "55px",
                      width: "55px",
                      color: "#EEF5ED",
                      "&:hover": { cursor: "pointer" },
                      transform: "rotate(90deg)",
                      transition: "transform 0.4s ease-in-out",
                    }
              }
              onClick={() => setOpen(!open)}
            />
          )}
          <img
            onClick={() => handleHomeAction()}
            src={LyraM2M}
            className={isMobile ? classes.imageSize : classes.imageSizeDesktop}
            alt="logo_header"
          ></img>
        </div>
        {!isMobile && user.systemRole !== 4 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: isLogged ? 200 : 15,
            }}
          >
            <p className={classes.store}>Loja</p>
          </div>
        ) : user.systemRole === 4 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: isLogged ? 200 : 15,
            }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={normalizecompaniesList(companies)}
              sx={{ width: 300 }}
              value={autoCompleteInput}
              onClose={() => setClosed(true)}
              className={classes.select}
              onChange={(_, value) => handleCompanyChange(value)}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </div>
        ) : null}
        {isMobile ? (
          <div className={classes.rightContainer}>
            <div className={classes.cartIconContainerMobile}>
              {cart.length !== 0 && (
                <div className={classes.cartLengthBackgroundMobile}>
                  <span className={classes.cartLengthMobile}>
                    {cart.length}
                  </span>
                </div>
              )}
              <StyledCartIcon onClick={() => handleCartAction()} />
            </div>
          </div>
        ) : isDesktopLogged ? (
          <div className={classes.rightContainerDesktopLogged}>
            <StyledHomeIconDesktop
              style={
                theme.palette.mode === "dark"
                  ? { color: "white" }
                  : { color: "#EEF5ED" }
              }
              onClick={() => handleHomeAction()}
            />
            <StyledListAltIcon
              style={
                theme.palette.mode === "dark"
                  ? { color: "white" }
                  : { color: "#EEF5ED" }
              }
              onClick={() => handleOrdersAction()}
            />
            <div className={classes.cartIconContainer}>
              {cart.length !== 0 && (
                <div
                  className={
                    theme.palette.mode === "dark"
                      ? classes.cartLengthBackgroundDark
                      : classes.cartLengthBackground
                  }
                >
                  <span
                    className={
                      theme.palette.mode === "dark"
                        ? classes.cartLengthDark
                        : classes.cartLength
                    }
                  >
                    {cart.length}
                  </span>
                </div>
              )}

              <StyledCartDesktopIcon
                style={
                  theme.palette.mode === "dark"
                    ? { color: "white" }
                    : { color: "#EEF5ED" }
                }
                onClick={() => handleCartAction()}
              />
            </div>
            <div className={classes.userContainer}>
              <StyledPersonIcon
                style={
                  theme.palette.mode === "dark"
                    ? { color: "white" }
                    : { color: "#EEF5ED" }
                }
                onClick={() => handleUserAction()}
              />
              {theme.palette.mode === "light" ? (
                <p onClick={handleUserAction} className={classes.loggedUsername}>
                  {userLoggedName}
                </p>
              ) : (
                <p onClick={handleUserAction} className={classes.loggedUsernameDark}>
                  {userLoggedName}
                </p>
              )}
            </div>
            <StyledExitToAppIcon
              style={
                theme.palette.mode === "dark"
                  ? { color: "white" }
                  : { color: "#EEF5ED" }
              }
              onClick={() => handleLogoutAction()}
            />
          </div>
        ) : (
          <div className={classes.rightContainerDesktop}>
            <StyledHomeIconDesktop
              style={
                theme.palette.mode === "dark"
                  ? { color: "white" }
                  : { color: "#EEF5ED" }
              }
              onClick={() => handleHomeAction()}
            />
            <div className={classes.userContainer}>
              <StyledPersonIcon
                style={
                  theme.palette.mode === "dark"
                    ? { color: "white" }
                    : { color: "#EEF5ED" }
                }
                onClick={() => handleLoginAction()}
              />
              {theme.palette.mode === "light" ? (
                <Link to="/user" className={classes.loggedUsername}>
                  Fazer Login
                </Link>
              ) : (
                <Link to="/user" className={classes.loggedUsernameDark}>
                  Fazer Login
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
      {isMobileLogged ? (
        <div className={open ? classes.rootAsideOpen : classes.rootAsideClosed}>
          <div className={classes.iconContainer}>
            <StyledHomeIcon onClick={() => handleHomeAction()} />
            <StyledLink onClick={() => handleHomeAction()} to={"/"}>
              Loja
            </StyledLink>
          </div>
          <div className={classes.iconContainer}>
            <StyledListAltIconMobile onClick={() => handleOrdersAction()} />
            <StyledLink onClick={() => handleOrdersAction()} to="/status">
              Pedidos
            </StyledLink>
          </div>
          <div className={classes.iconContainer}>
            <StyledPersonIconMobile onClick={() => handleUserAction()} />
            <StyledLink onClick={() => handleUserAction()} to="/user">
              Meus Dados
            </StyledLink>
          </div>
          <div className={classes.iconContainer}>
            <StyledEnterToAppIcon onClick={() => handleLogoutAction()} />
            <StyledLink onClick={() => handleLogoutAction()} to="/">
              Sair
            </StyledLink>
          </div>
        </div>
      ) : (
        isMobile && (
          <div
            className={open ? classes.rootAsideOpen : classes.rootAsideClosed}
          >
            <div className={classes.iconContainer}>
              <StyledHomeIcon onClick={() => handleHomeAction()} />
              <StyledLink onClick={() => handleHomeAction()} to="/">
                Loja
              </StyledLink>
            </div>
            <div className={classes.iconContainer}>
              <StyledEnterToAppIcon onClick={() => handleLoginAction()} />
              <StyledLink onClick={() => handleLoginAction()} to="/login">
                Entrar
              </StyledLink>
            </div>
          </div>
        )
      )}
    </>
  );
};

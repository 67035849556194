import { useNavigate } from "react-router-dom";
import { StatusProduct } from "../../../components/StatusProduct";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LastOrderStatus } from "../../../components/LastOrderStatus";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Auth";
import { useGetOrder } from "../../../API/Hooks/Orders";
import { modeContext } from "../../../App";
import { UserContext } from "../../../contexts/User";
import { CompanyContext } from "../../../contexts/Company";

const useStyles = makeStyles({
  root: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "calc(100vh - 60px)",
  },
  rootDesktop: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    minHeight: "calc(100vh - 150px)",
    flexDirection: "column",
    alignItems: "center",
  },
  rootDesktopWithoutTitle: {
    display: "flex",
    width: "100%",
    minHeight: "calc(100vh - 150px)",
    justifyContent: "space-around",
  },
  rootDesktopWithoutTitleDark: {
    display: "flex",
    width: "100%",
    minHeight: "calc(100vh - 150px)",
    justifyContent: "center",
    backgroundColor: "#474A56",
  },
  rootDesktopWithoutTitleNoOrders: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  rootDesktopNoOrders: {
    display: "flex",
    backgroundColor: "#EEF5ED",
    width: "100%",
    minHeight: "calc(100vh - 50px)",
    flexDirection: "column",
    alignItems: "center",
  },
  rootDesktopNoOrdersDark: {
    display: "flex",
    backgroundColor: "#474A56",
    width: "100%",
    minHeight: "calc(100vh - 50px)",
    flexDirection: "column",
    alignItems: "center",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionTitleDark: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "white",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sessionTitleContainerDark: {
    backgroundColor: "#474A56",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsAndStatusContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  noOrdersContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 250px)",
  },
  noOrdersText: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#666666",
  },
  noOrdersTextDark: {
    fontSize: "1.2em",
    fontWeight: "600",
    color: "white",
  },
});

const Status = () => {
  const isMobile = useMediaQuery("(max-width:900px)");

  const classes = useStyles();

  const colorMode = useContext(modeContext);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const [somethingOpen, setSomethingOpen] = useState([]);

  const navigate = useNavigate();

  const [token] = useContext(AuthContext);

  const [loaded, setLoaded] = useState(false);

  const [orders, setOrders] = useState({});

  const { fetchGetOrder, requestData, requestLoading } = useGetOrder();

  const handleOrders = () => {
    return new Promise((resolve, reject) => {
      fetchGetOrder();
      if (requestData) {
        setOrders(requestData);
      }
      resolve();
    });
  };

  const [selected, setSelected] = useState({});

  const manageOpenOrderCommercial = (orders) => {
    if (Object.keys(orders).length !== 0 && orders?.data.length !== 0) {
      if (orders.data.length !== 0 && somethingOpen.length === 0) {
        let array = [];
        for (let i = 0; i < orders.data.length; i++) {
          if (i === 0) {
            array.push(true);
          } else {
            array.push(false);
          }
        }
        setSomethingOpen(array);
      }
      if (
        Object.keys(selected).length === 0 ||
        (user?.systemRole === 4 &&
        company?.companyChanged === true &&
        company?.company_id !== undefined)
      ) {
        setSelected(orders.data[0]);
      }
    }
  };

  const [loadedLast, setLoadedLast] = useState(true)

  useEffect(() => {
    const redirectUnloggedUser = () => {
      if (!token.token) {
        return navigate("/login");
      } else if (
        user &&
        company &&
        !company?.company_id &&
        user?.systemRole === 4
      ) {
        return navigate("/");
      }
    };
    redirectUnloggedUser();

    const loadOrders = async () => {
      if (Object.keys(orders).length === 0 && user?.systemRole !== 4) {
        await handleOrders();
      } else if (
        Object.keys(orders).length === 0 &&
        user?.systemRole === 4 &&
        company?.company_id !== undefined
      ) {
        const result = await fetchGetOrder(company.company_id);
        if (result && !requestLoading) {
          setOrders(result);
          manageOpenOrderCommercial(result)
        }
      } else if (
        Object.keys(orders).length !== 0 &&
        user?.systemRole === 4 &&
        company?.companyChanged === true &&
        company?.company_id !== undefined
      ) {
        const result = await fetchGetOrder(company.company_id);
        if (result && !requestLoading) {
          setOrders(result);
          manageOpenOrderCommercial(result)
        }
      }
    };
    loadOrders();

    const manageOpenOrder = () => {
      if (Object.keys(orders)?.length !== 0 && orders?.data?.length !== 0) {
        if (orders.data.length !== 0 && somethingOpen.length === 0) {
          let array = [];
          for (let i = 0; i < orders.data.length; i++) {
            if (i === 0) {
              array.push(true);
            } else {
              array.push(false);
            }
          }
          setSomethingOpen(array);
        }
        if (
          Object.keys(selected).length === 0
        ) {
          setSelected(orders.data[0]);
        }
      }
    };
    if(user?.systemRole !== 4){
      manageOpenOrder()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    orders,
    requestData,
    somethingOpen,
    selected,
    requestLoading,
    user,
    company,
  ]);

  return isMobile ? (
    <div className={classes.root}>
      <div className={classes.sessionTitleContainer}>
        <h1 className={classes.sessionTitle}>Seu Último Pedido</h1>
      </div>
      {Object.keys(orders).length !== 0 && !requestLoading && (
        <LastOrderStatus
          orders={orders}
          loaded={loadedLast}
          lastOrder={orders.data[orders.data.length - 1]}
        />
      )}
    </div>
  ) : (
    <div
      className={orders &&
        Object.keys(orders)?.length !== 0 && orders?.data?.length !== 0
          ? classes.rootDesktop
          : colorMode !== "dark"
          ? classes.rootDesktopNoOrders
          : classes.rootDesktopNoOrdersDark
      }
    >
      <div
        className={
          colorMode !== "dark"
            ? classes.sessionTitleContainer
            : classes.sessionTitleContainerDark
        }
      >
        <h1
          className={
            colorMode !== "dark"
              ? classes.sessionTitle
              : classes.sessionTitleDark
          }
        >
          Meus Pedidos
        </h1>
      </div>
      <div
        className={orders &&
          Object.keys(orders)?.length !== 0 &&
          orders?.data?.length !== 0 &&
          colorMode !== "dark"
            ? classes.rootDesktopWithoutTitle
            : Object.keys(orders)?.length !== 0 &&
              orders?.data?.length !== 0 &&
              colorMode === "dark"
            ? classes.rootDesktopWithoutTitleDark
            : classes.rootDesktopWithoutTitleNoOrders
        }
      >
        {Object.keys(orders).length !== 0 && orders.data.length !== 0 && (
          <div className={classes.detailsAndStatusContainer}>
            {orders.data.map((order, index) => (
              <StatusProduct
              setLoaded={setLoadedLast}
              loaded={loadedLast}
                order={order}
                setSelected={setSelected}
                index={index}
                somethingOpen={somethingOpen}
                setSomethingOpen={setSomethingOpen}
              />
            ))}
          </div>
        )}
        {Object.keys(orders).length !== 0 &&
        orders.data.length !== 0 &&
        Object.keys(selected).length !== 0 &&
        !requestLoading ? (
          <LastOrderStatus loaded={loadedLast} lastOrder={selected} orders={orders} />
        ) : (
          !requestLoading && (
            <LastOrderStatus loaded={loadedLast} lastOrder={undefined} orders={orders} />
          )
        )}
      </div>
    </div>
  );
};

export default Status;

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { modeContext } from "../../App";
import { useMediaQuery } from "@mui/material";
import { CompanyContext } from "../../contexts/Company";
import { UserContext } from "../../contexts/User";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  cardContainer: {
    width: (props) =>
      props.index === props.focus && !props.isMobile ? "300px" : "250px",
    height: (props) =>
      props.index === props.focus && !props.isMobile ? "450px" : "400px",
    transition: "width 1s, height 1s",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "3px solid #273682",
    margin: "0 20px 40px 20px",
    justifyContent: "space-around",
    borderRadius: "15px",
    backgroundColor: "white",
  },
  cardContainerDark: {
    width: "250px",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #0B0B0D",
    margin: "0 20px 40px 20px",
    justifyContent: "space-around",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  bothParagraphs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "110px",
    justifyContent: "space-around",
  },
  images: {
    width: "160px",
    height: "130px",
    objectFit: "contain",
    userSelect: "none",
  },
  vivoImage: {
    width: "190px",
    height: "130px",
    objectFit: "contain",
    userSelect: "none",
  },
  paragraphPrice: {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "#273682",
    userSelect: "none",
  },
  paragraphPriceDark: {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "#474A56",
  },
  paragraphName: {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "black",
    userSelect: "none",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "50px",
  width: "150px",
  backgroundColor: "#273682",
  textTransform: "unset",
  border: "2px solid transparent",
  fontWeight: 700,
  fontSize: "1.2em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682",
    border: "2px solid #273682",
    // filter: "brightness(120%)",
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "50px",
  width: "150px",
  backgroundColor: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.3em",
  borderRadius: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
  },
});

export const ProductCard = ({ name, price, carrier, id, ...props }) => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:900px)");

  const colorMode = useContext(modeContext);

  const { enqueueSnackbar } = useSnackbar();

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const handleOpenProduct = () => {
    if (user.systemRole !== 4) {
      navigate(`/product/${id}`);
    } else if (user.systemRole === 4 && company.company_id !== undefined) {
      navigate(`/product/${id}`);
    } else {
      enqueueSnackbar("Selecione uma empresa antes de acessar o produto", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const classes = useStyles(props);

  return (
    <div
      className={
        isMobile
          ? classes.cardContainer
          : colorMode !== "dark"
          ? classes.cardContainer
          : classes.cardContainerDark
      }
    >
      <div>
        <img
          className={
            !name.toLowerCase().includes("vivo")
              ? classes.images
              : classes.vivoImage
          }
          src={`${
            process.env.REACT_APP_API_URL
          }/public/images/product/${carrier?.toLowerCase()}`}
          alt={name}
        />
      </div>
      <div className={classes.bothParagraphs}>
        <p className={classes.paragraphName}>{name}</p>
        <p
          className={
            colorMode !== "dark" || isMobile
              ? classes.paragraphPrice
              : classes.paragraphPriceDark
          }
        >{`R$ ${price
          .toString()
          .split("")
          .reverse()
          .join("")
          .substring(2)
          .split("")
          .reverse()
          .join("")},${price
          .toString()
          .split("")
          .reverse()
          .join("")
          .substring(0, 2)
          .split("")
          .reverse()
          .join("")}`}</p>
      </div>
      {colorMode !== "dark" || isMobile ? (
        <StyledButton onClick={handleOpenProduct} variant="contained">
          Ver Mais
        </StyledButton>
      ) : (
        <StyledButtonDark onClick={handleOpenProduct} variant="contained">
          Ver Mais
        </StyledButtonDark>
      )}
    </div>
  );
};

import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";

const useStyles = makeStyles({
  successDark: { backgroundColor: "#413e3e!important", border: "1px solid white" },
  errorDark: { backgroundColor: "#1a1919!important", border: "1px solid white"},
  warningDark: { backgroundColor: "green" },
  infoDark: { backgroundColor: "yellow" },
});

const WrapperSnackBar = ({ children, colorMode }) => {

  const isMobile = useMediaQuery("(max-width:900px)");

  const classes = useStyles();

  return colorMode !== "dark" || isMobile ? (
    <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
  ) : (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.successDark,
        variantError: classes.errorDark,
        variantWarning: classes.warningDark,
        variantInfo: classes.infoDark,
      }}
      maxSnack={3}
    >
      {children}
    </SnackbarProvider>
  );
};

export default WrapperSnackBar;

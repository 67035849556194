import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteCart, useUpdateCart } from "../../API/Hooks/Cart";
import { CartLengthContext } from "../../contexts/CartLength";
import { useSnackbar } from "notistack";
import { useProductsQuantity } from "../../API/Hooks/Products";
import { modeContext } from "../../App";
import { UserContext } from "../../contexts/User";
import { CompanyContext } from "../../contexts/Company";
import { MenuItem, Tooltip } from "@mui/material";
import { useGetFreight } from "../../API/Hooks/Orders";

const useStyles = makeStyles({
  productDetailsContainer: {
    height: "420px",
    paddingTop: "20px",
    width: "100%",
    minWidth: "100%",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "3px solid #273682",
    justifyContent: "space-around",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "white",
  },
  productDetailsContainerLastItem: {
    height: "420px",
    paddingTop: "20px",
    width: "100%",
    display: "flex",
    borderRadius: "15px",
    alignItems: "center",
    flexDirection: "column",
    border: "3px solid #273682",
    justifyContent: "space-around",
    position: "relative",
    overflow: "hidden",
    marginBottom: "20px",
    backgroundColor: "white",
  },
  productDetailsContainerDesktop: {
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    height: "150px",
    borderRadius: "15px",
    width: "80%",
    minWidth: "560px",
    display: "flex",
    alignItems: "center",
    border: "3px solid #273682",
    justifyContent: "space-around",
  },
  productDetailsContainerDesktopDark: {
    paddingLeft: "30px",
    paddingRight: "30px",
    borderRadius: "15px",
    backgroundColor: "white",
    height: "150px",
    width: "80%",
    minWidth: "560px",
    display: "flex",
    alignItems: "center",
    border: "3px solid #0B0B0D",
    justifyContent: "space-around",
  },
  productDetailsContainerDesktopLastItem: {
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "15px",
    height: "150px",
    width: "80%",
    minWidth: "560px",
    display: "flex",
    alignItems: "center",
    border: "#273682",
    justifyContent: "space-around",
  },
  productDetailsContainerDesktopLastItemDark: {
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "15px",
    height: "150px",
    width: "80%",
    minWidth: "560px",
    display: "flex",
    alignItems: "center",
    border: "3px solid #0B0B0D",
    justifyContent: "space-around",
  },
  orderDetailsContainer: {
    height: "600px",
    width: "90%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktop: {
    height: "600px",
    width: "27%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  orderDetailsContainerDesktopBigger: {
    height: "600px",
    width: "22%",
    border: "3px solid #273682",
    borderRadius: "15px",
    padding: "0 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignContent: "center",
    backgroundColor: "white",
  },
  sessionName: {
    alignItems: "center",
    fontWeight: "bold",
    color: "#273682",
    marginLeft: "10px",
    fontSize: "1em",
  },
  sessionNameDark: {
    alignItems: "center",
    fontWeight: "bold",
    marginLeft: "10px",
    fontSize: "1em",
  },
  sessionNameMobile: {
    alignItems: "center",
    fontWeight: "bold",
    color: "#273682",
    fontSize: "1em",
  },
  images: {
    width: "160px",
    height: "100px",
    objectFit: "contain",
  },
  priceProduct: {
    color: "#001064",
    fontWeight: "600",
    fontSize: "1.1em",
    marginLeft: "10px",
  },
  priceProductDark: {
    color: "#474A56",
    fontWeight: "600",
    fontSize: "1.1em",
    marginLeft: "10px",
  },
  priceProductMobile: {
    color: "#001064",
    fontWeight: "600",
    fontSize: "1.1em",
  },
  stock: {
    textAlign: "center",
    color: "#0FA958",
    fontWeight: "bold",
    fontSize: "1em",
  },
  stockParagraph: {
    marginLeft: "10px",
  },
  soldOff: {
    textAlign: "center",
    color: "#E11521",
    fontWeight: "bold",
  },
  selectAndTextFieldContainerDesktopNoTariffs: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "80px",
  },
  selectAndTextFieldContainerDesktop: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "65px",
  },
  detailsParagraphLeftTotal: {
    fontSize: "1em",
    textAlign: "left",
  },
  productDetailsAndOrderDetailsContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "10px",
  },
  imageNameAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100px",
    width: "420px",
    marginLeft: "10px",
  },
  imageNameAndPriceContainerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100px",
    width: "420px",
    alignItems: "center",
  },
  descriptionContainer: {
    display: "flex",
    width: "70%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  resize: {
    fontSize: "1em!important",
    cursor: "pointer",
  },
  tariffSelected: {
    width: "60%!important",
    fontSize: "2px!important",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "15px 0 13px 10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "6px",
    },
  },
  select: {
    width: "60%!important",
    height: "50px",
    "& .MuiInputBase-input": {
      "&:focus": {
        borderStyle: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "15px 0 13px 10px",
    },
    "& .MuiSvgIcon-root": {
      margin: "0",
      position: "absolute",
      top: "6px",
    },
  },
});

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  width: "60%",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#273682",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#273682",
    },
  },
  "& label.Mui-focused": {
    color: "#273682",
    fontWeight: "600",
  },
  "& .MuiInputLabel-root": {
    color: "#273682",
    fontWeight: "600",
  },
});

const StyledTextFieldDark = styled(TextField, {
  name: "StyledTextField",
})({
  width: "60%",
  backgroundColor: "white",
  textTransform: "unset",
  fontWeight: "bold",
  fontSize: "1.5em",
  zIndex: "0",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#474A56",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#474A56",
    },
  },
  "& label.Mui-focused": {
    color: "#474A56",
    fontWeight: "600",
  },
  "& .MuiInputLabel-root": {
    color: "#474A56",
    fontWeight: "600",
  },
});

const StyledDeleteIcon = styled(DeleteIcon, {
  name: "DeleteIcon",
})({
  height: "30px",
  width: "30px",
  color: "#A4A5AA",
  marginBottom: "0",
  marginRight: "0",
  "&:hover": {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

const StyledDeleteIconDark = styled(DeleteIcon, {
  name: "DeleteIcon",
})({
  height: "30px",
  width: "30px",
  color: "#474A56",
  marginBottom: "0",
  marginRight: "0",
  "&:hover": {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

const StyledDeleteIconMobile = styled(DeleteIcon, {
  name: "DeleteIcon",
})({
  height: "35px",
  width: "35px",
  color: "#A4A5AA",
  marginBottom: "0",
  position: "absolute",
  top: 15,
  right: 10,
  marginRight: "0",
  "&:hover": {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});

export const ProductDetailsCart = ({
  carrier,
  id,
  setProducts,
  productId,
  name,
  index,
  lastCartProduct,
  setProductsLength,
  type,
  products,
  price,
  quantityBackEnd,
  promotionalTariffsList,
  selectedTariffs,
  setSelectedTariffs,
  tariffChanged,
  setTariffChanged,
  cartItemId,
  setChangingTariff,
  selectedAddress,
  selectedFreight,
  setSelectedFreight,
  setTotalValue,
  value,
  setQuantityChanged,
}) => {
  const [quantity, setQuantity] = useState(quantityBackEnd);

  const { enqueueSnackbar } = useSnackbar();

  const colorMode = useContext(modeContext);

  const [user, dispatchUserContext] = useContext(UserContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext);

  const [selectedTariff, setSelectedTariff] = useState({});

  const [cart, dispatchCartLengthContext] = useContext(CartLengthContext);

  const [priceFormated, setPriceFormated] = useState(null);

  const isMobile = useMediaQuery("(max-width:900px)");

  const handleTariff = async (value) => {
    setChangingTariff(true);
    let newSelectedTariffs = selectedTariffs;
    newSelectedTariffs[cartItemId] = value;
    setSelectedTariffs({
      type: "setSelectedTariffs",
      newSelectedTariffs,
    });
    setSelectedTariff(value);
    setTariffChanged(true);
    setChangingTariff(false);
  };

  const operators = ["claro", "vivo", "tim", "oi", "arqia", "nlt", "algar"];

  const productName = operators.find((item) =>
    name.toLowerCase().includes(item)
  );

  const [stockQuantity, setStockQuantity] = useState({});

  const { fetchDeleteCart, requestLoadingDelete } = useDeleteCart();

  const { fetchUpdateCart, requestLoading } = useUpdateCart();

  const correctProductTariff = (product) => {
    const tariffFound = selectedTariffs[product.id];
    if (tariffFound !== undefined) {
      return tariffFound.tariff;
    } else {
      return product.product.price_unit;
    }
  };

  const classes = useStyles();

  const { fetchProductsQuantity, requestData } = useProductsQuantity();

  const { fetchGetFreight, requestErrorFreight, requestLoadingFreight } =
    useGetFreight();

  const [oldQuantity, setOldQuantity] = useState(0);

  const handleStockQuantity = () => {
    return new Promise((resolve, reject) => {
      fetchProductsQuantity(productName);
      setStockQuantity(requestData);
      resolve();
    });
  };

  const total = (priceFormatted) => {
    let result = parseFloat(priceFormatted.replace(",", ".")) * quantity;
    result = result.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
    return `R$ ${result}`;
  };

  const [returnTotal, setReturnTotal] = useState(null);

  const updateQuantity = (cartProductsArray, targetValue) => {
    for (let i = 0; i < cartProductsArray.length; i++) {
      if (i === index) {
        cartProductsArray[i].quantity = targetValue;
      }
    }
    return cartProductsArray;
  };

  const handleQuantity = async (event) => {
    if (
      event.target.value.length < 7 &&
      (event.target.value >= 1 || event.target.value === "") &&
      !requestLoading &&
      !requestLoadingFreight
    ) {
      setOldQuantity(quantity);
      setQuantity(parseInt(event.target.value));
      let body = {
        itemId: id,
        productId: productId,
        productType: type,
        quantity: Number(event.target.value),
      };
      if (user.systemRole === 4) {
        body.companyId = company.company_id;
      }
      await fetchUpdateCart(body);
      const result = updateQuantity(
        products.data,
        parseInt(event.target.value)
      );
      setProducts({ ...products, result });
      let resultPrice =
        parseFloat(priceFormated.replace(",", ".")) *
        parseInt(event.target.value);
      resultPrice = resultPrice.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
      });
      setReturnTotal(`R$ ${resultPrice}`);
      if (user?.systemRole !== 4) {
        setTotalValue(
          products.data.reduce(
            (acc, curVal, index) =>
              acc +
              curVal.quantity * correctProductTariff(products.data[index]),
            value === "sedex"
              ? Number(selectedFreight.data[0].Valor.replace(",", ""))
              : Number(selectedFreight.data[1].Valor.replace(",", ""))
          )
        );
      } else {
        setTotalValue(
          products.data.reduce(
            (acc, curVal, index) =>
              acc +
              curVal.quantity * correctProductTariff(products.data[index]),
            0
          )
        );
      }

      if (selectedFreight?.data?.[0].Codigo === "---") {
        const newFreight = await fetchGetFreight(
          selectedAddress,
          products.data.reduce((acc, curVal) => acc + curVal.quantity, 0)
        );
        if (newFreight?.data) {
          setSelectedFreight(newFreight);
        }
      }
      setQuantityChanged(true);
    }
  };

  const handleComma = (event) => {
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
      return false;
    }
  };

  const handleDeleteFromCart = () => {
    if (company.company_id !== undefined) {
      fetchDeleteCart(id, company.company_id).then(() => {
        setQuantityChanged(true);
      });
    } else {
      fetchDeleteCart(id).then(() => {
        setQuantityChanged(true);
      });
    }
    if (!requestLoadingDelete) {
      setProductsLength(products.data.length - 1);
      dispatchCartLengthContext({
        type: "setCartLength",
        length: cart.length - 1,
      });
      enqueueSnackbar(`Removido do carrinho!`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        preventDuplicate: false,
      });
    }
  };

  console.log(selectedTariff);

  useEffect(() => {
    if (
      selectedTariffs?.[cartItemId] &&
      Object.keys(selectedTariff).length === 0
    ) {
      setChangingTariff(true);
      setSelectedTariff(selectedTariffs[cartItemId]);
      setChangingTariff(false);
      setTariffChanged(true);
    }

    if (!priceFormated) {
      const newPriceFormatted = `${price
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(2)
        .split("")
        .reverse()
        .join("")},${price
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(0, 2)
        .split("")
        .reverse()
        .join("")}`;
      setPriceFormated(newPriceFormatted);
      setReturnTotal(total(newPriceFormatted));
    } else if (
      priceFormated &&
      tariffChanged &&
      Object.keys(selectedTariff).length !== 0
    ) {
      const newPriceFormatted = `${selectedTariff.tariff
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(2)
        .split("")
        .reverse()
        .join("")},${selectedTariff.tariff
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(0, 2)
        .split("")
        .reverse()
        .join("")}`;
      setPriceFormated(newPriceFormatted);
      setReturnTotal(total(newPriceFormatted));
      setTariffChanged(false);
    } else if (
      priceFormated &&
      tariffChanged &&
      Object.keys(selectedTariff).length === 0
    ) {
      const newPriceFormatted = `${price
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(2)
        .split("")
        .reverse()
        .join("")},${price
        .toString()
        .split("")
        .reverse()
        .join("")
        .substring(0, 2)
        .split("")
        .reverse()
        .join("")}`;
      setPriceFormated(newPriceFormatted);
      setReturnTotal(total(newPriceFormatted));
      setTariffChanged(false);
    }
    const loadStockQuantity = async () => {
      if (Object.keys(stockQuantity).length === 0) {
        await handleStockQuantity();
      }
    };
    loadStockQuantity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    returnTotal,
    quantityBackEnd,
    price,
    quantity,
    products,
    cart,
    stockQuantity,
    requestData,
    priceFormated,
    tariffChanged,
    requestLoading,
    requestLoadingFreight,
    oldQuantity,
    selectedFreight,
    selectedTariff,
    selectedTariffs,
    value,
  ]);

  return (
    <>
      {isMobile ? (
        <>
          <div className={classes.productDetailsAndOrderDetailsContainer}>
            <div
              className={
                lastCartProduct?.name === name
                  ? classes.productDetailsContainerLastItem
                  : classes.productDetailsContainer
              }
            >
              <div>
                <img
                  className={classes.images}
                  src={`${
                    process.env.REACT_APP_API_URL
                  }/public/images/product/${carrier?.toLowerCase()}`}
                  alt={name}
                />
              </div>

              <div
                className={
                  isMobile
                    ? classes.imageNameAndPriceContainerMobile
                    : classes.imageNameAndPriceContainer
                }
              >
                <h2 className={classes.sessionNameMobile}>
                  {type} {name}
                </h2>
                {returnTotal && (
                  <p className={classes.priceProductMobile}>
                    {!isNaN(quantity) ? returnTotal : "R$ 0,00"}
                  </p>
                )}
                <p>
                  {Object.keys(stockQuantity).length !== 0 &&
                    (stockQuantity.data >= 1 ? (
                      <span className={classes.stock}>Em estoque</span>
                    ) : (
                      <span className={classes.soldOff}>Esgotado</span>
                    ))}
                </p>
              </div>

              <div className={classes.descriptionContainer}>
                <div className={classes.selectAndTextFieldContainerDesktop}>
                  <StyledTextField
                    id="outlined-number"
                    label="Quantidade"
                    max={7}
                    type="number"
                    value={quantity}
                    onChange={handleQuantity}
                    onKeyDown={handleComma}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className={classes.iconContainer}>
                <StyledDeleteIconMobile
                  onClick={() => handleDeleteFromCart()}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.productDetailsAndOrderDetailsContainer}>
            <div
              className={
                lastCartProduct?.name === name && colorMode !== "dark"
                  ? classes.productDetailsContainerDesktopLastItem
                  : lastCartProduct?.name === name && colorMode === "dark"
                  ? classes.productDetailsContainerLastItemDark
                  : colorMode !== "dark" && lastCartProduct?.name !== name
                  ? classes.productDetailsContainerDesktop
                  : classes.productDetailsContainerDesktopDark
              }
            >
              <div>
                <img
                  className={classes.images}
                  src={`${
                    process.env.REACT_APP_API_URL
                  }/public/images/product/${carrier?.toLowerCase()}`}
                  alt={name}
                />
              </div>

              <div className={classes.imageNameAndPriceContainer}>
                <h2
                  className={
                    colorMode !== "dark"
                      ? classes.sessionName
                      : classes.sessionNameDark
                  }
                >
                  {type} {name}
                </h2>
                <p
                  className={
                    colorMode !== "dark"
                      ? classes.priceProduct
                      : classes.priceProductDark
                  }
                >
                  {!isNaN(quantity) ? returnTotal : "R$ 0,00"}
                </p>
                <p className={classes.stockParagraph}>
                  {Object.keys(stockQuantity).length !== 0 &&
                    (stockQuantity.data >= 1 ? (
                      <span className={classes.stock}>Em estoque</span>
                    ) : (
                      <span className={classes.soldOff}>Esgotado</span>
                    ))}
                </p>
              </div>

              <div className={classes.descriptionContainer}>
                <div
                  className={
                    promotionalTariffsList?.length !== 0
                      ? classes.selectAndTextFieldContainerDesktop
                      : classes.selectAndTextFieldContainerDesktopNoTariffs
                  }
                >
                  {colorMode !== "dark" ? (
                    <StyledTextField
                      id="outlined-number"
                      label="Quantidade"
                      max={7}
                      type="number"
                      value={quantity}
                      onChange={handleQuantity}
                      onKeyDown={handleComma}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : (
                    <StyledTextFieldDark
                      id="outlined-number"
                      label="Quantidade"
                      max={7}
                      type="number"
                      value={quantity}
                      onChange={handleQuantity}
                      onKeyDown={handleComma}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </div>
                {promotionalTariffsList?.length !== 0 &&
                user?.systemRole === 4 &&
                !selectedTariffs?.[cartItemId] ? (
                  <div className={classes.selectAndTextFieldContainerDesktop}>
                    <StyledTextField
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTariff}
                      select
                      InputLabelProps={{ shrink: true }}
                      label="Tarifa Promocional &nbsp;"
                      onChange={(e) => handleTariff(e.target.value)}
                      className={classes.select}
                    >
                      {promotionalTariffsList.map((tariff) => (
                        <MenuItem value={tariff}>
                          R$
                          {tariff.tariff
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")}
                          ,
                          {tariff.tariff
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")}{" "}
                          - {tariff.name}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </div>
                ) : (
                  promotionalTariffsList?.length !== 0 &&
                  user?.systemRole === 4 &&
                  Object.keys(selectedTariff).length !== 0 && (
                    <div className={classes.selectAndTextFieldContainerDesktop}>
                      <Tooltip title={"Remover"} arrow>
                        <StyledTextField
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          textov
                          value={`${selectedTariff.tariff
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(2)
                            .split("")
                            .reverse()
                            .join("")},${selectedTariff.tariff
                            .toString()
                            .split("")
                            .reverse()
                            .join("")
                            .substring(0, 2)
                            .split("")
                            .reverse()
                            .join("")} - ${
                            selectedTariff.name.length < 22
                              ? selectedTariff.name
                              : `${selectedTariff.name.slice(0, 22)}...`
                          }`}
                          InputProps={{
                            shrink: true,
                            classes: {
                              input: classes.resize,
                            },
                          }}
                          label="Tarifa Promocional &nbsp;"
                          onClick={() => {
                            setChangingTariff(true);
                            let newSelectedTariffs = selectedTariffs;
                            delete newSelectedTariffs[cartItemId];
                            setSelectedTariffs({
                              type: "setSelectedTariffs",
                              newSelectedTariffs,
                            });
                            setSelectedTariff({});
                            setChangingTariff(false);
                            setTariffChanged(true);
                          }}
                          className={classes.tariffSelected}
                        />
                      </Tooltip>
                    </div>
                  )
                )}
              </div>
              <div className={classes.iconContainer}>
                {colorMode !== "dark" ? (
                  <StyledDeleteIcon onClick={() => handleDeleteFromCart()} />
                ) : (
                  <StyledDeleteIconDark
                    onClick={() => handleDeleteFromCart()}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

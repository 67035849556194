import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { useOrder } from "../../API/Hooks/Orders";
import { useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CartLengthContext } from "../../contexts/CartLength";
import { modeContext } from "../../App";
import { useLogout } from "../../API/Hooks";

const useStyles = makeStyles({
  root: {
    minHeight: "500px",
    width: "500px",
    position: "fixed",
    backgroundColor: "white",
    border: "3px solid #273682",
    zIndex: 2,
    borderRadius: "15px",
    right: "calc(50vw - 250px)",
    top: "calc(50vh - 250px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "100%",
    transition: "all 0.5s linear",
  },
  rootMobile: {
    minHeight: "500px",
    width: "100vw",
    position: "fixed",
    backgroundColor: "white",
    border: "3px solid #273682",
    zIndex: 2,
    borderRadius: "15px",
    top: "calc(50vh - 250px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "100%",
    transition: "all 0.5s linear",
  },
  rootClosedMobile: {
    minHeight: "500px",
    width: "100vw",
    position: "fixed",
    backgroundColor: "white",
    border: "3px solid #273682",
    zIndex: 2,
    borderRadius: "15px",
    top: "calc(50vh - 250px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "0%",
    pointerEvents: "none",
    transition: "all 0.5s linear",
  },
  rootClosed: {
    minHeight: "500px",
    width: "500px",
    position: "fixed",
    backgroundColor: "white",
    border: "3px solid #273682",
    zIndex: 2,
    borderRadius: "15px",
    right: "calc(50vw - 250px)",
    top: "calc(50vh - 250px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "0%",
    pointerEvents: "none",
    transition: "all 0.5s linear",
  },
  paragraphContainer: {
    height: "300px",
    overflowY: "scroll",
    width: "95%",
    backgroundColor: "#ffffff",
    textAlign: "justify",
    display: "flex",
    borderRadius: "10px",
    padding: "10px 15px 10px 15px",
    scrollbarColor: "#EEF5ED #EEF5ED",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "#EEF5ED",
      border: "1px solid #C3C3C3",
      borderRadius: "5px 5px 5px 5px",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: "5px 5px 5px 5px",
      backgroundColor: "#273682",
      minHeight: 24,
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: "rgb(18, 34, 130)",
    },
  },
  paragraphContainerDark: {
    height: "300px",
    overflowY: "scroll",
    width: "95%",
    backgroundColor: "#273682",
    color: "white",
    textAlign: "justify",
    display: "flex",
    borderRadius: "10px",
    padding: "10px 15px 10px 15px",
    scrollbarColor: "#EEF5ED #EEF5ED",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "#EEF5ED",
      border: "1px solid #0B0B0D",
      borderRadius: "5px 5px 5px 5px",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: "5px 5px 5px 5px",
      backgroundColor: "#2B2B30",
      minHeight: 24,
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#0B0B0D",
    },
  },
  checkboxContainer: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    width: "90%",
  },
  paragraph: {
    width: "100%",
    height: "20px",
    fontWeight: "600",
    fontSize: "0.9em",
  },
  paragraphDark: {
    width: "100%",
    height: "20px",
    fontSize: "0.9em",
    fontWeight: "600",
    color: "#2B2B30",
  },
  paragraphMobile: {
    width: "100%",
    height: "32px",
    fontSize: "0.9em",
    lineHeight: "1.1em",
    marginLeft: "10px",
  },
  titulo: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#273682",
    margin: "15px",
  },
  tituloDark: {
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "#2B2B30",
    margin: "15px",
  },
});

const StyledButton = styled(Button, {
  name: "StyledButton",
})({
  height: "40px",
  width: "130px",
  backgroundColor: "#273682",
  color: "#FFFFFF",
  textTransform: "unset",
  fontWeight: "600",
  marginTop: "15px",
  fontSize: "1.3em",
  border: "1px solid #273682",
  borderRadius: "10px",
  margin: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682"
  },
});

const StyledButtonDark = styled(Button, {
  name: "StyledButton",
})({
  height: "40px",
  width: "130px",
  backgroundColor: "#0B0B0D",
  color: "#FFFFFF",
  textTransform: "unset",
  fontWeight: "600",
  marginTop: "15px",
  fontSize: "1.3em",
  borderRadius: "10px",
  border: "1px solid white",
  margin: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    filter: "brightness(120%)",
  },
});

const StyledButtonCancel = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "40px",
  width: "130px",
  backgroundColor: "#273682",
  color: "#FFFFFF",
  textTransform: "unset",
  fontWeight: "600",
  marginTop: "15px",
  fontSize: "1.3em",
  border: "1px solid #273682",
  borderRadius: "10px",
  margin: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "white",
    color: "#273682"
  },
});

const StyledButtonCancelDark = styled(Button, {
  name: "StyledButtonCart",
})({
  height: "40px",
  width: "130px",
  backgroundColor: "white",
  color: "#0B0B0D",
  textTransform: "unset",
  fontWeight: "600",
  marginTop: "15px",
  fontSize: "1.3em",
  borderRadius: "10px",
  border: "1px solid #0B0B0D",
  margin: "10px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#2B2B30",
    border: "1px solid white",
    color: "white",
  },
});

const StyledCheckbox = styled(Checkbox, {
  name: "StyledButton",
})({
  padding: 0,
  width: "35px",
  "& .MuiSvgIcon-root": {
    margin: 0,
    color: "#273682",
  },
});

const StyledCheckboxDark = styled(Checkbox, {
  name: "StyledButton",
})({
  padding: 0,
  width: "35px",
  "& .MuiSvgIcon-root": {
    margin: 0,
    color: "#474A56",
  },
});

const StyledCheckboxDisabled = styled(Checkbox, {
  name: "StyledButton",
})({
  padding: 0,
  width: "35px",
  "& .MuiSvgIcon-root": {
    margin: 0,
    color: "rgb(166, 166, 166)",
  },
});

export const TermsOfService = ({
  openTermsOfService,
  setOpenTermsOfService,
  products,
  selectedAddress,
  value,
  termDescription,
  onConfirm,
}) => {
  const [checked, setChecked] = useState(false);

  const classes = useStyles();

  const colorMode = useContext(modeContext);

  const listInnerRef = useRef();

  const { fetchLogout } = useLogout();

  const [, dispatchCartLengthContext] = useContext(CartLengthContext);

  const isMobile = useMediaQuery("(max-width:768px)");

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const [creating, setCreating] = useState(false);

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const { requestError, requestLoading } = useOrder();

  const handleConfirmModal = () => onConfirm();

  const handleCloseModal = () => {
    // setOpenTermsOfService(false);
    setScrolledToBottom(false);
    setLoaded(false);
    setChecked(false);
    fetchLogout();
    navigate("/login");
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight > scrollHeight - 2) {
        setScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    if (!loaded && listInnerRef.current) {
      listInnerRef.current.scrollTo(0, 0);
      setLoaded(true);
    }
    const showMessage = () => {
      if (!requestError && !requestLoading && creating) {
        enqueueSnackbar("Pedido realizado!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        dispatchCartLengthContext({
          type: "setCartLength",
          length: 0,
        });
        navigate("/status");
        setCreating(false);
      }
    };
    showMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loaded,
    listInnerRef.current,
    products,
    selectedAddress,
    requestError,
    requestLoading,
    creating,
  ]);

  return (
    <div
      className={
        openTermsOfService && isMobile
          ? classes.rootMobile
          : openTermsOfService && !isMobile
          ? classes.root
          : !openTermsOfService && isMobile
          ? classes.rootClosedMobile
          : classes.rootClosed
      }
    >
      <h2
        className={
          colorMode !== "dark" || isMobile ? classes.titulo : classes.tituloDark
        }
      >
        Termos de Uso e Contrato
      </h2>
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        className={
          colorMode !== "dark" || isMobile
            ? classes.paragraphContainer
            : classes.paragraphContainerDark
        }
      >
        <div className="Container" dangerouslySetInnerHTML={{__html: termDescription}}></div>
      </div>
      <div className={classes.checkboxContainer}>
        {scrolledToBottom && (colorMode !== "dark" || isMobile) ? (
          <StyledCheckbox
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        ) : scrolledToBottom && colorMode === "dark" ? (
          <StyledCheckboxDark
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        ) : (
          <StyledCheckboxDisabled
            disabled
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        )}

        <p
          className={
            isMobile
              ? classes.paragraphMobile
              : colorMode !== "dark"
              ? classes.paragraph
              : classes.paragraphDark
          }
        >
          Eu li e concordo com os termos de uso e a política de privacidade.
        </p>
      </div>
      <div className={classes.buttonsContainer}>
        {checked && (colorMode !== "dark" || isMobile) ? (
          <StyledButton onClick={() => handleConfirmModal()}>
            {" "}
            ACEITO{" "}
          </StyledButton>
        ) : checked && colorMode === "dark" ? (
          <StyledButtonDark onClick={() => handleConfirmModal()}>
            ACEITO
          </StyledButtonDark>
        ) : !checked && colorMode === "dark" ? (
          <StyledButtonDark
            disabled
            variant="contained"
            onClick={() => handleConfirmModal()}
          >
            ACEITO
          </StyledButtonDark>
        ) : (
          <StyledButton
            disabled
            variant="contained"
            onClick={() => handleConfirmModal()}
          >
            ACEITO
          </StyledButton>
        )}
        {colorMode !== "dark" || isMobile ? (
          <StyledButtonCancel onClick={() => handleCloseModal()}>
            RECUSO
          </StyledButtonCancel>
        ) : (
          <StyledButtonCancelDark onClick={() => handleCloseModal()}>
            RECUSO
          </StyledButtonCancelDark>
        )}
      </div>
    </div>
  );
};

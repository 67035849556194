import { makeStyles } from "@mui/styles";
import { ProductCard } from "../../../components/ProductCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useProducts } from "../../../API/Hooks/Products";
import { useContext, useEffect, useState } from "react";
import { modeContext } from "../../../App";
import { UserContext } from "../../../contexts/User";
import { CartLengthContext } from "../../../contexts/CartLength";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../contexts/Auth";
import { LoginMessageContext } from "../../../contexts/LoginMessage";
import { loadItem } from "../../../utils/localStorage";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import claroBanner from "../../../assets/img/clarobanner.png";
import meeting from "../../../assets/img/meeting.png";
import cyclopay from "../../../assets/img/cyclopay.png";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import { CompanyContext } from "../../../contexts/Company";

const useStyles = makeStyles({
  rootMobile: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EEF5ED",
    flexWrap: "wrap",
    justifyContent: "center",
    minHeight: "calc(100vh - 160px)",
  },
  rootDesktop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EEF5ED",
    flexWrap: "wrap",
  },
  rootDesktopDark: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#474A56",
    flexWrap: "wrap",
  },
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "#273682",
  },
  sessionTitleDark: {
    fontWeight: "bold",
    fontSize: "1.5em",
    color: "white",
  },
  sessionTitleContainer: {
    backgroundColor: "#EEF5ED",
  },
  sessionTitleContainerDark: {
    backgroundColor: "#474A56",
  },
  companyText: {
    fontSize: "1.2em",
    textAlign: "left",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#273682",
  },
  slide: {
    height: "250px",
    outline: "none!important",
    "& .slideInner___2mfX9": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .focusRing___1airF": {
      outline: "none!important",
    },
  },
  buttonBack: {
    position: "absolute",
    top: "calc(100% - (50% + 40px))",
    width: "48px",
    height: "48px",
    borderRadius: "48px",
    border: "1px solid transparent",
    backgroundColor: "transparent",
  },
  buttonNext: {
    position: "absolute",
    top: "calc(100% - (50% + 40px))",
    right: 0,
    width: "48px",
    height: "48px",
    borderRadius: "48px",
    border: "1px solid transparent",
    backgroundColor: "transparent",
  },
  image: {
    width: "100%",
    objectFit: "cover",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageContainer: {
    width: "100%",
  },
});

const Home = () => {
  const classes = useStyles();

  const colorMode = useContext(modeContext);

  const { enqueueSnackbar } = useSnackbar();

  const [user, dispatchUserContext] = useContext(UserContext);

  const [token] = useContext(AuthContext);

  const [login, dispatchLoginMessageContext] = useContext(LoginMessageContext);

  const [company, dispatchCompanyContext] = useContext(CompanyContext)

  const isMobile = useMediaQuery("(max-width:900px)", { noSsr: true });

  const { fetchProducts, fetchUserProducts, requestData, requestLoading } = useProducts();

  const [products, setProducts] = useState({});

  const [focus, setFocus] = useState(1);

  const [reloaded, setReloaded] = useState(true);

  const [reloading, setReloading] = useState(false);

  console.log(products)

  useEffect(() => {
    const loadProducts = async () => {
      if (Object.keys(products)?.length === 0 && !loadItem("token")) {
        const result = await fetchProducts();
        if(!requestLoading){
          setProducts(result);
        }
      } else if (Object.keys(products)?.length === 0 && loadItem("token") && user.systemRole !== 4) {
        const result = await fetchUserProducts();
        if(!requestLoading){
          setProducts(result);
        }
      } else if (Object.keys(products)?.length === 0 && user.systemRole === 4 && company?.company_id === undefined) {
        const result = await fetchProducts();
        if(!requestLoading){
          setProducts(result);
        }
      } else if (Object.keys(products)?.length === 0 && user.systemRole === 4 && company?.company_id !== undefined){
        const result = await fetchUserProducts(company.company_id);
        if(!requestLoading){
          setProducts(result);
        }
      }
    };
    loadProducts();

    const loadSpecificProducts = async () => {
      if (loadItem("token") && user.systemRole === 4 && company?.company_id !== undefined && company.companyChanged) {
        const result = await fetchUserProducts(company.company_id);
        if(!requestLoading){
          setProducts(result);
        }
      }
    };
    loadSpecificProducts();

    if (!isMobile && !reloaded) {
      setReloading(true);
      window.location.reload();
    }

    if (isMobile) {
      setReloaded(false);
    }

    if (login.canShow === true) {
      dispatchLoginMessageContext({
        type: "setLoginMessage",
        canShow: false,
      });
      enqueueSnackbar(`Bem-vindo (a), ${user.userName}!`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestData,
    products,
    user,
    login,
    token,
    isMobile,
    reloaded,
    reloading,
    company
  ]);

  const handleNextClick = () => {
    setFocus(focus + 1);
  };

  const handleBackClick = () => {
    setFocus(focus - 1);
  };

  return (
    <>
      <div
        className={
          isMobile
            ? classes.sessionTitleContainer
            : colorMode !== "dark"
            ? classes.sessionTitleContainer
            : classes.sessionTitleContainerDark
        }
      >
        {/* <h2
          className={
            isMobile
              ? classes.sessionTitle
              : colorMode !== "dark"
              ? classes.sessionTitle
              : classes.sessionTitleDark
          }
        >
          Loja
        </h2> */}
        <CarouselProvider
          naturalSlideWidth={3}
          naturalSlideHeight={0.5}
          totalSlides={3}
          visibleSlides={1}
          // touchEnabled={!isMobile ? false : true}
          // dragEnabled={!isMobile ? false : true}
          isPlaying={true}
          interval={4000}
        >
          <Slider>
            <Slide>
              <div className={classes.imageContainer}>
                <img className={classes.image} src={cyclopay} alt="Claro" />
              </div>
            </Slide>
            <Slide>
              <img className={classes.image} src={claroBanner} alt="Sala" />
            </Slide>
            <Slide>
              <img className={classes.image} src={meeting} alt="Cyclopay" />
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>
      <div
        className={
          isMobile
            ? classes.rootMobile
            : colorMode === "dark"
            ? classes.rootDesktopDark
            : classes.rootDesktop
        }
      >
        <div
          style={{ width: "1250px", position: "relative", overflow: "hidden" }}
        >
          {!reloading && (
            <CarouselProvider
              naturalSlideWidth={5}
              naturalSlideHeight={isMobile ? 9 : 7}
              totalSlides={products?.data?.length}
              visibleSlides={isMobile ? 1.4 : 3}
              touchEnabled={!isMobile ? false : true}
              dragEnabled={!isMobile ? false : true}
            >
              <Slider>
                {products?.data?.map((product, index) => (
                  <Slide className={classes.slide} index={index}>
                    <ProductCard
                      isMobile={isMobile}
                      index={index}
                      focus={focus}
                      name={product.name}
                      carrier={product?.telco}
                      price={product.price_unit}
                      id={product.id}
                    />
                  </Slide>
                ))}
              </Slider>
              {focus !== 1 && !isMobile && (
                <ButtonBack
                  className={classes.buttonBack}
                  onClick={handleBackClick}
                >
                  <ArrowBackIosNewIcon />
                </ButtonBack>
              )}
              {focus !== products?.data?.length - 2 && !isMobile && (
                <ButtonNext
                  className={classes.buttonNext}
                  onClick={handleNextClick}
                >
                  <ArrowForwardIosIcon />
                </ButtonNext>
              )}
              {/* {focus !== 0 && isMobile && (
              <ButtonBack
                className={classes.buttonBack}
                onClick={handleBackClick}
              >
                <ArrowBackIosNewIcon />
              </ButtonBack>
            )}
            {focus !== products?.data?.length - 1 && isMobile && (
              <ButtonNext
                className={classes.buttonNext}
                onClick={handleNextClick}
              >
                <ArrowForwardIosIcon />
              </ButtonNext>
            )} */}
            </CarouselProvider>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;

import { RegisterForm } from "../../../components/RegisterForm";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { loadItem } from "../../../utils/localStorage";
import { useRegister } from "../../../API/Hooks/Auth";
import { useGetCep, useGetCep2 } from "../../../API/Hooks/Addresses";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "#EEF5ED",
  },
  rootDesktop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EEF5ED",
  },
});

const Register = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [social, setSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [date, setDate] = useState("");
  const [tel, setTel] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpf, setCpf] = useState("");
  const [subscription, setSubscription] = useState("");
  const [complement, setComplement] = useState("");
  const [complement2, setComplement2] = useState("");
  const [street, setStreet] = useState("");
  const [street2, setStreet2] = useState("");
  const [number, setNumber] = useState("");
  const [number2, setNumber2] = useState("");
  const [district, setDistrict] = useState("");
  const [district2, setDistrict2] = useState("");
  const [state, setState] = useState("");
  const [state2, setState2] = useState("");
  const [cep, setCep] = useState("");
  const [cep2, setCep2] = useState("");
  const [checked, setChecked] = useState(true);
  const [city, setCity] = useState("");
  const [city2, setCity2] = useState("");
  const [search, setSearch] = useState(false);
  const [search2, setSearch2] = useState(false);
  const [oldZipCode, setOldZipCode] = useState("");
  const [oldZipCode2, setOldZipCode2] = useState("");

  const [erasing, setErasing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [registered, setRegistered] = useState(false)

  const { fetchRegister, requestError, requestLoading } = useRegister();

  const handleRegister = (data) => {
    delete data.birthdate;
    delete data.city;
    delete data.cnpj;
    delete data.company_name;
    delete data.complement;
    delete data.cpf;
    delete data.district;
    delete data.email;
    delete data.firstName;
    delete data.lastName;
    delete data.number;
    delete data.password;
    delete data.phone;
    delete data.state;
    delete data.state_registration;
    delete data.street;
    delete data.zipcode;
    delete data.zipcode2;
    delete data.city2;
    delete data.complement2;
    delete data.district2;
    delete data.number2;
    delete data.state2;
    delete data.street2;
    delete data.confirmPassword;
    if (checked && !requestErrorCep) {
      data.type = "legal_person";
      data.company = {
        company_name: social,
        state_registration: subscription,
        document_type: "cnpj",
        cnpj: cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '')
      };
      data.company.billing_address = [];
      data.company.billing_address.push({
        zipcode: cep,
        street: street,
        complement: complement,
        number: number,
        state: state,
        city: city,
        district: district,
      });
      data.company.shipping_address = [];
      data.company.shipping_address.push({
        zipcode: cep,
        street: street,
        complement: complement,
        number: number,
        state: state,
        city: city,
        district: district,
      });
      data.user = {
        firstName: firstName,
        lastName: lastName,
        birthdate: date,
        cpf: cpf.replace('.', '').replace('.', '').replace('-', ''),
        email: email,
        phone: tel.replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", ""),
        password: password,
      }
      fetchRegister(data);
      setRegistered(true)
    } else if (!checked && !requestErrorCep && !requestErrorCep2) {
      data.type = "legal_person";
      data.company = {
        company_name: social,
        state_registration: subscription,
        document_type: "cnpj",
        cnpj: cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '')
      };
      data.company.billing_address = [];
      data.company.billing_address.push({
        zipcode: cep,
        street: street,
        complement: complement,
        number: number,
        state: state,
        city: city,
        district: district,
      });
      data.company.shipping_address = [];
      data.company.shipping_address.push({
        zipcode: cep2,
        street: street2,
        complement: complement2,
        number: number2,
        state: state2,
        city: city2,
        district: district2,
      });
      data.user = {
        firstName: firstName,
        lastName: lastName,
        birthdate: date,
        cpf: cpf.replace('.', '').replace('.', '').replace('-', ''),
        email: email,
        phone: tel.replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", ""),
        password: password,
      };
      fetchRegister(data);
      setRegistered(true)
    }else{
      enqueueSnackbar("CEP inválido!", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const {
    fetchGetCep,
    requestDataCep,
    requestLoadingCep,
    requestErrorCep,
    setRequestDataCep,
    setRequestErrorCep,
  } = useGetCep();

  const {
    fetchGetCep2,
    requestDataCep2,
    requestLoadingCep2,
    requestErrorCep2,
    setRequestDataCep2,
    setRequestErrorCep2,
  } = useGetCep2();

  const handleRequestCep = () => {
    return new Promise((resolve, reject) => {
      fetchGetCep(cep);
      resolve(requestDataCep.data);
    });
  };

  const handleRequestCep2 = () => {
    return new Promise((resolve, reject) => {
      fetchGetCep2(cep2);
      resolve(requestDataCep2.data);
    });
  };

  const handleZipCode = (event) => {
    event.target.maxLength = 9;
    event.target.value = event.target.value.replace(/\D/g, "");
    setCep(event.target.value.replace(/^(\d{5})(\d)/, "$1-$2"));
  };

  const handleZipCode2 = (event) => {
    event.target.maxLength = 9;
    event.target.value = event.target.value.replace(/\D/g, "");
    setCep2(event.target.value.replace(/^(\d{5})(\d)/, "$1-$2"));
  };

  const handlePhone = (event) => {
    event.target.maxLength = 15;
    if (event.target.value.length === 2 && !erasing) {
      event.target.value = `(${event.target.value})`;
    } else if (event.target.value.length === 5 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        4
      )} ${event.target.value.substring(4)}`;
    } else if (event.target.value.length === 11 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        10
      )}-${event.target.value.substring(10)}`;
    }
    setTel(event.target.value);
  };

  const handleCpf = (event) => {
    event.target.maxLength = 14;
    if (event.target.value.length === 4 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        3
      )}.${event.target.value.substring(3)}`;
    } else if (event.target.value.length === 8 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        7
      )}.${event.target.value.substring(7)}`;
    } else if (event.target.value.length === 12 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        11
      )}-${event.target.value.substring(11)}`;
    }
    setCpf(event.target.value);
  };

  const handleCnpj = (event) => {
    event.target.maxLength = 18;
    if (event.target.value.length === 3 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        2
      )}.${event.target.value.substring(2)}`;
    } else if (event.target.value.length === 7 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        6
      )}.${event.target.value.substring(6)}`;
    } else if (event.target.value.length === 11 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        10
      )}/${event.target.value.substring(10)}`;
    } else if (event.target.value.length === 16 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        15
      )}-${event.target.value.substring(15)}`;
    }

    setCnpj(event.target.value);
  };

  const handleDate = (event) => {
    event.target.maxLength = 10;
    if (event.target.value.length === 3 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        2
      )}/${event.target.value.substring(2)}`;
    } else if (event.target.value.length === 6 && !erasing) {
      event.target.value = `${event.target.value.substring(
        0,
        5
      )}/${event.target.value.substring(5)}`;
    }
    setDate(event.target.value);
  };

  const handleErase = (event) => {
    if (event.keyCode === 8) {
      setErasing(true);
    } else {
      setErasing(false);
      if (isNaN(event.key) && event.keyCode !== 9) {
        event.preventDefault();
        return false;
      }
    }
  };

  const handleEraseCpfAndCnpj = (event) => {
    if (event.keyCode === 8) {
      setErasing(true);
    } else {
      setErasing(false);
    }
  };

  useEffect(() => {
    const loadCepDataUpdate = async () => {
      if (requestErrorCep && cep.length > 8) {
        setStreet("");
        setCity("");
        setDistrict("");
        setState("");
      }
      if (cep.length > 8 && search) {
        await handleRequestCep();
        setSearch(false);
        setOldZipCode(cep);
      }
      if (cep.length < 9 || cep !== oldZipCode) {
        setSearch(true);
      }
      if (
        !requestLoadingCep &&
        (city !== requestDataCep.data.localidade ||
          state !== requestDataCep.data.uf ||
          street !== requestDataCep.data.street ||
          district !== requestDataCep.data.district) &&
        cep.length > 8
      ) {
        if (!requestErrorCep) {
          setStreet(requestDataCep.data.logradouro);
          setCity(requestDataCep.data.localidade);
          setDistrict(requestDataCep.data.bairro);
          setState(requestDataCep.data.uf);
        }
      }
      if (cep2.length > 8 && search2) {
        await handleRequestCep2();
        setSearch2(false);
        setOldZipCode2(cep2);
      }
      if (cep2.length < 9 || cep2 !== oldZipCode2) {
        setSearch2(true);
      }
      if (
        !requestLoadingCep2 &&
        (city2 !== requestDataCep2.data.localidade ||
          state2 !== requestDataCep2.data.uf ||
          street2 !== requestDataCep2.data.street ||
          district2 !== requestDataCep2.data.district) &&
        cep2.length > 8
      ) {
        if (!requestErrorCep2) {
          setStreet2(requestDataCep2.data.logradouro);
          setCity2(requestDataCep2.data.localidade);
          setDistrict2(requestDataCep2.data.bairro);
          setState2(requestDataCep2.data.uf);
        }
      }
    };
    loadCepDataUpdate();
    if (!tel.includes(")") && tel.includes("(")) {
      setTel("");
    }
    if(cnpj.length === 14 && (!cnpj.includes('.') || !cnpj.includes('/'))){
      setCnpj(`${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`)
    }
    if(cpf.length === 11 && !cpf.includes('.')){
      setCpf(`${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9)}`)
    }
    if(tel.length === 12 && (!tel.includes('(') || !tel.includes(')') || !tel.includes(' '))){
      setTel(`(${tel.slice(0, 2)}) ${tel.slice(2, 7)}-${tel.slice(7, 10)}${tel.slice(11)}`)
    }
    if(registered){
      if(!requestError && !requestLoading){
        enqueueSnackbar(`Bem-vindo (a), ${firstName}!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setRegistered(false)
      }
    }
  }, [
    cep,
    street,
    city,
    tel,
    cpf,
    cnpj,
    district,
    erasing,
    state,
    requestDataCep,
    requestLoadingCep,
    requestErrorCep,
    requestDataCep2,
    requestLoadingCep2,
    requestErrorCep2,
    search,
    city2,
    search2,
    district2,
    state2,
    street2,
    cep2,
    checked,
    requestError,
    requestLoading,
    registered
  ]);

  if (loadItem("token")) {
    return <Navigate to="/" />;
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div className={isMobile ? classes.root : classes.rootDesktop}>
        <RegisterForm
          email={{
            id: "email",
            value: email,
            onChange: (e) => setEmail(e.target.value),
          }}
          password={{
            id: "password",
            value: password,
            onChange: (e) => setPassword(e.target.value),
          }}
          confirmPassword={{
            id: "confirmPassword",
            value: confirmPassword,
            onChange: (e) => setConfirmPassword(e.target.value),
          }}
          social={{
            id: "social",
            value: social,
            onChange: (e) => setSocial(e.target.value),
          }}
          cnpj={{
            id: "cnpj",
            value: cnpj,
            onChange: (e) => handleCnpj(e),
          }}
          date={{
            id: "date",
            value: date,
            onChange: (e) => handleDate(e),
          }}
          tel={{
            id: "tel",
            value: tel,
            onChange: (e) => handlePhone(e),
          }}
          firstName={{
            id: "name",
            value: firstName,
            onChange: (e) => setFirstName(e.target.value),
          }}
          lastName={{
            id: "lastName",
            value: lastName,
            onChange: (e) => setLastName(e.target.value),
          }}
          cpf={{
            id: "cpf",
            value: cpf,
            onChange: (e) => handleCpf(e),
          }}
          subscription={{
            id: "subscription",
            value: subscription,
            onChange: (e) => setSubscription(e.target.value),
          }}
          street={{
            id: "street",
            value: street,
            onChange: (e) => setStreet(e.target.value),
          }}
          street2={{
            id: "street2",
            value: street2,
            onChange: (e) => setStreet2(e.target.value),
          }}
          number={{
            id: "number",
            value: number,
            onChange: (e) => setNumber(e.target.value),
          }}
          number2={{
            id: "number2",
            value: number2,
            onChange: (e) => setNumber2(e.target.value),
          }}
          district={{
            id: "district",
            value: district,
            onChange: (e) => setDistrict(e.target.value),
          }}
          district2={{
            id: "district2",
            value: district2,
            onChange: (e) => setDistrict2(e.target.value),
          }}
          state={{
            id: "state",
            value: state,
            onChange: (e) => setState(e.target.value),
          }}
          state2={{
            id: "state2",
            value: state2,
            onChange: (e) => setState2(e.target.value),
          }}
          cep={{
            id: "cep",
            value: cep,
            onChange: (e) => handleZipCode(e),
          }}
          cep2={{
            id: "cep2",
            value: cep2,
            onChange: (e) => handleZipCode2(e),
          }}
          checked={{
            id: "checked",
            value: checked,
            onChange: handleChange,
          }}
          complement={{
            id: "complement",
            value: complement,
            onChange: (e) => setComplement(e.target.value),
          }}
          complement2={{
            id: "complement2",
            value: complement2,
            onChange: (e) => setComplement2(e.target.value),
          }}
          city={{
            id: "city",
            value: city,
            onChange: (e) => setCity(e.target.value),
          }}
          city2={{
            id: "city2",
            value: city2,
            onChange: (e) => setCity2(e.target.value),
          }}
          handleRegister={handleRegister}
          handleErase={handleErase}
          requestErrorCep={requestErrorCep}
          handleEraseCpfAndCnpj={handleEraseCpfAndCnpj}
        />
      </div>
    </>
  );
};

export default Register;

import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "setComercialCompany":
      return {
        company_id: action.company_id,
        companyName: action.companyName,
        companySubsidiaryId: action.companySubsidiaryId,
        companyChanged: action.companyChanged
      };
    default:
      throw new Error("Unexpected action");
  }
};

export const CompanyContext = createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <CompanyContext.Provider value={context}>
      {children}
    </CompanyContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
